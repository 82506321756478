import { FC } from "react";
import { PieceInterface } from "../../interfaces";
import PieceTable, {
  PieceMenuOptions,
} from "../../components/Shipment/PieceTable";

interface InitialValues {
  category: string;
  weight: string;
  value: string;
  height: string;
  width: string;
  length: string;
  amount: string;
}
interface PiecesDetailsProps {
  pieces: PieceInterface[];
  editMode: boolean;
  editIndex: number;
  setPieces: (pieces: PieceInterface[]) => void;
  setEditMode: (editMode: boolean) => void;
  setEditIndex: (editIndex: number) => void;
  setInitialValues: (values: InitialValues) => void;
}
const PiecesDetails: FC<PiecesDetailsProps> = ({
  pieces,
  editMode,
  editIndex,
  setPieces,
  setEditMode,
  setEditIndex,
  setInitialValues,
}) => {
  const deletePiece = (index: number) => {
    const newPieces = [...pieces];
    newPieces.splice(index, 1);
    setPieces(newPieces);
  };

  const duplicatePiece = (index: number) => {
    const newPieces = [...pieces];
    newPieces.push(pieces[index]);
    setPieces(newPieces);
  };

  const handleMenuClick = (
    option: { value: PieceMenuOptions },
    index: number
  ) => {
    if (option.value === PieceMenuOptions.EDIT) {
      setEditMode(true);
      setEditIndex(index);
      setInitialValues({
        category: pieces[index].category.name,
        weight: pieces[index].weight.toFixed(2),
        value: pieces[index].value.toFixed(2),
        height: pieces[index].height.toFixed(0),
        width: pieces[index].width.toFixed(0),
        length: pieces[index].length.toFixed(0),
        amount: "1",
      });
    } else if (option.value === PieceMenuOptions.DELETE) {
      deletePiece(index);
    } else if (option.value === PieceMenuOptions.DUPLICATE) {
      duplicatePiece(index);
    }
  };

  return (
    <div className="border-t border-gray-200">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-semibold leading-7 text-gray-900">
            Piezas
          </h2>

          {pieces.length === 0 ? (
            <p className="text-gray-500 leading-5">
              No se ha añadido ninguna pieza
            </p>
          ) : (
            <PieceTable
              editable
              pieces={pieces}
              editMode={editMode}
              editIndex={editIndex}
              onClickPieceMenu={handleMenuClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PiecesDetails;
