import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Modal from "../Modal";
import LoadingIcon from "../LodingIcon";
import { useAppSelector } from "../../store/hooks";
import { ClosureResponseInterface } from "../../interfaces";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { DailyStoreCloseDTO } from "../../interfaces/Dtos/DailyStoreCloseDTO";
import {
  getVtid,
  miscMerchant,
  getDailyCloseMerchant,
  insertDailyCloseMerchant,
} from "../../services";
import {
  XCircleIcon,
  CheckCircleIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";

enum MerchantStatus {
  INACTIVE,
  PROCESSING,
  SUCCESS,
  ERROR,
}
interface MerchantClosuresCardProps {
  closures: DailyStoreCloseDTO[];
}
export const MerchantClosuresCard: FC<MerchantClosuresCardProps> = ({
  closures,
}) => {
  const user = useAppSelector((state) => state.user);

  const [merchantModalOpen, setMerchantModalOpen] = useState(false);
  const [merchantStatus, setMerchantStatus] = useState(MerchantStatus.INACTIVE);
  const [vtid, setVtid] = useState<string>("");
  const [closuresMerchant, setClosuresMerchant] = useState<
    ClosureResponseInterface[]
  >([]);

  const merchantLogs = useMemo(() => {
    const logs = [...closuresMerchant];

    return logs.slice(0, 15);
  }, [closuresMerchant]);

  // Handles merchant close
  const handleMerchantClose = async () => {
    setMerchantModalOpen(true);
    setMerchantStatus(MerchantStatus.PROCESSING);
    try {
      const response = await miscMerchant(3);
      if (response && response.codRespuesta === "00") {
        const closeResponse = await insertDailyCloseMerchant(
          response.nombreVoucher,
          1,
          user.user?.userLogin ?? "",
          closuresMerchant[0]?.dailyCloseDate ?? null
        );
        if (!closeResponse.didError) {
          setMerchantStatus(MerchantStatus.SUCCESS);
          getClosures(vtid);
          return;
        }
      }
    } catch (error) {}
    setMerchantStatus(MerchantStatus.ERROR);
  };

  // Get merchant daily closures
  const getClosures = async (VTID: string) => {
    const response = await getDailyCloseMerchant(VTID);
    if (!response.didError && response.model) {
      setClosuresMerchant(response.model);
    }
  };

  // Get VTID
  const saveVTID = async () => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;
    const buConfigResponse = await getVtid(buCode);
    if (buConfigResponse.didError) return;
    const vtid = buConfigResponse.model?.vtid;
    if (!vtid) return;
    setVtid(vtid);
    return vtid;
  };

  useEffect(() => {
    const getMerchantClosures = async () => {
      const VTID = await saveVTID();
      await getClosures(VTID!);
    };

    getMerchantClosures();
  }, []);

  return (
    <div className="flex flex-1 flex-col items-center bg-white rounded-xl shadow-md hover:shadow-lg border px-8 pb-6 pt-4">
      <div className="flex gap-2 items-center">
        <span className="text-center">
          <CircleStackIcon className="inline align-middle self-center h-6 w-6 text-indigo-600 mr-1" />
          <span className="text-lg font-semibold text-center text-gray-700">
            Merchant
          </span>
        </span>
      </div>

      <div className="flex flex-col items-center mt-3 mb-5">
        <span className="text-gray-900 font-semibold">Último cierre:</span>
        <span className="text-gray-900">
          {closuresMerchant[0]?.dailyCloseDate
            ? moment(closuresMerchant[0]?.dailyCloseDate)
                .subtract(4, "hours")
                .format("DD/MM/YYYY hh:mm A")
            : "No hay cierres registrados"}
        </span>
      </div>

      <div hidden={user.user?.roleName === "Consulta Innovus"}>
        <PrimaryButton
          onClick={() => handleMerchantClose()}
          className="mt-3 w-full md:w-64 xl:w-full"
        >
          Cierre de Caja
        </PrimaryButton>
      </div>

      <LinkText
        text="Mostrar Pre-Cierre de Caja"
        className="mt-3 w-full md:w-64 xl:w-full text-center text-md"
        onClick={() => miscMerchant(2)}
      />
      <p className="mt-8 table-auto w-full border text-center font-semibold">
        Log de cierres recientes
      </p>
      <div className="overflow-y-auto w-full" style={{ maxHeight: "15rem" }}>
        <table className="table-auto w-full border text-center">
          <thead>
            <tr className="px-1 py-1 font-semibold text-xs">
              <th className="px-2 pt-4">ID</th>
              <th className="px-2 pt-4">Fecha</th>
            </tr>
          </thead>
          <tbody>
            {merchantLogs.length > 0 ? (
              merchantLogs?.map((log, index) => {
                const adjustedDate = moment(log.dailyCloseDate).subtract(
                  4,
                  "hours"
                );
                const day =
                  moment(log.dailyCloseDate)
                    .format("dddd")
                    .charAt(0)
                    .toUpperCase() +
                  moment(log.dailyCloseDate).format("dddd").slice(1);

                return (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 && "bg-gray-50"}`}
                  >
                    <td className="px-4 py-4 text-xs text-center text-gray-500 font-semibold">
                      {log.dailyCloseExecID}
                    </td>
                    <td className="px-4 py-4 text-xs text-center text-gray-500 font-semibold">
                      {day}, {moment(adjustedDate).format("DD/MM/YYYY hh:mm A")}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={2}
                  className="px-4 py-4 text-xs text-center text-gray-500 font-semibold"
                >
                  No hay cierres registrados
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal openModal={merchantModalOpen} setOpenModal={() => {}}>
        {merchantStatus === MerchantStatus.PROCESSING && (
          <div className="flex flex-1 items-center flex-col gap-4">
            <LoadingIcon size="5rem" />

            <p className="mt-2 text-lg font-medium text-gray-600 text-center">
              Comunicando con servidor Merchant. Por favor espere...
            </p>
          </div>
        )}

        {merchantStatus === MerchantStatus.SUCCESS && (
          <div className="flex flex-1 items-center flex-col gap-4">
            <CheckCircleIcon className="text-green-600 w-32 h-32" />

            <p className="mt-2 text-lg font-medium text-gray-600 text-center">
              Cierre de Merchant realizado exitosamente.
            </p>

            <div className="w-full">
              <SecondaryButton
                onClick={() => setMerchantModalOpen(false)}
                className="w-full"
              >
                Cerrar
              </SecondaryButton>
            </div>
          </div>
        )}

        {merchantStatus === MerchantStatus.ERROR && (
          <div className="flex flex-1 items-center flex-col gap-4">
            <XCircleIcon className="text-red-600 w-32 h-32" />

            <p className="mt-2 text-lg font-medium text-gray-600 text-center">
              Error al abrir interfaz Merchant.
            </p>

            <div className="flex gap-4 w-full">
              <SecondaryButton
                onClick={() => setMerchantModalOpen(false)}
                className="w-full"
              >
                Cerrar
              </SecondaryButton>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};
