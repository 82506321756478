import React, { FC } from "react";

interface HorizontalPaddingProps {
  paddingTop?: boolean;
  children: React.ReactNode;
}
const HorizontalPadding: FC<HorizontalPaddingProps> = ({
  paddingTop = false,
  children,
}) => {
  return (
    <div
      className={`relative ${
        paddingTop && "pt-16"
      } my-8 px-6 md:px-12 xl:px-24`}
    >
      {children}
    </div>
  );
};

export default HorizontalPadding;
