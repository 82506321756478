import { FC, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import logo from "../../assets/LogoTEALCA.svg";
import { useNavigate } from "react-router-dom";
import { FormTextInput } from "../../components/FormFields";
import { forgotPassword } from "../../services/authetication";
import { LinkText, PrimaryButton } from "../../components/Buttons";
import { useAppSelector } from "../../store/hooks";

export interface PasswordRecoveryFormValues {
  email: string;
}
const initialValues: PasswordRecoveryFormValues = {
  email: "",
};

const PasswordRecovery: FC = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [apiSuccessMessage, setApiSuccessMessage] = useState<string[]>([]);

  const appData = useAppSelector((state) => state.inmutable.appData);

  const recoverPassword = async (values: PasswordRecoveryFormValues) => {
    setApiErrors([]);
    setApiSuccessMessage([]);
    const response = await forgotPassword(
      values.email,
      appData.applicationCode
    );

    if (response === null) {
      setApiErrors([
        ...apiErrors,
        "Hubo un error al intentar cambiar la contraseña",
      ]);
    } else if (response.didError && !!response.errorMessage) {
      setApiErrors([...apiErrors, response.errorMessage]);
    } else {
      setSuccess(true);
      setApiSuccessMessage([...apiSuccessMessage, response.message]);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("El correo es obligatorio"),
  });

  return (
    <div className="flex h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full h-full max-w-sm flex flex-1 flex-col justify-around">
        <div>
          <h1 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Taquilla TEALCA
          </h1>
          <img src={logo} className="mx-auto h-30 w-auto" alt="logo" />
          <h2 className="mt-5 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            Reestablecer contraseña
          </h2>
          <p className="text-sm text-gray-500 text-center">
            Ingrese su correo para cambiar la contraseña
          </p>
        </div>

        {!success && (
          <Formik<PasswordRecoveryFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              recoverPassword(values).then(() => {
                actions.setSubmitting(false);
                actions.resetForm({
                  values: initialValues,
                });
              });
            }}
          >
            {(formik) => {
              const {
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
              } = formik;
              return (
                <form className="space-y-6" onSubmit={handleSubmit}>
                  {!!apiErrors && apiErrors.length > 0 && (
                    <p className="text-center ">
                      <span className="text-sm text-red-500">
                        {apiErrors.join(", ")}
                      </span>
                    </p>
                  )}
                  <div className="flex flex-1 flex-col gap-2">
                    <div>
                      <FormTextInput
                        id="forgot-password-email"
                        name="email"
                        error={
                          touched.email && errors.email ? errors.email : ""
                        }
                        placeholder="Correo electrónico"
                        value={values.email}
                        onChange={(value) => {
                          handleChange(value);
                          setApiErrors([]);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="flex flex-1">
                    <PrimaryButton type="submit" className="w-full">
                      Enviar
                    </PrimaryButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {success && (
          <div className="flex flex-col items-center">
            {!!apiSuccessMessage && apiSuccessMessage.length > 0 && (
              <p className="text-center ">
                <span className="text-green-500">
                  {apiSuccessMessage.join(", ")}
                </span>
              </p>
            )}
          </div>
        )}

        <div className="flex flex-col items-center">
          <LinkText text="Iniciar Sesión" onClick={() => navigate("/login")} />
        </div>
      </div>
    </div>
  );
};
export default PasswordRecovery;
