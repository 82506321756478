import api from "./api";

const baseURLAPI = process.env.REACT_APP_API_HOST;

export const dnsLookup = async (domain: string): Promise<boolean> => {
  const uri = `${baseURLAPI}/Account/VerifyEmailDomain?domain=${domain}`;

  try {
    const response = await api.head(uri);

    if (response.status === 200) {
      return true;
    }
  } catch (error) {}

  return false;
};
