import axios from "axios";
import { store } from "../store/store";
import api from "./api";
import {
  PaymentMethodDto,
  PaymentMethodInterface,
  PaymentMethodEnum,
  ResponseInterface,
  PaymentInterface,
} from "../interfaces";
import { PaymentExtendedDto } from "../interfaces/Dtos";

const baseURLAPI = process.env.REACT_APP_API_HOST;

// get all payment methods for a business unit
export const getPaymentMethods = async (
  buCode: string,
  userLogin: string
): Promise<PaymentMethodInterface[]> => {
  const uri = `${baseURLAPI}/v1/Payment/SearchByBU?BUCode=${buCode}&UserLogin=${userLogin}`;
  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const paymentMethodsDto = response.data.model as PaymentMethodDto[];
      const paymentMethods: PaymentMethodInterface[] = [];
      let bankID: number | undefined = undefined;
      let bankAccountID: number | undefined = undefined;
      paymentMethodsDto.forEach((method) => {
        if (
          method.paymentMethodID !==
            PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL &&
          method.paymentMethodID !== PaymentMethodEnum.RETENCION_DE_ISRL &&
          method.paymentMethodID !== PaymentMethodEnum.RETENCION_DE_IVA
        ) {
          if (method.banks && method.banks.length > 0) {
            if (method.banks.length === 1) {
              bankID = method.banks[0].bankID;
              bankAccountID = method.banks[0].bankAccountID;
            } else if (
              method.paymentMethodID > 6 &&
              method.paymentMethodID < 9
            ) {
              bankID = 27;
              bankAccountID = 0;
            }
          }
          const paymentMethod: PaymentMethodInterface = {
            paymentMethodID: method.paymentMethodID,
            paymentMethodName: method.paymentMethodName,
            currencyID: method.currencyID,
            bankID: bankID,
            bankAccountID: bankAccountID,
            bankAccounts: method.banks,
          };
          bankID = undefined;
          bankAccountID = undefined;
          paymentMethods.push(paymentMethod);
        }
      });

      const sortedPaymentMethods = [...paymentMethods].sort((a, b) =>
        a.paymentMethodName.localeCompare(b.paymentMethodName)
      );

      return sortedPaymentMethods;
    }
  } catch (error) {}
  return [];
};

// get all retention methods form a business unit
export const getRetentionMethods = async (
  buCode: string,
  userLogin: string
): Promise<PaymentMethodInterface[]> => {
  const uri = `${baseURLAPI}/v1/Payment/SearchByBU?BUCode=${buCode}&UserLogin=${userLogin}`;
  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      const paymentMethodsDto = response.data.model as PaymentMethodDto[];
      const retentionMethods: PaymentMethodInterface[] = [];
      paymentMethodsDto.forEach((method) => {
        if (
          method.paymentMethodID ===
            PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL ||
          method.paymentMethodID === PaymentMethodEnum.RETENCION_DE_ISRL ||
          method.paymentMethodID === PaymentMethodEnum.RETENCION_DE_IVA
        ) {
          const retentionMethod: PaymentMethodInterface = {
            paymentMethodID: method.paymentMethodID,
            paymentMethodName: method.paymentMethodName,
            currencyID: method.currencyID,
          };
          retentionMethods.push(retentionMethod);
        }
      });

      return retentionMethods;
    }
  } catch (error) {}
  return [];
};

export const getStorePendingPayments = async (
  buCode: string
): Promise<ResponseInterface<PaymentInterface[]>> => {
  const currencyList = store.getState().inmutable.currencyList;
  const uri = `${baseURLAPI}/v1/Payment/Pending?BUCode=${buCode}`;

  try {
    const response = await axios.get(uri);

    if (response.status === 200) {
      return {
        ...response.data.model,
        model: response.data.model.map(
          (payment: PaymentExtendedDto) =>
            ({
              paymentID: payment.paymentID,
              documentID: payment.documentID,
              documentNumber: payment.documentNumber,
              paymentDetailID: payment.paymentDetailID,
              proofOfPayment: payment.attachmentID,
              amount: payment.paymentAmountBaseCurr,
              sourceBankID: payment.sourceBankID,
              destBankID: payment.bankAccountID,
              paymentMethod: {
                paymentMethodID: payment.paymentMethodID,
                paymentMethodName: payment.paymentMethodName,
                currencyID: payment.currencyID,
                bankID: payment.bankAccountID,
                taxes: [],
              },
              igtfAmount: 0,
              igtfPercentage: 0,
              reference: payment.referenceNum,
              currency: {
                id: payment.currencyID,
                name:
                  currencyList.find(
                    (pay) => pay.currencyID === payment.currencyID
                  )?.currencyName ?? "",
                code:
                  currencyList.find(
                    (pay) => pay.currencyID === payment.currencyID
                  )?.currencyIsoCode ?? "",
              },
              status: payment.statusID,
              creationDate: payment.paymentDate,
              paymentDate: payment.paymentDetailDate,
              creationUser: payment.creationUser,
              isRetention: [
                PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL,
                PaymentMethodEnum.RETENCION_DE_ISRL,
                PaymentMethodEnum.RETENCION_DE_IVA,
              ].includes(payment.paymentMethodID), // check if inside a list of retentions
            } as PaymentInterface)
        ),
      };
    }
  } catch (error) {
    return (error as any).response.data;
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error al obtener los pagos pendientes.",
  };
};
