import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { FormTextInput } from "../FormFields";
import { deliveryTypeFormat } from "../../utils";
import PaginationFooter from "../PaginationFooter";
import { useAppSelector } from "../../store/hooks";
import PendingShipmentItem from "./PendingShipmentItem";
import ShipmentDetailsModal from "./ShipmentDetailsModal";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import { MultipleFilterDisclosure } from "../FilterDisclosure";
import { DeliveryType, ShipmentInterface } from "../../interfaces";
import {
  getShipment,
  alertService,
  searchShipmentPendingPOD,
} from "../../services";
import { PendingShipmentsContext } from "../../contexts/PendingShipmentsContext";

const ROWS_PER_PAGE = 5;

const INITIAL_DELIVERY_TYPES = [
  DeliveryType.AT_OFFICE,
  DeliveryType.AT_HOME,
].map((deliveryType) => ({
  name: deliveryTypeFormat(deliveryType),
  value: deliveryType,
  checked: false,
}));

const PendingShipmentTable: FC = ({}) => {
  const { shipmentNumber } = useParams();
  const user = useAppSelector((state) => state.user);

  const [filter, setFilter] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [reload, setReload] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openShipmentModal, setOpenShipmentModal] = useState(false);
  const [routeShipment, setRouteShipment] = useState<ShipmentInterface>();
  const [shipments, setShipments] = useState<StoreShipmentInterface[]>([]);
  const [deliveryTypes, setDeliveryTypes] = useState(INITIAL_DELIVERY_TYPES);

  const filteredShipments = useMemo(() => {
    setItemOffset(0);
    const tokens = filter.toLowerCase().split(" ");
    const deliveryTypeList = deliveryTypes
      .filter((dt) => dt.checked)
      .map((dt) => dt.value);

    return shipments.filter(
      (s) =>
        tokens.every(
          (t) =>
            s.shipmentNumber.toLowerCase().includes(t) ||
            s.shipperName.toLowerCase().includes(t) ||
            s.shipperIdentificationNumber?.toLowerCase().includes(t) ||
            s.consigneeName.toLowerCase().includes(t) ||
            s.consigneeIdentificationNumber?.toLowerCase().includes(t) ||
            s.documentNumber?.toLowerCase().includes(t)
        ) &&
        (deliveryTypeList.length === 0 ||
          deliveryTypeList.some(
            (dt) => (s.storePickup === "1") === (dt === DeliveryType.AT_OFFICE)
          ))
    );
  }, [shipments, filter, deliveryTypes]);

  const paginatedShipments = useMemo(() => {
    return filteredShipments.slice(itemOffset, itemOffset + ROWS_PER_PAGE);
  }, [filteredShipments, itemOffset]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset =
      (event.selected * ROWS_PER_PAGE) % filteredShipments.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) {
      return;
    }
    setItemOffset(0);

    const getShipments = async () => {
      const shipments = await searchShipmentPendingPOD(buCode);

      if (!shipments || shipments.didError || !shipments.model) {
        alertService.error(
          "No se pudieron cargar los envíos pendientes",
          shipments.errorMessage
        );
        return;
      }
      setShipments(shipments.model);
    };

    getShipments();
  }, [user.businessUnit, refresh]);

  useEffect(() => {
    const getRouteShipment = async () => {
      if (!!shipmentNumber) {
        const shipmentFound = await getShipment(shipmentNumber);
        if (!!shipmentFound) {
          setOpenShipmentModal(true);
          setRouteShipment(shipmentFound);
        }
      }
    };

    getRouteShipment();
  }, [shipmentNumber, reload]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredShipments.length / ROWS_PER_PAGE));
  }, [itemOffset, ROWS_PER_PAGE, filteredShipments]);

  return (
    <PendingShipmentsContext.Provider value={{ refresh, setRefresh }}>
      <div className="flex flex-1 flex-col pb-6 pt-4 overflow-hidden gap-8">
        <div className="flex w-full flex-row justify-between">
          <div className="flex-1" />
          <div className="flex w-full lg:max-w-[35rem] md:max-w-[30rem]">
            <FormTextInput
              value={filter}
              id="pending-shipments-filter"
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filtrar por número de guía o por nombre/documento de remitente o destinatario o por número de orden/factura"
              data-te-toggle="tooltip"
              title="Filtrar por número de guía o por nombre/documento de remitente o destinatario o por número de orden/factura"
            />
          </div>
          <div className="flex flex-1 items-end justify-end">
            <div>
              <MultipleFilterDisclosure
                showCount
                title="Entrega"
                options={deliveryTypes}
                setOptions={setDeliveryTypes}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "w-auto px-8",
            filteredShipments.length === 0 && "hidden"
          )}
        >
          <div className="overflow-x-auto">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    GUÍA
                  </th>

                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    REMITENTE
                  </th>

                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    DESTINATARIO
                  </th>

                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    CARACTERÍSTICAS
                  </th>

                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    FACTURA
                  </th>

                  <th className="text-center px-4 py-2 font-semibold text-xs truncate">
                    DÍAS EN TIENDA
                  </th>

                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    ENTREGA
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedShipments.map((shipment, index: number) => (
                  <PendingShipmentItem
                    showDelivery
                    deliveryInColumn
                    index={index}
                    shipment={shipment}
                    pendingShipments={shipments}
                    key={shipment.shipmentHeaderID}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <PaginationFooter
            totalPages={totalPages}
            itemsOffSet={itemOffset}
            rowCounts={ROWS_PER_PAGE}
            totalItems={filteredShipments.length}
            handlePageClick={handlePageClick}
          />
        </div>

        <div className={classNames(filteredShipments.length !== 0 && "hidden")}>
          <div className="flex flex-col items-center justify-center gap-4">
            <h2 className="text-gray-400">
              No se encontraron guías por entregar
            </h2>
          </div>
        </div>

        {routeShipment && (
          <ShipmentDetailsModal
            shipments={shipments}
            openModal={openShipmentModal}
            shipmentNumber={routeShipment.shipmentNumber!}
            setOpenModal={setOpenShipmentModal}
            onReload={() => setReload(!reload)}
          />
        )}
      </div>
    </PendingShipmentsContext.Provider>
  );
};

export default PendingShipmentTable;
