import { FC, Fragment } from "react";
import logo from "../assets/LogoTEALCA.svg";
import { useAppSelector } from "../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { BusinessUnitInterface } from "../interfaces";
import { NavigationItem } from "../utils/NavigationLists";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { UserInterface } from "../interfaces/UserInterface";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  Bars3Icon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";

//Items que no se deben mostrar en la sidebar para el rol de consulta
enum consultingRestrictedItems {
  "Crear Guía",
  "Facturar a Casa Matriz",
  "Reporte Operativo",
  "Reporte Administrativo",
}

interface SidebarDynamicProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigationItems: NavigationItem[];
  currentItemIndex: number;
  setCurrentItemIndex: React.Dispatch<React.SetStateAction<number>>;
  handleLogout: () => void;
  handleBusinessUnitChange: (
    user: UserInterface,
    businessUnit: BusinessUnitInterface
  ) => void;
}

const SidebarDynamic: FC<SidebarDynamicProps> = ({
  sidebarOpen,
  setSidebarOpen,
  navigationItems,
  currentItemIndex,
  setCurrentItemIndex,
  handleLogout,
  handleBusinessUnitChange,
}) => {
  const navigate = useNavigate();
  const userLogged = useAppSelector((state) => state.user.user);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const handleItemClick = (index: number) => {
    setCurrentItemIndex(index);
  };
  // Method to render the navigation items depending on the user role
  const renderNavigationItems = () => {
    return navigationItems.map((item, index) => {
      const shouldRenderItem =
        !item.rolesRequired ||
        (!!userLogged && item.rolesRequired?.includes(userLogged.roleName));

      if (shouldRenderItem) {
        return (
          <li key={item.name}>
            <div
              hidden={
                userLogged?.roleName === "Consulta Innovus" &&
                item.name in consultingRestrictedItems
              }
            >
              <Link
                to={item.href}
                className={classNames(
                  index === currentItemIndex
                    ? "bg-gray-50 text-indigo-600"
                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                )}
                onClick={() => handleItemClick(index)}
                target={item.target}
              >
                {!!item.icon && (
                  <item.icon
                    className={classNames(
                      index === currentItemIndex
                        ? "text-indigo-600"
                        : "text-gray-400 group-hover:text-indigo-600",
                      "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            </div>
          </li>
        );
      }
      return null; // If the item shouldn't be rendered based on roles
    });
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <a href="/">
                      <img
                        className="h-16 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </a>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {renderNavigationItems()}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/* top bar */}
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900"></div>

        <Menu as="div" className="relative">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Open user menu</span>

            <div className="w-12 h-12 rounded-full bg-gradient-to-b text-white from-indigo-600 to-blue-400 flex items-center justify-center text-lg font-bold">
              {userLogged?.userName[0]} {userLogged?.userLastName[0]}
            </div>

            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                aria-hidden="true"
              >
                User:{" "}
                {!!userLogged
                  ? userLogged.userName + " " + userLogged.userLastName
                  : ""}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2.5 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
              <Menu.Item key={"profile"}>
                {({ active }) => (
                  <button
                    onClick={() => navigate("/profile")}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block px-3 py-1 text-sm leading-6 text-gray-900 text-right w-full"
                    )}
                  >
                    Mi Perfil
                  </button>
                )}
              </Menu.Item>

              <Menu.Item key={"logout"}>
                {({ active }) => (
                  <button
                    onClick={() => handleLogout()}
                    className={classNames(
                      active ? "bg-gray-50" : "",
                      "block px-3 py-1 text-sm leading-6 text-gray-900 text-right w-full"
                    )}
                  >
                    Cerrar sesión
                  </button>
                )}
              </Menu.Item>

              {(userLogged?.businessUnitList.length ?? 0) > 1 && (
                <Menu.Item key={"change_businessUnit"}>
                  {({ active }) => (
                    <Menu as="div" className="relative inline-block text-left">
                      <Menu.Button
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "flex justify-between items-center gap-4 px-3 py-1 text-sm leading-6 truncate text-gray-900"
                        )}
                      >
                        <ChevronLeftIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Cambiar tienda
                      </Menu.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute py-2 right-full overflow-y-auto top-0 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userLogged?.businessUnitList.map(
                            (businessUnit, index) => (
                              <Menu.Item key={index}>
                                {({ active }) => (
                                  <Menu.Button
                                    onClick={() =>
                                      handleBusinessUnitChange(
                                        userLogged,
                                        businessUnit
                                      )
                                    }
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block w-full px-3 py-1 text-sm text-left leading-6 text-gray-900 truncate"
                                    )}
                                  >
                                    {businessUnit.code} -{" "}
                                    {businessUnit.location.name}
                                  </Menu.Button>
                                )}
                              </Menu.Item>
                            )
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </Menu.Item>
              )}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};
export default SidebarDynamic;
