import api from "./api";
import axios from "axios";
import { UserState } from "../store/slices";
import { formatPaymentStatus } from "../utils";
import { NetworkLog } from "../interfaces/ReportInterface";
import {
  AccountInterface,
  BusinessUnitInterface,
  DocumentInterface,
  PaymentInterface,
  ResponseInterface,
  ShipmentInterface,
  ShipmentSimpleInterface,
} from "../interfaces";

const baseURL = process.env.REACT_APP_API_HOST;

export const reportIncidence = async (
  login: UserState,
  title: string,
  description: string,
  logs: NetworkLog[],
  info?: DocumentInterface,
  files?: File[]
): Promise<ResponseInterface<boolean>> => {
  let infoString = formatDocument(
    login.user!.userLogin,
    `${login.businessUnit!.name} / ${login.businessUnit!.code} - ${
      login.businessUnit!.location.name
    }`,
    info
  );

  let json = info
    ? JSON.stringify(info, null, 2)
    : "No hay información asociada al pago";
  json += "\n LOGS: \n";
  json += logs
    ? JSON.stringify(logs, null, 2)
    : "No hay logs asociados al pago";

  const formData = new FormData();
  formData.append("TicketTitle", title);
  formData.append("TicketDescription", description);
  formData.append("TicketInfo", infoString);
  formData.append("TicketJson", json);
  formData.append("TicketCCEmail", login.user!.userEmail); // User email
  formData.append("TicketBCCEmail", ""); // BU email pending
  if (files) {
    if (files[0]) {
      formData.append("TicketFile1", files[0]);
    }
    if (files[1]) {
      formData.append("TicketFile2", files[1]);
    }
    if (files[2]) {
      formData.append("TicketFile3", files[2]);
    }
  }

  try {
    const result = await api.post(
      `${baseURL}/Incidence/SendPaymentIncidenceTicket`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {}
  return {
    didError: true,
    errorMessage: "Hubo un error al reportar la incidencia",
    message: "",
    model: false,
  };
};

const formatDocument = (
  userName: string,
  buName: string,
  document?: DocumentInterface
): string => {
  const now = new Date();
  const formattedCreationDate = `Fecha y hora de creación del ticket: ${now.toLocaleDateString(
    "es-VE"
  )} ${now.toLocaleTimeString("es-VE")}`;

  const formattedUserName = `Usuario: ${userName}`;

  const formattedBusinessUnit = `Tienda: ${buName}`;

  if (!document) {
    return `${formattedCreationDate}\n${formattedUserName}\n${formattedBusinessUnit}\n\n Todavía no hay registro de la factura asociada al pago.`;
  }

  const formattedDocumentNumber = `Número de factura: ${document.documentNumber}`;

  const formattedShipments = document.shipments
    .map(
      (shipment: ShipmentSimpleInterface | ShipmentInterface) =>
        `- Guía número: ${
          shipment.shipmentNumber ?? "No hay numero de guía asociado"
        }`
    )
    .join("\n");

  const formattedPayments = document.payments
    .map(
      (payment: PaymentInterface) =>
        `- Tipo de pago: ${payment.paymentMethod.paymentMethodName}, Moneda: ${
          payment.paymentMethod.currencyID === 1 ? "Bolívares" : "Dólares"
        }, Monto: ${payment.amount}${
          payment.reference ? ", Referencia: " + payment.reference : ""
        }", Estado:" + ${formatPaymentStatus(payment.status)}`
    )
    .join("\n");

  return `${formattedCreationDate}\n${formattedUserName}\n${formattedBusinessUnit}\n\n${formattedDocumentNumber}\nGuía(s):\n${formattedShipments}\n\nPago(s) realizado(s):\n${formattedPayments}`;
};

// NetworkMonitor function
export const NetworkMonitor = (
  setState: React.Dispatch<React.SetStateAction<NetworkLog[]>>,
  logs: NetworkLog[]
) => {
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      const log: NetworkLog = {
        request: {
          method: response.config.method ?? "",
          url: response.config.url ?? "",
          headers: response.config.headers,
          body: response.config.data,
        },
        response: {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers as Record<string, string>,
          data: response.data,
        },
      };
      setState((prevLogs) => [...prevLogs, log]);
      return response;
    },
    (error) => {
      console.error(error);
      return Promise.reject(error);
    }
  );

  // Clean up interceptors on component unmount
  return () => {
    axios.interceptors.response.eject(responseInterceptor);
  };
};

export const ReintegrationRequest = async (
  user: string,
  businessUnit: BusinessUnitInterface,
  document: DocumentInterface,
  amount: number,
  owner: AccountInterface
): Promise<ResponseInterface<boolean>> => {
  const infoString = formatReintegration(
    user,
    businessUnit,
    document,
    amount,
    owner
  );

  const body = {
    data: infoString,
  };

  try {
    const result = await axios.post(
      `${baseURL}/V1/Payment/Reintegration`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (result.status === 200) return result.data;
  } catch (error) {}
  return {
    didError: true,
    errorMessage: "Hubo un error al solicitar el reintegro",
    message: "",
    model: false,
  };
};

const formatReintegration = (
  user: string,
  businessUnit: BusinessUnitInterface,
  document: DocumentInterface,
  amount: number,
  owner: AccountInterface
): string => {
  const formattedUserName = `Usuario: ${user}`;

  const formattedBusinessUnit = `Tienda: ${businessUnit.name} - ${businessUnit.code}`;

  const formattedDocumentNumber = `Número de factura: ${document.documentNumber}`;

  const formattedAmount = `Monto: ${amount} Bs.`;

  const formattedOwner = `Cliente: ${owner.accountFullName} ${owner.abreviationName}${owner.identificationNumber}`;

  return `${formattedUserName}\n${formattedBusinessUnit}\n${formattedDocumentNumber}\n${formattedAmount}\n${formattedOwner}`;
};
