import { FC } from "react";
import { Fragment } from "react";
import classNames from "classnames";
import logo from "../assets/LogoTEALCA.svg";
import { useAppSelector } from "../store/hooks";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { BusinessUnitInterface } from "../interfaces";
import { NavigationItem } from "../utils/NavigationLists";
import { UserInterface } from "../interfaces/UserInterface";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

//Items que no se deben mostrar en la sidebar para el rol de consulta
enum consultingRestrictedItems {
  "Crear Guía",
  "Facturar a Casa Matriz",
  "Reporte Operativo",
  "Reporte Administrativo",
}

interface SidebarStaticProps {
  navigationItems: NavigationItem[];
  currentItemIndex: number;
  setCurrentItemIndex: React.Dispatch<React.SetStateAction<number>>;
  handleLogout: () => void;
  handleBusinessUnitChange: (
    user: UserInterface,
    businessUnit: BusinessUnitInterface
  ) => void;
}

const SidebarStatic: FC<SidebarStaticProps> = ({
  navigationItems,
  currentItemIndex,
  setCurrentItemIndex,
  handleLogout,
  handleBusinessUnitChange,
}) => {
  const navigate = useNavigate();
  const userLogged = useAppSelector((state) => state.user.user);
  const userBu = useAppSelector((state) => state.user.businessUnit);

  const handleItemClick = (index: number) => {
    setCurrentItemIndex(index);
  };
  // Method to render the navigation items depending on the user role
  const renderNavigationItems = () => {
    return navigationItems.map((item, index) => {
      const shouldRenderItem =
        !item.rolesRequired ||
        (!!userLogged && item.rolesRequired?.includes(userLogged.roleName));

      if (shouldRenderItem) {
        return (
          <li key={item.name}>
            <div
              hidden={
                userLogged?.roleName === "Consulta Innovus" &&
                item.name in consultingRestrictedItems
              }
            >
              <Link
                to={item.href}
                className={classNames(
                  !item.primary &&
                    (index === currentItemIndex
                      ? "bg-gray-50 text-indigo-600"
                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"),
                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                  !!item.primary &&
                    "bg-indigo-600 hover:bg-indigo-500 text-white shadow-md"
                )}
                onClick={() => handleItemClick(index)}
                target={item.target}
              >
                {!!item.icon && (
                  <item.icon
                    className={classNames(
                      !item.primary &&
                        (index === currentItemIndex
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600"),
                      "h-6 w-6 shrink-0",
                      !!item.primary && "text-white"
                    )}
                    aria-hidden="true"
                  />
                )}
                {item.name}
              </Link>
            </div>
          </li>
        );
      }
      return null; // If the item shouldn't be rendered based on roles
    });
  };

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white px-6">
        <div className="flex h-16 shrink-0 items-center">
          {process.env.REACT_APP_PRODUCTION === "true" && (
            <a href="/">
              <img
                className="max-h-[4rem] max-w-[4rem] min-h-[4rem] min-w-[4rem]"
                src={logo}
                alt="Your Company"
              />
            </a>
          )}

          {process.env.REACT_APP_PRODUCTION === "false" && (
            <span className="text-sm ml-6 font-bold italic text-center text-indigo-600 leading-tight">
              ENTORNO DE DESARROLLO
            </span>
          )}
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="-mx-2 space-y-1">{renderNavigationItems()}</ul>
            </li>
            <Menu as="li" className="relative -mx-6 mt-auto -mx-2 space-y-1">
              <Menu.Button className="flex w-full items-center gap-x-4 px-6 py-3 font-semibold leading-6 text-gray-900 hover:bg-gray-50">
                <span className="sr-only">Open user menu</span>
                <div className="w-12 h-12 rounded-full bg-gradient-to-b text-white from-indigo-600 to-blue-400 flex items-center justify-center text-lg font-bold">
                  {userLogged?.userName[0]} {userLogged?.userLastName[0]}
                </div>
                <div
                  className="ml-4 font-semibold leading-6 text-left text-gray-900"
                  aria-hidden="true"
                >
                  <p className="">
                    {userLogged?.userName} {userLogged?.userLastName}
                  </p>
                  <p className="text-sm">Tienda {userBu?.code}</p>
                </div>
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute bottom-16 right-0 z-10 origin-bottom-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item key={"profile"}>
                    {({ active }) => (
                      <Menu.Button
                        onClick={() => navigate("/profile")}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block w-full px-3 py-1 text-sm text-left leading-6 text-gray-900"
                        )}
                      >
                        Mi Perfil
                      </Menu.Button>
                    )}
                  </Menu.Item>

                  {(userLogged?.businessUnitList.length ?? 0) > 1 && (
                    <Menu.Item key={"change_businessUnit"}>
                      {({ active }) => (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <Menu.Button
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "flex justify-between items-center gap-4 px-3 py-1 text-sm leading-6 truncate text-gray-900"
                            )}
                          >
                            Cambiar tienda
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Menu.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items
                              style={{ maxHeight: "10rem" }}
                              className="absolute py-2 left-full bottom-0 overflow-y-auto bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              {userLogged?.businessUnitList.map(
                                (businessUnit, index) => (
                                  <Menu.Item key={index}>
                                    {({ active }) => (
                                      <Menu.Button
                                        onClick={() =>
                                          handleBusinessUnitChange(
                                            userLogged,
                                            businessUnit
                                          )
                                        }
                                        className={classNames(
                                          active ? "bg-gray-50" : "",
                                          "block w-full px-3 py-1 text-sm text-left leading-6 text-gray-900 truncate"
                                        )}
                                      >
                                        {businessUnit.code} -{" "}
                                        {businessUnit.location.name}
                                      </Menu.Button>
                                    )}
                                  </Menu.Item>
                                )
                              )}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </Menu.Item>
                  )}

                  <Menu.Item key={"logout"}>
                    {({ active }) => (
                      <Menu.Button
                        onClick={() => handleLogout()}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block w-full px-3 py-1 text-sm text-left leading-6 text-gray-900"
                        )}
                      >
                        Cerrar sesión
                      </Menu.Button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default SidebarStatic;
