import api from "./api";
import { BoxAccountDTO } from "../interfaces/Dtos/BoxAccountDTO";
import {
  Nullable,
  BoxAccountInterface,
  BusinessUnitInterface,
  ResponseInterface,
} from "../interfaces";

const baseURLAPI = process.env.REACT_APP_API_HOST;

export const getFilteredBoxAccounts = async (
  boxAccountCode: string,
  businessUnits: BusinessUnitInterface[]
): Promise<ResponseInterface<BoxAccountInterface[]>> => {
  const uri = `${baseURLAPI}/BoxAccount?BoxAccountCode=${boxAccountCode}`;

  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return {
        message: "Casillero encontrado.",
        didError: false,
        errorMessage: "",
        model: response.data.map((boxAccount: BoxAccountDTO) => ({
          id: boxAccount.boxAccountID,
          boxAccountCode: boxAccount.boxAccountCode,
          businessUnit: businessUnits.find(
            (businessUnit) => businessUnit.code === boxAccount.buCode
          ),
          accountID: boxAccount.accountID,
          accountFullName: boxAccount.accountFullName,
          accountAbreviationName: boxAccount.abreviationName,
          accountIdentificationNumber: boxAccount.identificationNumber,
          authorizedShippers: [],
        })),
      };
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    model: null,
    didError: true,
    message: "Hubo un error buscando el casillero.",
    errorMessage: "Hubo un error buscando el casillero.",
  };
};
