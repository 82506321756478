import { FC, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { useNavigate } from "react-router-dom";
import {
  alertService,
  loaderService,
  previewFileDO,
  saveProofOfPayment,
  updatePayment,
} from "../../services";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { currencyExchangeText, useCurrencyExchanges } from "../../utils";
import {
  DocumentInterface,
  PaymentInterface,
  PaymentMethodEnum,
  PaymentStatusEnum,
  UserInterface,
} from "../../interfaces";
import Modal from "../Modal";
import { useAppSelector } from "../../store/hooks";
import ProofOfPaymentModal from "./ProofOfPaymentModal";

interface PaymentItemProps {
  index: number;
  transaction: PaymentInterface;
  document?: DocumentInterface;
  vtid?: string;
  last?: boolean;
  setRefresh?: (value: number) => void;
}
const PendingPaymentItem: FC<PaymentItemProps> = ({
  index,
  transaction,
  document,
  last,
  setRefresh = () => {},
}) => {
  const navigate = useNavigate();
  const exchanges = useCurrencyExchanges();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user.user);

  const [openProofModal, setOpenProofModal] = useState(false);
  const [isModification, setIsModification] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const paymentMethodName = (transaction: PaymentInterface): string => {
    const methodName = transaction.paymentMethod.paymentMethodName;
    if (
      transaction.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.EFECTIVO ||
      transaction.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.VUELTO_EFECTIVO
    ) {
      if (transaction.paymentMethod.currencyID === 1) {
        return `${methodName} en Bolívares`;
      }
      return `${methodName} en Dólares`;
    }

    return methodName;
  };

  const onCancelTransaction = async () => {
    if (!user) return;

    loaderService.start();
    const response = await updatePayment({
      documentID: document?.documentID ?? transaction.documentID!,
      paymentDetailID: transaction.paymentDetailID!,
      statusID: PaymentStatusEnum.CANCELED,
      user: user,
    });

    if (!!response.didError) {
      alertService.error(
        "Error al cancelar la retención",
        response.errorMessage
      );
    } else {
      setRefresh(Math.random());
    }

    loaderService.stop();
    setOpenCancelModal(false);
  };

  const onSaveProofOfPayment = async (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string,
    isModification?: boolean
  ) => {
    loaderService.start();
    const saved = await saveProofOfPayment(
      file,
      retention,
      user,
      statusID,
      reference,
      isModification,
      document?.documentID
    );

    if (saved.didError || !saved.model) {
      alertService.error(
        "Error al guardar el comprobante de pago",
        saved.errorMessage
      );
    }

    alertService.success("El comprobante se guardó exitosamente");
    setRefresh(Math.random());
    loaderService.stop();
  };

  return (
    <tr
      className={classNames(
        index % 2 === 0 && "bg-gray-50",
        last && "border-b-2 border-gray-300"
      )}
    >
      <td className="text-xs px-4 py-2 font-bold truncate">
        <p className="flex items-center">{paymentMethodName(transaction)}</p>

        <p className="text-xs text-gray-400">
          {moment(transaction.paymentDate)
            .subtract(4, "hours")
            .format("YYYY-MM-DD hh:mm a")}
        </p>

        <ProofOfPaymentModal
          retention={transaction}
          openModal={openProofModal}
          setOpenModal={setOpenProofModal}
          onSaveProofOfPayment={onSaveProofOfPayment}
          isModification={isModification}
          setIsModification={setIsModification}
        />
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        <LinkText
          text={transaction.documentNumber ?? ""}
          onClick={() => navigate(`/documents/${transaction.documentID}`)}
        />
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.isRetention && transaction.proofOfPayment && (
          <div>
            <div className="flex gap-2">
              <p className="font-semibold text-gray-500 truncate">
                <LinkText
                  text="Ver comprobante"
                  onClick={() => {
                    previewFileDO(transaction.proofOfPayment!);
                  }}
                />
              </p>
              <p
                className={classNames(
                  "font-semibold text-gray-500 truncate",
                  (transaction.status === PaymentStatusEnum.CANCELED ||
                    transaction.status === PaymentStatusEnum.REJECT) &&
                    "hidden"
                )}
              >
                <LinkText
                  text="Cambiar"
                  onClick={() => {
                    setOpenProofModal(true);
                    setIsModification(true);
                  }}
                />
              </p>
            </div>
            <p className="hidden">
              {moment(transaction.paymentDate).format("YYYY-MM-DD hh:mm a")}
            </p>
          </div>
        )}

        {transaction.isRetention &&
          !transaction.proofOfPayment &&
          transaction.status === PaymentStatusEnum.PENDING &&
          user?.roleName !== "Consulta Innovus" && (
            <LinkText
              text="Agregar comprobante"
              onClick={() => {
                setOpenProofModal(true);
                setIsModification(false);
              }}
            />
          )}

        {transaction.paymentMethod.paymentMethodName === "Nota de Crédito" &&
          document?.documentAffectedID && (
            <LinkText
              text="Ver comprobante"
              onClick={() =>
                navigate(`/documents/${document?.documentAffectedID}`)
              }
            />
          )}
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.reference?.includes(":")
          ? transaction.reference.split(":")[0]
          : transaction.reference}
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.creationUser}
      </td>

      <td className="text-xs text-right px-4 py-2 font-semibold w-auto text-gray-600 truncate">
        <div className="flex flex-col items">
          <p className="font-semibold text-gray-600 truncate">
            {currencyExchangeText(transaction.amount, exchanges, "USD")}
          </p>
          <p className="text-xs text-gray-400 ">
            {currencyExchangeText(transaction.amount, exchanges, "BS")}
          </p>
        </div>
      </td>
    </tr>
  );
};

export default PendingPaymentItem;
