import { FC, useState } from "react";
import moment from "moment";
import Modal from "../Modal";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { loaderService, previewFileDO } from "../../services";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import {
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  currencyExchangeText,
  formatPaymentStatus,
  useCurrencyExchanges,
} from "../../utils";
import {
  BankAccountInterface,
  DocumentInterface,
  PaymentInterface,
  PaymentMethodEnum,
  PaymentStatusEnum,
} from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { FormSelect } from "../FormFields";

interface PaymentItemProps {
  index: number;
  transaction: PaymentInterface;
  document?: DocumentInterface;
  vtid?: string;
  last?: boolean;
  onDeletePayment: () => void;
  onAddProof: () => void;
  onChangeProof: () => void;
  onDeleteProof: () => void;
  onApproveReintegration?: (
    reintegration: PaymentInterface,
    bankAccount: BankAccountInterface
  ) => void;
  onCancelPayment: (payment: PaymentInterface) => void;
}
const PaymentItem: FC<PaymentItemProps> = ({
  index,
  transaction,
  document,
  vtid,
  last,
  onDeletePayment,
  onAddProof,
  onChangeProof,
  onDeleteProof,
  onCancelPayment,
  onApproveReintegration = () => {},
}) => {
  const navigate = useNavigate();
  const exchanges = useCurrencyExchanges();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user);

  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [paymentToCancel, setPaymentToCancel] = useState<PaymentInterface>();
  const [bankAccount, setBankAccount] = useState<BankAccountInterface>();
  const [approveReintegrationModal, setApproveReintegrationModal] =
    useState(false);

  const bankAccountList: BankAccountInterface[] = [
    {
      bankID: 0,
      bankAccountID: 0,
      bankName: "No procesado",
    },
    {
      bankID: 6,
      bankAccountID: 11,
      bankName: "Bancaribe (7628)",
    },
    {
      bankID: 13,
      bankAccountID: 46,
      bankName: "BFC (5027)",
    },
    {
      bankID: 18,
      bankAccountID: 19,
      bankName: "Bancrecer (5747)",
    },
    {
      bankID: 4,
      bankAccountID: 1,
      bankName: "Mercantil (0740)",
    },
    {
      bankID: 9,
      bankAccountID: 12,
      bankName: "Banesco (2750)",
    },
    {
      bankID: 9,
      bankAccountID: 45,
      bankName: "Banesco (0539)",
    },
    {
      bankID: 26,
      bankAccountID: 14,
      bankName: "BNC (9313)",
    },
    {
      bankID: 5,
      bankAccountID: 17,
      bankName: "Provincial (0193)",
    },
  ];

  const paymentMethodName = (transaction: PaymentInterface): string => {
    const methodName = transaction.paymentMethod.paymentMethodName;
    if (
      transaction.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.EFECTIVO ||
      transaction.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.VUELTO_EFECTIVO
    ) {
      if (transaction.paymentMethod.currencyID === 1) {
        return `${methodName} en Bolívares`;
      }
      return `${methodName} en Dólares`;
    }

    return methodName;
  };

  return (
    <tr
      className={classNames(
        index % 2 === 0 && "bg-gray-50",
        last && "border-b-2 border-gray-300"
      )}
    >
      <td className="text-xs px-4 py-2 font-bold truncate">
        <div className="flex items-center">
          <div hidden={user.user?.roleName === "Consulta Innovus"}>
            <div
              onClick={onDeletePayment}
              className={classNames(
                "inline-flex p-2 rounded-full bg-red-50 hover:bg-red-100 cursor-pointer mr-2",
                (transaction.status === PaymentStatusEnum.CANCELED ||
                  transaction.status === PaymentStatusEnum.REJECT ||
                  transaction.status === PaymentStatusEnum.APPROVE ||
                  (transaction.paymentMethod.paymentMethodID ===
                    PaymentMethodEnum.REINTEGRO &&
                    user?.user!.roleName !== "Superadministrador")) &&
                  "hidden"
              )}
            >
              <TrashIcon className="h-4 w-4 text-red-500" />
            </div>
          </div>

          <div>
            <p>{paymentMethodName(transaction)}</p>
            {!!transaction.creationDate && (
              <p className="text-gray-700">
                <span className="text-gray-800 font-normal">Registrado: </span>{" "}
                {new Date(transaction.creationDate)
                  .toLocaleDateString("es-VE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                  .replace("a", "AM")
                  .replace("p", "PM")
                  .slice(0, -4)}
              </p>
            )}
          </div>
        </div>
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.isRetention && transaction.proofOfPayment && (
          <div>
            <div className="flex gap-2">
              <p className="font-semibold text-gray-500 truncate">
                <LinkText
                  text="Ver comprobante"
                  onClick={() => {
                    previewFileDO(transaction.proofOfPayment!);
                  }}
                />
              </p>
              <p
                className={classNames(
                  "font-semibold text-gray-500 truncate",
                  (transaction.status === PaymentStatusEnum.CANCELED ||
                    transaction.status === PaymentStatusEnum.REJECT) &&
                    "hidden"
                )}
              >
                <LinkText text="Cambiar" onClick={onChangeProof} />
              </p>
              <p
                className={classNames(
                  "font-semibold text-gray-500 truncate",
                  transaction.status !== PaymentStatusEnum.PENDING && "hidden"
                )}
              >
                <LinkText text="Eliminar" onClick={onDeleteProof} />
              </p>
            </div>
            <p className="hidden">
              {moment(transaction.paymentDate).format("YYYY-MM-DD hh:mm a")}
            </p>
          </div>
        )}

        {transaction.isRetention &&
          !transaction.proofOfPayment &&
          user.user?.roleName !== "Consulta Innovus" &&
          transaction.status === PaymentStatusEnum.PENDING && (
            <LinkText text="Agregar comprobante" onClick={onAddProof} />
          )}

        {transaction.paymentMethod.paymentMethodName === "Nota de Crédito" &&
          document?.documentAffectedID && (
            <LinkText
              text="Ver comprobante"
              onClick={() =>
                navigate(`/documents/${document?.documentAffectedID}`)
              }
            />
          )}
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.reference?.includes(":")
          ? transaction.reference.split(":")[0]
          : transaction.reference}
      </td>

      <td className="text-xs px-4 py-2 font-semibold font-bold text-gray-500 truncate">
        {transaction.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.REINTEGRO
          ? transaction.status === PaymentStatusEnum.PENDING
            ? formatPaymentStatus(transaction.status ?? 1)
            : transaction.destBankID === 0
            ? "Reintegro no procesado"
            : formatPaymentStatus(transaction.status ?? 1)
          : formatPaymentStatus(transaction.status ?? 1)}
        <br />
        {transaction.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.REINTEGRO &&
          transaction.status === PaymentStatusEnum.PENDING &&
          user.user?.roleName === "Superadministrador" && (
            <>
              <div className="mt-2 mb-2 items-start align-middle">
                <LinkText
                  text="Aprobar reintegro"
                  disabled={bankAccount === undefined}
                  className="text-xs"
                  onClick={() => {
                    setApproveReintegrationModal(true);
                  }}
                />
                <FormSelect
                  label="Cuenta pagadora"
                  name="bank"
                  options={bankAccountList}
                  optionString={(method) => `${method.bankName ?? "Buscar..."}`}
                  selected={bankAccount}
                  onSelectOption={(bank) => setBankAccount(bank)}
                />
              </div>
              {/* Warning modal */}
              <Modal
                openModal={approveReintegrationModal}
                setOpenModal={setApproveReintegrationModal}
              >
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ maxWidth: "20rem" }}
                >
                  <div className="flex flex-col items-center justify-center w-full">
                    <ExclamationTriangleIcon className="w-32 h-32" />
                  </div>
                  <p className="mt-2 text-lg text-center text-gray-700">
                    ¿Está seguro que desea aprobar el reintegro?
                  </p>
                  <div className="mt-4 flex flex-row justify-center gap-12">
                    <SecondaryButton
                      className="px-4"
                      onClick={() => setApproveReintegrationModal(false)}
                    >
                      Cancelar
                    </SecondaryButton>

                    <PrimaryButton
                      className="px-4"
                      disabled={loading}
                      onClick={() => {
                        setApproveReintegrationModal(false);
                        onApproveReintegration(transaction, bankAccount!);
                        setBankAccount({} as BankAccountInterface);
                      }}
                    >
                      Aceptar
                    </PrimaryButton>
                  </div>
                </div>
              </Modal>
            </>
          )}
        {[
          PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO,
          PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO,
          PaymentMethodEnum.PAGO_MERCHANT_TARJETA_CONCILIACION_MANUAL,
        ].includes(transaction.paymentMethod.paymentMethodID) &&
          transaction.status === PaymentStatusEnum.PENDING &&
          vtid === transaction.reference?.split(":")[1]?.toLowerCase() && (
            <>
              <LinkText
                text="Anular pago"
                className="text-xs"
                onClick={() => {
                  setPaymentToCancel(transaction);
                  setOpenWarningModal(true);
                }}
              />

              {/* Warning modal */}
              <Modal
                openModal={openWarningModal}
                setOpenModal={setOpenWarningModal}
              >
                <div
                  className="flex flex-col items-center justify-center"
                  style={{ maxWidth: "20rem" }}
                >
                  <div className="flex flex-col items-center justify-center w-full">
                    <ExclamationTriangleIcon className="w-32 h-32" />
                  </div>
                  <p className="mt-2 text-lg text-center text-gray-700">
                    ¿Está seguro que desea anular el pago?
                  </p>
                  <div className="mt-4 flex flex-row justify-center gap-12">
                    <SecondaryButton
                      className="px-4"
                      onClick={() => setOpenWarningModal(false)}
                    >
                      Cancelar
                    </SecondaryButton>

                    <PrimaryButton
                      className="px-4"
                      disabled={loading}
                      onClick={() => {
                        setOpenWarningModal(false);
                        onCancelPayment(paymentToCancel!);
                      }}
                    >
                      Aceptar
                    </PrimaryButton>
                  </div>
                </div>
              </Modal>
            </>
          )}
      </td>

      <td className="text-xs text-right px-4 py-2 font-semibold w-auto text-gray-600 truncate">
        <div className="flex flex-col items">
          <p className="font-semibold text-gray-600 truncate">
            {currencyExchangeText(transaction.amount, exchanges, "USD")}
          </p>
          <p className="text-xs text-gray-400 ">
            {currencyExchangeText(transaction.amount, exchanges, "BS")}
          </p>
        </div>
      </td>
    </tr>
  );
};

export default PaymentItem;
