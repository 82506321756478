import { FC, useMemo } from "react";
import Modal from "../Modal";
import logo from "../../assets/LogoTEALCA.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PaymentMethodEnum, PaymentMethodInterface } from "../../interfaces";

interface PaymentInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  method?: PaymentMethodInterface;
  bankID?: number;
}

// Payment info modal
export const PaymentInfoModal: FC<PaymentInfoModalProps> = ({
  isOpen,
  onClose,
  method,
  bankID,
}) => {
  const methodID = useMemo(() => method?.paymentMethodID, [method]);

  const name = useMemo(
    () =>
      methodID === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA
        ? "Datos Transferencia Inmediata"
        : "Datos Pago Móvil",
    [methodID]
  );

  const beneficiary = useMemo(
    () =>
      methodID === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA
        ? "Transferencias y Encomiendas Angulo López, C.A."
        : "",
    [methodID]
  );

  const bank = useMemo(() => {
    if (bankID === 6) {
      return "Bancaribe - 0114";
    } else if (bankID === 4) {
      return "Mercantil - 0105";
    }
  }, [bankID]);

  const rif = "J-002206080";

  const phone = useMemo(() => {
    if (methodID === PaymentMethodEnum.PAGO_MOVIL_P2C) {
      if (bankID === 6) {
        return "0424-1668634";
      } else if (bankID === 4) {
        return "0424-1668634";
      }
    }
    return "";
  }, [methodID, bankID]);

  const accountNumber = useMemo(() => {
    if (methodID === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA) {
      if (bankID === 6) {
        return "0114-0190-2019-0002-7628";
      } else if (bankID === 4) {
        return "0105-0083-4210-8306-0740";
      }
    }
    return "";
  }, [methodID, bankID]);

  return (
    <Modal
      openModal={isOpen}
      setOpenModal={onClose}
      className="max-w-lg w-full"
    >
      <div className="flex justify-end">
        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => onClose()}
        >
          <span className="absolute -inset-2.5" />
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div className="bg-white pl-4 pb-4">
        <div className="flex items-center">
          <div className="flex-shrink-0 flex items-center justify-center h-24 w-24 rounded-full bg-blue-100">
            <img src={logo} className="App-logo" alt="Logo" />
          </div>
          <div className="text-left ml-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {name}
            </h3>
            <div className="mt-2">
              {beneficiary && (
                <p className="text-md text-gray-500">
                  Beneficiario:{" "}
                  <span className="font-bold">{`${beneficiary}`}</span>
                </p>
              )}
              <p className="text-md text-gray-500">
                Banco: <span className="font-bold">{`${bank}`}</span>
              </p>
              <p className="text-md text-gray-500">
                RIF: <span className="font-bold">{`${rif}`}</span>
              </p>
              {accountNumber && (
                <p className="text-md text-gray-500">
                  Nro. Cuenta:{" "}
                  <span className="font-bold">{`${accountNumber}`}</span>
                </p>
              )}
              {phone && (
                <p className="text-md text-gray-500">
                  Teléfono: <span className="font-bold">{`${phone}`}</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
