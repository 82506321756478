import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import logo from "../../assets/LogoTEALCA.svg";
import { useAppSelector } from "../../store/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormTextInput } from "../../components/FormFields";
import { changePassword } from "../../services/authetication";
import { LinkText, PrimaryButton } from "../../components/Buttons";
import { loaderService } from "../../services";

export interface UserPasswordUpdateFormValues {
  loginOrEmail: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}
const initialValues: UserPasswordUpdateFormValues = {
  loginOrEmail: "",
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};
interface UserPasswordUpdateProps {
  setShowUpadatePassword?: Dispatch<SetStateAction<boolean>>;
}
const UserPasswordUpdate: FC<UserPasswordUpdateProps> = ({
  setShowUpadatePassword,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useParams();
  const { userLoginEmailToken } = useParams();
  const userLogged = useAppSelector((state) => state.user.user);
  const appData = useAppSelector((state) => state.inmutable.appData);

  const [apiErrors, setApiErrors] = useState<string[]>([]);
  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    //If there is an user, but this page is not called from Profile, then redirect to profile
    if (!!userLogged && userToken === undefined && !setShowUpadatePassword) {
      navigate("/profile");
    }
  }, [userLogged, setShowUpadatePassword, navigate, userToken]);

  const updatePassword = async (values: UserPasswordUpdateFormValues) => {
    setApiErrors([]);

    loaderService.start();
    const response = await changePassword(
      {
        UserEmailOrLogin:
          location.state?.email ?? userLoginEmailToken ?? values.loginOrEmail,
        UserOldPassword: location.state?.password ?? values.oldPassword,
        UserNewPassword: values.newPassword,
        ApplicationCode: appData.applicationCode,
        UserTokenHash: !!userToken ? userToken : null,
      }
      //userLogged?.token ?? ""
    );
    loaderService.stop();

    if (!response || response.didError) {
      setApiErrors([
        ...apiErrors,
        "Error al cambiar la contraseña: " + response.message,
      ]);
    } else {
      setPasswordChanged(true);
    }
  };

  const validationSchema = Yup.object().shape({
    loginOrEmail: Yup.string().test(
      "loginOrEmail",
      "Debe ingresar el correo o el username",
      function (value) {
        return !!value || !!location.state || !!userToken;
      }
    ),
    oldPassword: Yup.string().test(
      "oldPassword",
      "Debe ingresar la contraseña actual",
      function (value) {
        return !!value || !!location.state || !!userToken;
      }
    ),
    newPassword: Yup.string().required("Debe ingresar la nueva contraseña"),
    repeatPassword: Yup.string()
      .required("Debe repetir la nueva contraseña")
      .test(
        "passwords-match",
        "Las contraseñas no coinciden",
        function (value) {
          return this.parent.newPassword === value;
        }
      ),
  });

  return (
    <div className="flex h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full h-full max-w-sm flex flex-1 flex-col justify-around">
        <div>
          {!userLogged && (
            //Logo header
            <>
              <h1 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
                Taquilla TEALCA
              </h1>
              <img src={logo} className="mx-auto h-30 w-auto" alt="logo" />
            </>
          )}
          <h2 className="mt-5 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
            {!!location.state ? "Crea tu contraseña" : "Cambiar contraseña"}
          </h2>
        </div>

        {!passwordChanged && (
          <Formik<UserPasswordUpdateFormValues>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              updatePassword(values).then(() => {
                actions.setSubmitting(false);
                actions.resetForm({
                  values: initialValues,
                });
              });
            }}
          >
            {(formik) => {
              const {
                errors,
                values,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
              } = formik;
              return (
                <form className="space-y-6" onSubmit={handleSubmit}>
                  {!!apiErrors && apiErrors.length > 0 && (
                    <p className="text-center ">
                      <span className="text-sm text-red-500">
                        {apiErrors.join(", ")}
                      </span>
                    </p>
                  )}
                  <div className="flex flex-1 flex-col gap-2">
                    <div
                      className={classNames(
                        (!!location.state || !!userToken) && "hidden"
                      )}
                    >
                      <FormTextInput
                        id="new-password-email"
                        name="loginOrEmail"
                        error={
                          touched.loginOrEmail && errors.loginOrEmail
                            ? errors.loginOrEmail
                            : ""
                        }
                        placeholder="Correo electrónico o username"
                        value={values.loginOrEmail}
                        onChange={(value) => {
                          handleChange(value);
                          setApiErrors([]);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div
                      className={classNames(
                        (!!location.state || !!userToken) && "hidden"
                      )}
                    >
                      <FormTextInput
                        id="old-password"
                        name="oldPassword"
                        type="password"
                        error={
                          touched.oldPassword && errors.oldPassword
                            ? errors.oldPassword
                            : ""
                        }
                        placeholder="Contraseña Actual"
                        value={values.oldPassword}
                        onChange={(value) => {
                          handleChange(value);
                          setApiErrors([]);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div>
                      <FormTextInput
                        id="new-password"
                        name="newPassword"
                        type="password"
                        error={
                          touched.newPassword && errors.newPassword
                            ? errors.newPassword
                            : ""
                        }
                        placeholder="Nueva Contraseña"
                        value={values.newPassword}
                        onChange={(value) => {
                          handleChange(value);
                          setApiErrors([]);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>

                    <div>
                      <FormTextInput
                        id="repeat-password"
                        name="repeatPassword"
                        type="password"
                        error={
                          touched.repeatPassword && errors.repeatPassword
                            ? errors.repeatPassword
                            : ""
                        }
                        placeholder="Repetir Contraseña"
                        value={values.repeatPassword}
                        onChange={(value) => {
                          handleChange(value);
                          setApiErrors([]);
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="flex flex-1">
                    <PrimaryButton type="submit" className="w-full">
                      Aceptar
                    </PrimaryButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        {passwordChanged && (
          <div className="flex flex-col items-center">
            <p className="text-center ">
              <span className="text-green-500">
                La contraseña ha sido cambiada exitosamente.
                {!userLogged
                  ? "Por favor inicie sesión con la nueva contraseña."
                  : ""}
              </span>
            </p>
          </div>
        )}

        <div className="flex flex-col items-center">
          {!userLogged && (!location.state || passwordChanged) && (
            <LinkText
              text="Iniciar Sesión"
              onClick={() => navigate("/login")}
            />
          )}
          {!!setShowUpadatePassword && (
            <LinkText
              text="Volver"
              onClick={() => setShowUpadatePassword(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default UserPasswordUpdate;
