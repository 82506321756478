import { createContext } from "react";

export interface PaymentCallbackContextInterface {
  onFinishPayment: () => void;
}

export const PaymentCallbackContext =
  createContext<PaymentCallbackContextInterface>({
    onFinishPayment: () => {},
  });
