import { LocationInterface, Nullable, Undefinable } from "../interfaces";

const callback = (
  details: Nullable<google.maps.places.PlaceResult>,
  status: google.maps.places.PlacesServiceStatus,
  place: Undefinable<google.maps.places.PlaceResult>,
  lat: number,
  lng: number,
  setLocation: (location: LocationInterface) => void
) => {
  if (
    status !== window.google.maps.places.PlacesServiceStatus.OK ||
    (!details && !place)
  ) {
    return;
  }

  // Get location data
  let location: LocationInterface;
  if (!!details) {
    location = {
      name: details.name ?? "",
      address: details.formatted_address ?? "",
      coordinates: { lat, lng },
      postalCode: details.address_components?.find((component) =>
        component.types.includes("postal_code")
      )?.long_name,
    };
  } else if (!!place) {
    location = {
      name: place.name ?? "",
      address: place.formatted_address ?? "",
      coordinates: { lat, lng },
      postalCode: place.address_components?.find((component) =>
        component.types.includes("postal_code")
      )?.long_name,
    };
  } else {
    return;
  }

  setLocation(location);
};

export const getDetailsFromCoords = (
  lat: number,
  lng: number,
  mapRef: React.MutableRefObject<google.maps.Map | undefined>,
  setLocation: (location: LocationInterface) => void
) => {
  if (!mapRef.current) {
    return;
  }

  const geocoder = new window.google.maps.Geocoder();
  const places = new window.google.maps.places.PlacesService(mapRef.current);
  if (!geocoder || !places) {
    return;
  }

  const request1 = {
    location: new window.google.maps.LatLng(lat, lng),
  };

  geocoder.geocode(request1, (results, status) => {
    if (
      status !== window.google.maps.GeocoderStatus.OK ||
      !results ||
      results.length === 0
    ) {
      return;
    }

    const filtered = results.filter(
      (r) => r.types.includes("route") || r.types.includes("intersection")
    );
    const selected = filtered[0] ?? results[0];
    const request2: google.maps.places.PlaceDetailsRequest = {
      placeId: selected.place_id ?? "",
    };

    places.getDetails(request2, (place, status) =>
      callback(place, status, undefined, lat, lng, setLocation)
    );
  });
};
