import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import HorizontalPadding from "../components/HorizontalPadding";
import { DashboardDataDTO } from "../interfaces/Dtos/DashboardDataDTO";
import {
  getBusinessDashboardData,
  getStoreDocuments,
  searchShipmentPendingPOD,
} from "../services";
import {
  formatAmount,
  currencyExchange,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../utils";
import {
  ScaleIcon,
  BanknotesIcon,
  DocumentPlusIcon,
  InboxArrowDownIcon,
  CurrencyDollarIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { StoreDocumentInterface } from "../interfaces";
import { StoreShipmentInterface } from "../interfaces/Dtos";

interface DashboardCardProps {
  title: string;
  primary?: boolean;
  isAmount?: boolean;
  value: number;
  Icon: React.ElementType;
  extraDescription?: string;
}
const DashboardCard: FC<DashboardCardProps> = ({
  title,
  value,
  Icon,
  isAmount = false,
  primary = false,
  extraDescription,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-1 h-full flex-col items-center justify-between bg-white rounded-xl shadow-md hover:shadow-lg border px-8 pb-2 pt-4 border-2"
      )}
    >
      <div className="flex gap-2 items-center">
        <span className="text-center">
          <Icon className="inline align-middle self-center mr-2 h-6 w-6 text-indigo-600" />
          <span
            className={classNames(
              "text-center",
              primary
                ? "text-indigo-600 font-semibold text-lg"
                : "font-semibold text-gray-700 text-sm"
            )}
          >
            {title}
          </span>
        </span>
      </div>

      <div className="flex flex-col items-center -mt-1">
        <p
          className="text-gray-900"
          style={{ fontSize: "4rem", lineHeight: "4rem" }}
        >
          {isAmount ? formatAmount(value) : value}
        </p>
        <p className="font-medium h-8 text-gray-700">{extraDescription}</p>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);

  const [dashboardData, setDashboardData] = useState<DashboardDataDTO>();
  const [documents, setDocuments] = useState<StoreDocumentInterface[]>([]);
  const [shipments, setShipments] = useState<StoreShipmentInterface[]>([]);

  const documentsToPaid = useMemo(() => {
    return documents.filter(
      (document) => document.documentGroup === "Por Cobrar"
    );
  }, [documents]);

  const documentsWithExcess = useMemo(() => {
    return documents.filter(
      (document) => document.documentGroup === "Excedente de Pago"
    );
  }, [documents]);

  const documentsWithRetentions = useMemo(() => {
    return documents.filter(
      (document) => document.documentGroup === "Retenciones Pendientes"
    );
  }, [documents]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getData = async () => {
      const response = await getBusinessDashboardData(buCode);
      if (!response.didError && response.model) {
        setDashboardData(response.model);
      }
    };

    getData();
  }, [user.businessUnit]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getDocuments = async () => {
      const response = await getStoreDocuments(buCode);
      if (response.didError || !response.model) {
        setDocuments([]);
        return;
      }

      setDocuments(response.model);
    };

    getDocuments();
  }, [user.businessUnit]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) {
      return;
    }

    const getShipments = async () => {
      const shipments = await searchShipmentPendingPOD(buCode);

      if (!shipments || shipments.didError || !shipments.model) {
        return;
      }

      setShipments(shipments.model);
    };

    getShipments();
  }, [user.businessUnit]);

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          {!!user.businessUnit
            ? `${user.businessUnit.code} - ${user.businessUnit.location.name}`
            : "Dashboard"}{" "}
        </header>
      </div>

      <HorizontalPadding>
        <div className="flex flex-1 flex-col gap-6">
          <div className="flex justify-between items-center gap-6 flex-wrap">
            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/shipments")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                primary
                value={shipments.length}
                Icon={InboxArrowDownIcon}
                title="Guías por Entregar"
                extraDescription={`Total: ${currencyExchangeText(
                  shipments.reduce(
                    (acc, ship) => acc + ship.totalAmountBaseCurr,
                    0
                  ),
                  exchanges,
                  "USD"
                )}`}
              />
            </div>

            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/documents")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                value={documentsToPaid.length ?? 0}
                Icon={ClipboardDocumentListIcon}
                title="Órdenes y Facturas por Cobrar"
                extraDescription={`Total: ${currencyExchangeText(
                  documentsToPaid.reduce(
                    (acc, doc) => acc + doc.balanceAmountBaseCurr,
                    0
                  ) ?? 0,
                  exchanges,
                  "USD"
                )}`}
              />
            </div>

            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/documents")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                value={documentsWithExcess.length ?? 0}
                Icon={ScaleIcon}
                title="Facturas con Pagos Excedentes"
              />
            </div>

            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/documents")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                value={documentsWithRetentions.length ?? 0}
                Icon={DocumentPlusIcon}
                title="Retenciones Pendientes"
              />
            </div>

            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/cash-desk")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                isAmount
                Icon={CurrencyDollarIcon}
                title="Efectivo por Depositar (USD)"
                value={dashboardData?.lastClosureDollar ?? 0}
              />
            </div>

            <div
              className="cursor-pointer flex-1"
              onClick={() => navigate("/cash-desk")}
              style={{ height: "10rem", minWidth: "20rem" }}
            >
              <DashboardCard
                isAmount
                Icon={BanknotesIcon}
                title="Efectivo por Depositar (VES)"
                value={dashboardData?.lastClosureBS ?? 0}
              />
            </div>
          </div>

          <div className="flex flex-col items-end ">
            <span className="text-sm">
              Tasa de Cambio <span className="font-semibold">BCV</span>
            </span>
            <div className="flex gap-2 items-center">
              <span>1 USD</span>

              <span>=</span>

              <span className="text-gray-900 font-semibold">
                {currencyExchange(1, exchanges, "BS", "USD")} VES
              </span>
            </div>
          </div>
        </div>
      </HorizontalPadding>

      {/* <PaymentInfoModal
        isOpen={P2CModal}
        onClose={() => setP2CModal(false)}
        paymentMethod="Datos Pago Móvil"
        rif="J-002206080"
        phone="0424-1668634"
        bank="Bancaribe - 0114"
      />

      <PaymentInfoModal
        isOpen={merchantP2CModal}
        onClose={() => setMerchantP2CModal(false)}
        paymentMethod="Datos Pago Móvil"
        rif="J-002206080"
        phone="0424-1668634"
        bank="Mercantil - 0105"
      /> */}
    </main>
  );
};

export default Dashboard;
