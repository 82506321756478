export enum DailyOperationType {
  INCOME = 10,
  EXPENSES = 20,
}

export enum DailyTransactionType {
  SALE = 10,
  DEPOSIT = 20,
  REJECTED_DEPOSIT = 30,
  CROSS_WITH_BENEFITS = 40,
  CHANGE_CASH = 50,
}

export enum DailyOperationStatus {
  PENDING = 10,
  APPROVED = 20,
  REJECTED = 30,
  ANULLED = 40,
}

export interface DailyStoreOperationDTO {
  dailyStoreOperationID: number;
  operationAffectedID: number;
  paymentID?: string;
  documentID?: string;
  documentNumber?: string;
  paymentDetailID?: string;
  operationTypeID: DailyOperationType;
  operationType: string;
  transactionTypeID: DailyTransactionType;
  transactionType: string;
  buCode: string;
  paymentMethodID?: number;
  currencyID: number;
  registerDate: string;
  operationDate: string;
  income: number;
  expenses: number;
  bankID?: number;
  bankName: string;
  bankAccountID?: number;
  referenceNum: string;
  operationStatusID: DailyOperationStatus;
  operationStatusCode: string;
  operationStatusDescription: string;
  lastStatusDate: string;
  lastStatusUser: string;
  attachmentID?: string;
  applied: boolean;
  creationUser: string;
  creationDate: string;
  updateUser: string;
  updateDate: string;
  lastupdate: number;
}
