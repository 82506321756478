import { FC } from "react";
import {
  AddressSearch,
  BusinessUnitSearch,
} from "../../components/LocationSearch";
import {
  DeliveryType,
  LocationInterface,
  BusinessUnitInterface,
} from "../../interfaces";
import { FormSimpleRadioGroup } from "../../components/FormFields";
import { AutocompleteRegion } from "../../components/Form/FormMapsAutocomplete";

interface LocationSelectProps {
  deliveryType?: DeliveryType;
  businessUnits: BusinessUnitInterface[];
  selectedLocation?: LocationInterface;
  selectedBusinessUnit?: BusinessUnitInterface;
  buSource?: BusinessUnitInterface;
  setLocationType: (type: DeliveryType) => void;
  setLocation: (
    location?: LocationInterface,
    businessUnit?: BusinessUnitInterface
  ) => void;
}
const LocationSelect: FC<LocationSelectProps> = ({
  deliveryType,
  businessUnits,
  selectedLocation,
  selectedBusinessUnit,
  buSource,
  setLocationType,
  setLocation,
}) => {
  return (
    <div className="flex flex-col px-4 bg-white py-4 rounded-lg border gap-2">
      <FormSimpleRadioGroup
        horizontal
        name="deliveryType"
        selected={deliveryType}
        onSelectOption={(type) => {
          setLocationType(type);
          setLocation(undefined, undefined);
        }}
        options={[
          { value: DeliveryType.AT_OFFICE, name: "Tienda" },
          { value: DeliveryType.AT_HOME, name: "Domicilio" },
          // {
          //   value: DeliveryType.INTERNATIONAL,
          //   name: "Internacional",
          // },
        ]}
        description="Buscar por:"
      />

      {deliveryType === DeliveryType.AT_OFFICE && (
        <BusinessUnitSearch
          buSource={buSource}
          selected={selectedBusinessUnit}
          setSelectedLocation={(businessUnit) =>
            setLocation(businessUnit.location, businessUnit)
          }
        />
      )}
      {deliveryType === DeliveryType.AT_HOME && (
        <AddressSearch
          businessUnits={businessUnits}
          selected={selectedLocation}
          region={AutocompleteRegion.TEALCA}
          setSelectedLocation={(location, businessUnit) => {
            setLocation(location, businessUnit);
          }}
        />
      )}

      {deliveryType === DeliveryType.INTERNATIONAL && (
        <AddressSearch
          businessUnits={businessUnits}
          selected={selectedLocation}
          region={AutocompleteRegion.INTERNATIONAL}
          setSelectedLocation={(location, businessUnit) =>
            setLocation(location, businessUnit)
          }
        />
      )}
    </div>
  );
};

export default LocationSelect;
