import { useState } from "react";
import classNames from "classnames";
import MenuTab from "../components/MenuTab";
import ShipmentTableSearch from "../components/Shipment/ShipmentTableSearch";
import PendingShipmentTable from "../components/Shipment/PendingShipmentTable";

const Shipments = () => {
  const [tab, setTab] = useState(0);
  const [forceBUSearch, setForceBUSearch] = useState(false);

  const selectTab = (tab: number) => {
    if (tab === 1) {
      setForceBUSearch(true);
    }
    setTab(tab);
  };

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <div>
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Guías
          </header>
        </div>
      </div>

      <div className="flex flex-1 flex-col gap-12 px-8 mb-24">
        <div className="flex flex-1 flex-col bg-white gap-2 rounded-lg border px-8 pb-6 pt-4">
          <h2 className="text-2xl font-semibold text-gray-900 truncate">
            Guías por Entregar
          </h2>

          <PendingShipmentTable />
        </div>

        <div className="flex flex-1 flex-col gap-4">
          <MenuTab
            current={tab}
            onTabClick={selectTab}
            tabs={[
              {
                name: "Todas las Guías",
                showCount: false,
              },
              {
                name: "Creadas en mi Tienda",
                showCount: false,
              },
            ]}
          />

          <div
            className={classNames(
              "flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-14",
              tab !== 0 && "hidden"
            )}
          >
            <ShipmentTableSearch />
          </div>

          <div
            className={classNames(
              tab !== 1 && "hidden",
              "flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-14"
            )}
          >
            <ShipmentTableSearch useBUCode forceSearch={forceBUSearch} />
          </div>
        </div>
      </div>
    </main>
  );
};
export default Shipments;
