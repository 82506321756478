import { FC, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { LinkText } from "../Buttons";
import { useNavigate } from "react-router-dom";
import { DailyStoreOperationModal } from "./DailyStoreOperationModal";
import { formatAmount, formatDailyOperationStatus } from "../../utils";
import {
  DailyOperationStatus,
  DailyOperationType,
  DailyStoreOperationDTO,
  DailyTransactionType,
} from "../../interfaces/Dtos/DailyStoreOperationDTO";

interface DailyStoreOperationItemProps {
  isDollar?: boolean;
  operation: DailyStoreOperationDTO;
  index: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
export const DailyStoreOperationItem: FC<DailyStoreOperationItemProps> = ({
  isDollar = false,
  operation,
  index,
  setRefresh,
}) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const creationDay = useMemo(() => {
    return (
      moment(operation.creationDate).format("dddd").charAt(0).toUpperCase() +
      moment(operation.creationDate).format("dddd").slice(1)
    );
  }, [operation.creationDate]);

  const operationDay = useMemo(() => {
    return (
      moment(operation.operationDate).format("dddd").charAt(0).toUpperCase() +
      moment(operation.operationDate).format("dddd").slice(1)
    );
  }, [operation.operationDate]);

  const amount = useMemo(() => {
    return operation.operationStatusID === DailyOperationStatus.ANULLED
      ? 0
      : operation.operationTypeID === DailyOperationType.INCOME
      ? operation.income
      : -operation.expenses;
  }, [
    operation.operationTypeID,
    operation.income,
    operation.expenses,
    operation.operationStatusID,
  ]);

  return (
    <tr
      className={classNames(
        "hover:bg-gray-100 cursor-pointer",
        index % 2 === 0 && "bg-gray-50"
      )}
      onClick={() => setOpenModal(true)}
    >
      <td className="px-4 py-4 text-xs text-left text-gray-500 font-semibold">
        {operation.dailyStoreOperationID}

        <div onClick={(e) => e.stopPropagation()}>
          <DailyStoreOperationModal
            operation={operation}
            open={openModal}
            setOpen={setOpenModal}
            setRefresh={setRefresh}
          />
        </div>
      </td>

      <td className="px-4 py-4 text-xs text-gray-800 font-semibold">
        <div className="flex flex-col">
          <span>{creationDay}</span>
          <span className="truncate">
            {moment(operation.creationDate).format("YYYY-MM-DD")}{" "}
          </span>
        </div>
      </td>

      <td className="px-4 py-4 text-xs text-gray-800 font-semibold">
        <div className="flex flex-col">
          <span>{operationDay}</span>
          <span className="truncate">
            {moment(operation.operationDate).format("YYYY-MM-DD")}{" "}
          </span>
        </div>
      </td>

      <td className="px-4 py-4 text-xs text-left text-gray-500 font-semibold">
        {operation.transactionType}
      </td>

      <td className="px-4 py-4 text-xs text-left text-gray-500 font-semibold">
        <div
          className={classNames(
            operation.transactionTypeID !== DailyTransactionType.SALE &&
              operation.transactionTypeID !==
                DailyTransactionType.CHANGE_CASH &&
              "hidden"
          )}
        >
          <p>Factura</p>
          <LinkText
            text={operation.documentNumber ?? ""}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/documents/${operation.documentID}`);
            }}
          />
        </div>

        <div
          className={classNames(
            operation.transactionTypeID !== DailyTransactionType.DEPOSIT &&
              "hidden"
          )}
        >
          <p>Nro. Referencia</p>
          <p className="text-gray-900">{operation.referenceNum}</p>
        </div>

        <div
          className={classNames(
            operation.transactionTypeID !==
              DailyTransactionType.REJECTED_DEPOSIT && "hidden"
          )}
        >
          <p>Operación</p>
          <p className="text-gray-900">{operation.operationAffectedID}</p>
        </div>
      </td>

      <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold">
        {isDollar ? "$" : "Bs"} {formatAmount(amount)}
      </td>

      <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold">
        {formatDailyOperationStatus(operation.operationStatusID)}
      </td>
    </tr>
  );
};
