import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import LoadingIcon from "../LodingIcon";
import { useAppSelector } from "../../store/hooks";
import PaginationFooter from "../PaginationFooter";
import { PDocumentTableItem } from "./PDocumentItem";
import { PDocumentStatus, PDocumentInterface } from "../../interfaces";
import { pdocumentDynamicSearch } from "../../services/pdocumentServices";

const ROWS_PER_PAGE = 10;

interface ActivePDocumentTableProps {
  reload: string;
  setReload: (value: string) => void;
}
export const ActivePDocumentTable: FC<ActivePDocumentTableProps> = ({
  reload,
  setReload,
}) => {
  const user = useAppSelector((state) => state.user);

  const [loader, setLoader] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pdocuments, setPDocuments] = useState<PDocumentInterface[]>([]);

  const pdocumentsPaginated = useMemo(() => {
    return pdocuments.slice(itemOffset, itemOffset + ROWS_PER_PAGE);
  }, [pdocuments, itemOffset, ROWS_PER_PAGE]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * ROWS_PER_PAGE) % pdocuments.length;
    setItemOffset(newOffset);
  };

  const getDocuments = async () => {
    setLoader(true);
    const response = await pdocumentDynamicSearch(user.businessUnit?.code, [
      PDocumentStatus.PENDING,
    ]);
    if (response) {
      setPDocuments(
        response.sort((a, b) => b.reportToDate.localeCompare(a.reportToDate))
      );
    }
    setLoader(false);
  };

  useEffect(() => {
    getDocuments();
  }, [user.businessUnit?.code, reload]);

  // Set total pages
  useEffect(() => {
    setTotalPages(Math.ceil(pdocuments.length / ROWS_PER_PAGE));
  }, [itemOffset, pdocuments]);

  return (
    <div className="flex flex-col max-w-full flex-1 gap-4">
      <div className="flex w-full justify-between mr-8 gap-4">
        <h2 className="text-2xl font-semibold text-gray-900 truncate">
          Por facturar:
        </h2>
        <div style={{ width: "2rem" }}>
          {loader && <LoadingIcon size="2rem" />}
        </div>
      </div>

      <div
        className={classNames(
          "overflow-x-auto",
          pdocuments.length === 0 && "hidden"
        )}
      >
        <table className="table-auto w-full mt-4">
          <thead>
            <tr>
              <th className="text-left px-4 py-2 font-semibold text-xs">
                REPORTE
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                TIPO
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                FECHA DE BENEFICIOS
              </th>

              <th className="text-right px-4 py-2 font-semibold text-xs truncate">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            {pdocumentsPaginated.map((pdocument, index) => {
              return (
                <PDocumentTableItem
                  isActive
                  index={index}
                  pdocument={pdocument}
                  setReload={setReload}
                  key={pdocument.documentID}
                />
              );
            })}
          </tbody>
        </table>

        <PaginationFooter
          rowCounts={ROWS_PER_PAGE}
          itemsOffSet={itemOffset}
          totalItems={pdocuments.length}
          handlePageClick={handlePageClick}
          totalPages={totalPages}
        />
      </div>

      <div
        className={classNames(
          "flex flex-1 items-center justify-center min-h-[5rem]",
          pdocuments.length > 0 && "hidden"
        )}
      >
        <p className="text-gray-400">No hay facturas registradas</p>
      </div>
    </div>
  );
};
