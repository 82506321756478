import React from "react";
import Disclosure from "./Disclosure";

export interface DropdownMenuOption<T> {
  title: string;
  description?: string;
  value: T;
}
interface DropdownMenuProps<T> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect: (option: DropdownMenuOption<T>) => void;
  options: DropdownMenuOption<T>[];
  className?: string;
}
const DropdownMenu = <T extends any>({
  open,
  setOpen,
  onSelect,
  options,
  className,
}: DropdownMenuProps<T>) => {
  return (
    <Disclosure open={open} setOpen={setOpen} className={className}>
      <div className="w-full min-w-[8rem]">
        {options.map((option) => (
          <div
            key={option.title}
            onClick={() => {
              onSelect(option);
              setOpen(false);
            }}
            className="cursor-pointer select-none p-2 text-sm text-gray-900 hover:bg-indigo-600 hover:text-white font-normal"
          >
            <div className="flex flex-col">
              <div className="flex justify-between">
                <p className="truncate">{option.title}</p>
              </div>
              {!!option.description && (
                <p className="font-normal">{option.description}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </Disclosure>
  );
};

export default DropdownMenu;
