import { BusinessUnitInterface } from "./LocationInterface";

export const ADMIN_ROLES = [
  "Supervisor",
  "Administrador",
  "Superadministrador",
];

export interface UserInterface {
  userID: string;
  userLogin: string;
  userName: string;
  userEmail: string;
  userLastName: string;
  statusID: number;
  userPasswordExpiration: Date;
  actionsModuleName: string[];
  roleName: string;
  token: string;
  expiration: Date;
  businessUnitList: BusinessUnitInterface[];
}
