import React, { useEffect, useState } from "react";
import { PrimaryButton } from "./Buttons";
import {
  payMerchant,
  changeMerchant,
  miscMerchant,
  annulationMerchant,
} from "../services";
import { MerchantInterface, Nullable } from "../interfaces";

const MerchantTest = () => {
  const [total, setTotal] = useState(0);
  const [ci, setCi] = useState("");
  const [response, setResponse] = useState<Nullable<MerchantInterface>>(null);
  const [sequence, setSequence] = useState("");

  const inputClassName =
    "focus block w-full rounded-xl border-0 text-gray-400 " +
    "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
    "placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
    "focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
    "invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer " +
    "h-10";

  const payMer = async (total: number, ci: string) => {
    const res = await payMerchant(total, ci);
    setResponse(res);
  };

  const changeMer = async (total: number, ci: string) => {
    const res = await changeMerchant(total, ci);
    setResponse(res);
  };

  const miscMer = async (option: number) => {
    const res = await miscMerchant(option);
    setResponse(res);
  };

  const cancelMer = async (seq: string) => {
    const res = await annulationMerchant(seq);
    setResponse(res);
  };

  useEffect(() => {
    if (response !== null) {
      alert(`Código de respuesta: ${response?.codRespuesta} \n
      numSeq: ${response?.numSeq} \n
      Respuesta de Merchant: ${response?.mensajeRespuesta} \n
      Monto: ${response?.montoTransaccion}`);
    }
  }, [response]);

  return (
    <div className="bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4 w-3/4">
      <h2 className="text-xl font-bold pb-4">Merchant test</h2>
      <label>
        CI:
        <input
          type="text"
          value={ci}
          onChange={(event) => setCi(event.target.value)}
          className={inputClassName}
        />
      </label>
      <br />
      <label>
        Monto:
        <input
          type="number"
          value={total}
          onChange={(event) => setTotal(event.target.valueAsNumber)}
          className={inputClassName}
        />
      </label>
      <br />

      <div className="flex justify-around items-center">
        <PrimaryButton className="mx-1" onClick={() => payMer(total, ci)}>
          Pagos
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => changeMer(total, ci)}>
          Cambio
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => miscMer(0)}>
          Ult Vou Aprobado
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => miscMer(1)}>
          Ult Vou Procesado
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => miscMer(2)}>
          Pre Cierre
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => miscMer(3)}>
          Cierre
        </PrimaryButton>
        <PrimaryButton className="mx-1" onClick={() => miscMer(4)}>
          Ultimo Cierre
        </PrimaryButton>
        <div className="mx-1">
          <label>
            Secuencia:
            <input
              type="number"
              value={sequence}
              onChange={(event) => setSequence(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <PrimaryButton onClick={() => cancelMer(sequence)}>
            Anular
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default MerchantTest;
