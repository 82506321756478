import { FC } from "react";
import MerchantTest from "../components/MerchantTest";
import BCTest from "../components/BCTest";
import DHLTest from "../components/DHLTest";
import DOtest from "../components/DOtest";
import MercantilTest from "../components/MercantilTest";

const TestLab: FC = () => {
  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <div>
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Test Lab
          </header>
        </div>
      </div>

      <div className="flex justify-center py-8">
        <DHLTest />
      </div>
      <div className="flex justify-center py-8">
        <DOtest />
      </div>
      <div className="flex justify-center py-8">
        <MerchantTest />
      </div>
      <div className="flex justify-center py-8">
        <BCTest />
      </div>
      <div className="flex justify-center py-8">
        <MercantilTest />
      </div>
    </main>
  );
};

export default TestLab;
