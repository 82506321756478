import { useState, FC, useMemo } from "react";
import classNames from "classnames";
import { ShipmentInterface, ShipmentSimpleInterface } from "../../interfaces";
import { TrashIcon } from "@heroicons/react/20/solid";
import ShipmentDetailsModal from "./ShipmentDetailsModal";
import { formatName, shipmentStatusFormat } from "../../utils/format";
import {
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils/currencyExchange";

export enum ShipmentField {
  ID,
  SHIPPER,
  BU_SOURCE,
  CONSIGNEE,
  DESTINATION,
  STATUS,
  AMOUNT,
}
interface ShipmentElementProps {
  index: number;
  fields: ShipmentField[];
  shipment: ShipmentSimpleInterface | ShipmentInterface;
  showDetails?: boolean;
  editable?: boolean;
  className?:
    | string
    | ((shipment: ShipmentSimpleInterface | ShipmentInterface) => string);
  onShipmentClick?: (
    shipment: ShipmentSimpleInterface | ShipmentInterface
  ) => void;
  onDelete?: (index: number) => void;
}
const ShipmentElement: FC<ShipmentElementProps> = ({
  index,
  fields,
  shipment,
  showDetails,
  editable,
  className = "",
  onShipmentClick = () => {},
  onDelete = () => {},
}) => {
  const exchanges = useCurrencyExchanges();
  const [openModal, setOpenModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState<ShipmentInterface>();

  const data = useMemo(() => {
    const buSource =
      shipment.buSource.code +
      ("location" in shipment.buSource
        ? " - " + shipment.buSource.location.name
        : "");
    const buConsignee =
      shipment.buConsignee?.code +
      (!!shipment.buConsignee && "location" in shipment.buConsignee
        ? " - " + shipment.buConsignee.location.name
        : "");
    const consigneeAddress = !!shipment.consigneeAddress
      ? ("addressName" in shipment.consigneeAddress
          ? shipment.consigneeAddress.addressName
          : shipment.consigneeAddress.name) +
        ("address" in shipment.consigneeAddress
          ? ` - ${shipment.consigneeAddress.address}`
          : "") +
        ("postalCode" in shipment.consigneeAddress &&
        !!shipment.consigneeAddress.postalCode
          ? ` (Código postal: ${shipment.consigneeAddress?.postalCode})`
          : "")
      : "";

    return {
      buSource,
      buConsignee,
      consigneeAddress,
    };
  }, [shipment]);

  const handleClick = async (
    shipment: ShipmentSimpleInterface | ShipmentInterface
  ) => {
    if (!shipment.shipmentNumber) {
      setShipmentModal(shipment as ShipmentInterface);
    } else {
      setShipmentModal(undefined);
    }
    setOpenModal(true);
  };

  return (
    <>
      <tr
        className={classNames(
          "cursor-pointer hover:bg-gray-100",
          index % 2 === 0 && "bg-gray-50",
          typeof className === "string" ? className : className(shipment)
        )}
        onClick={() => {
          onShipmentClick(shipment);
          if (showDetails) handleClick(shipment);
        }}
      >
        {fields.includes(ShipmentField.ID) && (
          <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
            {shipment.shipmentNumber ?? index + 1}
          </td>
        )}

        {fields.includes(ShipmentField.SHIPPER) && (
          <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
            {formatName(shipment.shipper.accountFullName)}
          </td>
        )}

        {fields.includes(ShipmentField.BU_SOURCE) && (
          <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate">
            {data.buSource}
          </td>
        )}

        {fields.includes(ShipmentField.CONSIGNEE) && (
          <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate text-left truncate">
            {formatName(shipment.consignee.accountFullName)}
          </td>
        )}

        {fields.includes(ShipmentField.DESTINATION) && (
          <td className="text-xs px-4 py-4 font-semibold w-auto text-gray-500 max-w-[20rem]">
            <p>{data.buConsignee}</p>
            <p className="text-xs font-normal truncate">
              {data.consigneeAddress}
            </p>
          </td>
        )}

        {fields.includes(ShipmentField.STATUS) && (
          <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate">
            {shipmentStatusFormat(shipment.status)}
          </td>
        )}

        {fields.includes(ShipmentField.AMOUNT) && (
          <td className="text-right text-xs px-4 py-4 font-semibold w-auto text-gray-500 truncate">
            {currencyExchangeText(shipment.total, exchanges, "USD")}
          </td>
        )}

        {editable && (
          <td className="text-xs px-4 py-4 flex items-end justify-end">
            <div
              onClick={(event) => {
                event.stopPropagation();
                onDelete(index);
              }}
              className="flex justify-center rounded-full w-9 bg-red-100 hover:bg-red-200 hover:cursor-pointer p-2"
            >
              <TrashIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
            </div>
          </td>
        )}

        <td className="h-0 w-0" onClick={(e) => e.stopPropagation()}>
          <ShipmentDetailsModal
            hideButtons
            shipment={shipmentModal}
            shipmentNumber={shipment.shipmentNumber ?? `${index + 1}`}
            openModal={openModal}
            setOpenModal={setOpenModal}
          />
        </td>
      </tr>
    </>
  );
};

export interface ShipmentTableProps {
  shipments: ShipmentSimpleInterface[] | ShipmentInterface[];
  fields?: ShipmentField[];
  showDetails?: boolean;
  editable?: boolean;
  className?:
    | string
    | ((shipment: ShipmentSimpleInterface | ShipmentInterface) => string);
  onShipmentClick?: (
    shipment: ShipmentSimpleInterface | ShipmentInterface
  ) => void;
  onDelete?: (index: number) => void;
}

const ShipmentTable: FC<ShipmentTableProps> = ({
  shipments,
  showDetails,
  editable,
  fields = [
    ShipmentField.ID,
    ShipmentField.SHIPPER,
    ShipmentField.BU_SOURCE,
    ShipmentField.CONSIGNEE,
    ShipmentField.DESTINATION,
    ShipmentField.STATUS,
    ShipmentField.AMOUNT,
  ],
  className = "",
  onShipmentClick = () => {},
  onDelete = () => {},
}) => {
  return (
    <div className="flex flex-1 flex-col bg-white rounded-lg border px-8 pb-6 pt-4 overflow-hidden">
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead>
            <tr>
              {fields.includes(ShipmentField.ID) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  GUÍA
                </th>
              )}

              {fields.includes(ShipmentField.SHIPPER) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  REMITENTE
                </th>
              )}

              {fields.includes(ShipmentField.BU_SOURCE) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  ORIGEN
                </th>
              )}

              {fields.includes(ShipmentField.CONSIGNEE) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  DESTINATARIO
                </th>
              )}

              {fields.includes(ShipmentField.DESTINATION) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  DESTINO
                </th>
              )}

              {fields.includes(ShipmentField.STATUS) && (
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  ESTADO
                </th>
              )}

              {fields.includes(ShipmentField.AMOUNT) && (
                <th className="text-right px-4 py-2 font-semibold text-xs">
                  MONTO
                </th>
              )}

              {editable && (
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs w-5" />
              )}
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, index: number) => (
              <ShipmentElement
                key={index}
                index={index}
                fields={fields}
                shipment={shipment}
                showDetails={showDetails}
                editable={editable}
                className={className}
                onShipmentClick={onShipmentClick}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShipmentTable;
