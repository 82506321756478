import React, { FC, useState, useEffect } from "react";
import classNames from "classnames";

interface AnimatedHeightProps {
  /**
   * Indicates if the accordion item is active
   */
  active: boolean;
  /**
   * Duration of the animation
   */
  duration?: number;
  /**
   * Class name of the accordion item
   */
  className?: string;
  /**
   * Content of the accordion item
   */
  children: React.ReactNode;
}
export const AnimatedHeight: FC<AnimatedHeightProps> = ({
  active,
  duration = 500,
  className,
  children,
}) => {
  const [height, setHeight] = useState(0);
  const childRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const current = childRef.current;

    if (!!current) {
      setHeight(current.scrollHeight);

      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === current) {
            setHeight(entry.target.scrollHeight);
          }
        }
      });
      resizeObserver.observe(current);

      return () => {
        !!current && resizeObserver.unobserve(current);
      };
    }
  }, [childRef.current?.scrollHeight, children]);

  return (
    <div
      className={classNames(
        "transition-all ease-in-out overflow-hidden",
        className
      )}
      style={{
        height: active ? height : 0,
        transitionDuration: `${duration}ms`,
      }}
    >
      <div ref={childRef}>{children}</div>
    </div>
  );
};

export default AnimatedHeight;
