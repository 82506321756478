import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Modal from "../Modal";
import MenuTab from "../MenuTab";
import classNames from "classnames";
import { FormikProps } from "formik";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { alertService, getDailyClosures, loaderService } from "../../services";
import { useAppSelector } from "../../store/hooks";
import { PaymentFormValues } from "./PaymentChange";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FormSelect, FormTextInput } from "../FormFields";
import { DailyStoreCloseDTO } from "../../interfaces/Dtos/DailyStoreCloseDTO";
import LoadingIcon from "../LodingIcon";
import {
  Banco,
  PaymentInterface,
  PaymentMethodEnum,
  PaymentMethodInterface,
} from "../../interfaces";
import {
  cleanID,
  currencyExchange,
  currencyExchangeText,
  formatGooglePhone,
  formatName,
  useCurrencyExchanges,
} from "../../utils";

interface CashChangeProps {
  currencyID: number;
  formik: FormikProps<PaymentFormValues>;
  paymentMethods: PaymentMethodInterface[];
  max: number;
}
interface SuperadminOption {
  name: string;
  id: number;
}

const CashChange: FC<CashChangeProps> = ({
  currencyID,
  formik,
  max,
  paymentMethods,
}) => {
  const [loader, setLoader] = useState(false);

  // handle amount change
  const handlePayAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<PaymentFormValues>,
    max: number
  ) => {
    let amount = e.target.valueAsNumber;

    if (e.target.valueAsNumber > max) {
      amount = max < 0 ? 0 : max;
    }
    formik.setFieldValue("amount", +amount.toFixed(2));
  };

  // Set default values
  useEffect(() => {
    if (currencyID === 1) {
      formik.setFieldValue("Currency", "BS");
      formik.setFieldValue("currency", "BS");
    } else {
      formik.setFieldValue("Currency", "USD");
      formik.setFieldValue("currency", "USD");
    }

    if (max === 0) {
      setLoader(true);
    }
    setLoader(false);
    max < 0
      ? formik.setFieldValue("amount", 0)
      : formik.setFieldValue("amount", +max.toFixed(2));
  }, [currencyID, paymentMethods, max]);

  return (
    <div className="mb-4">
      <FormTextInput
        name="amount"
        type="number"
        step="any"
        label={`Monto a pagar en ${
          currencyID === 1 ? "Bolívares" : "Dólares"
        } en efectivo`}
        onChange={(e) => handlePayAmountChange(e, formik, max)}
        value={formik.values.amount}
        error={
          formik.touched.amount && formik.errors.amount
            ? formik.errors.amount
            : ""
        }
      />
      {loader && (
        <div className="flex items-center justify-center w-full p-8">
          {loader && <LoadingIcon size="2rem" />}
        </div>
      )}
    </div>
  );
};

interface ChangeModalProps {
  remaining: number;
  formik: FormikProps<PaymentFormValues>;
  b2pBank?: Banco[];
  bankList?: Banco[];
  payments: PaymentInterface[];
  openModal: boolean;
  availableChange: number;
  isNatural: boolean;
  setOpenModal: (open: boolean) => void;
  setOpenReintegrationModal: (open: boolean) => void;
}

const ChangeModal: FC<ChangeModalProps> = ({
  remaining,
  formik,
  b2pBank,
  bankList,
  payments,
  openModal,
  availableChange,
  isNatural,
  setOpenModal,
  setOpenReintegrationModal,
}) => {
  const paymentMethods = useAppSelector(
    (state) => state.user.paymentMethods ?? []
  );
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [radio, setRadio] = useState(0);
  const [maxBs, setMaxBs] = useState(0);
  const [maxUsd, setMaxUsd] = useState(0);
  const [sumBs, setSumBs] = useState(0);
  const [superadminOption, setSuperadminOption] = useState<SuperadminOption>({
    name: "Procesar pago",
    id: 1,
  });
  const [sumUsd, setSumUsd] = useState(0);
  const [idType, setIdType] = useState("V");
  const [dailyClosures, setDailyClosures] = useState<DailyStoreCloseDTO[]>([]);

  // Identification types
  const identificationTypes = ["V", "E"];

  const tabs = [
    {
      name: "Pago Móvil",
      showCount: false,
    },
    {
      name: "Efectivo",
      showCount: false,
    },
  ];

  const superadminOptions: SuperadminOption[] = [
    {
      name: "Procesar vuelto",
      id: 1,
    },
    {
      name: "Registrar vuelto",
      id: 2,
    },
  ];

  const usdBalance = useMemo(
    () =>
      (dailyClosures.find((c) => c.currencyID === 2)?.balance ?? 0).toFixed(2),
    [dailyClosures]
  );

  const bsBalance = useMemo(
    () =>
      (dailyClosures.find((c) => c.currencyID === 1)?.balance ?? 0).toFixed(2),
    [dailyClosures]
  );

  const isSuperadmin = useMemo(() => {
    return user.user?.roleName === "Superadministrador";
  }, [user.user?.roleName]);

  // Submit form
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (tab === 0) {
      formik.setFieldValue("currency", "BS");
      if (superadminOption.id === 2) {
        formik.setFieldValue(
          "paymentMethod",
          paymentMethods.find(
            (method) => method.paymentMethodID === PaymentMethodEnum.REINTEGRO
          )!
        );
        formik.setFieldValue("destBankID", undefined);
      } else {
        formik.setFieldValue(
          "paymentMethod",
          paymentMethods.find(
            (method) =>
              method.paymentMethodID === PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO
          )!
        );
        formik.setFieldValue("destBankID", 6);
      }
    } else {
      formik.setFieldValue("destBankID", undefined);
      if (formik.values.currency === "BS") {
        formik.setFieldValue(
          "paymentMethod",
          paymentMethods.find(
            (method) => method.paymentMethodID === 20 && method.currencyID === 1
          )!
        );
      } else {
        formik.setFieldValue("Currency", "USD");
        formik.setFieldValue("currency", "USD");
        formik.setFieldValue(
          "amount",
          currencyExchange(formik.values.amount, exchanges, "BS")
        );
        formik.setFieldValue(
          "paymentMethod",
          paymentMethods.find(
            (method) => method.paymentMethodID === 20 && method.currencyID === 2
          )!
        );
      }
    }
    const cleanClientIdentifier = cleanID(formik.values.clientIdentifier);
    formik.setFieldValue(
      "clientIdentifier",
      `${idType}${cleanClientIdentifier}`
    );

    const errors = await formik.validateForm();
    formik.handleSubmit(e);
    if (
      [PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO].includes(
        formik.values.paymentMethod.paymentMethodID
      ) &&
      (Object.keys(errors).length > 0 ||
        formik.values.phone === "" ||
        formik.values.amount === 0 ||
        formik.values.bank === ({} as Banco))
    ) {
      return;
    }
    setOpenModal(false);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatGooglePhone(e.target.value);
    formik.setFieldValue("phone", value);
  };

  const handlePmAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<PaymentFormValues>,
    max: number
  ) => {
    let amount = e.target.valueAsNumber;

    if (e.target.valueAsNumber > max) {
      amount = max < 0 ? 0 : max;
    }
    formik.setFieldValue("amount", +amount.toFixed(2));
  };

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    // Get daily amount change limit
    const getClosures = async () => {
      loaderService.start();
      const response = await getDailyClosures(buCode);
      if (!response.didError && response.model) {
        setDailyClosures(
          response.model.sort((a, b) => {
            return moment(a.closeDate).isBefore(moment(b.closeDate)) ? 1 : -1;
          })
        );
      }
      loaderService.stop();
    };

    getClosures();
  }, [user.businessUnit?.code]);

  useEffect(() => {
    // Set max amount to pay
    setMaxUsd(
      Math.min(
        currencyExchange(sumUsd, exchanges, "USD"),
        currencyExchange(-remaining, exchanges, "USD"),
        +usdBalance
      )
    );
    setMaxBs(
      Math.min(
        currencyExchange(sumBs, exchanges, "BS"),
        currencyExchange(-remaining, exchanges, "BS"),
        +bsBalance
      )
    );
  }, [exchanges, sumBs, sumUsd, remaining, usdBalance, bsBalance]);

  // Sum cash payments in Bs and USD and set max amount to pay
  useEffect(() => {
    // Sum cash payments
    let amountBs = 0;
    let amountUsd = 0;
    payments.forEach((payment) => {
      if (
        payment.paymentMethod.paymentMethodID === 6 ||
        payment.paymentMethod.paymentMethodID === 20
      ) {
        if (payment.paymentMethod.currencyID === 1) {
          amountBs += payment.amount;
        } else {
          amountUsd += payment.amount;
        }
      }

      setSumBs(amountBs);
      setSumUsd(amountUsd);
    });

    // Set max amount to pay
    if (isSuperadmin) {
      setMaxUsd(currencyExchange(-remaining, exchanges, "USD"));
      setMaxBs(currencyExchange(-remaining, exchanges, "BS"));
    } else {
      setMaxUsd(
        Math.min(
          currencyExchange(sumUsd, exchanges, "USD"),
          currencyExchange(-remaining, exchanges, "USD"),
          +usdBalance
        )
      );
      setMaxBs(
        Math.min(currencyExchange(-remaining, exchanges, "BS"), +bsBalance)
      );
    }
  }, [
    payments,
    remaining,
    exchanges,
    sumBs,
    sumUsd,
    usdBalance,
    bsBalance,
    isSuperadmin,
  ]);

  useEffect(() => {
    if (tab === 0) {
      if (availableChange <= 0)
        alertService.error(
          "No hay saldo disponible para vuelto por pago móvil"
        );
      formik.setFieldValue(
        "amount",
        Math.min(
          +currencyExchange(availableChange, exchanges, "BS", "USD").toFixed(2),
          -remaining
        )
      );
    } else {
      if (formik.values.currency === "BS")
        formik.setFieldValue("amount", +maxBs.toFixed(2));
      else formik.setFieldValue("amount", +maxUsd.toFixed(2));
    }
  }, [tab, formik.values.currency, maxBs, maxUsd, availableChange]);

  // Check if user is natural
  useEffect(() => {
    if (
      !isNatural ||
      currencyExchange(availableChange, exchanges, "BS", "USD") < -remaining
    )
      setTab(1);
    else setTab(0);
  }, [isNatural, availableChange, remaining]);

  return (
    <Modal openModal={openModal} setOpenModal={() => {}} className="w-1/3">
      <div className="bg-white pl-4 pb-4">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row mb-4">
            <h2 className="text-md font-semibold items-center">Cambio:</h2>
            <div className="flex flex-row justify-start pl-1">
              <p className="text-sm font-semibold text-gray-600 truncate place-self-center">
                {currencyExchangeText(-remaining, exchanges, "BS")}
              </p>
              <p className="text-xs text-gray-400 place-self-center ml-1">
                {currencyExchangeText(-remaining, exchanges, "USD")}
              </p>
            </div>
          </div>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => setOpenModal(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        {((isNatural &&
          currencyExchange(availableChange, exchanges, "BS", "USD") >
            -remaining) ||
          isSuperadmin) && (
          <div className="mb-2">
            <MenuTab current={tab} onTabClick={setTab} tabs={tabs} />
          </div>
        )}
        {/* PM change */}
        <div className={classNames(tab !== 0 && "hidden")}>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <div className="flex flex-row mb-4 w-full">
              <div className="flex flex-col">
                <FormSelect
                  containerClassName="rounded rounded-r-none"
                  name="identificationType"
                  type="text"
                  label="Tipo"
                  options={identificationTypes}
                  optionString={(identificationTypes: string) =>
                    identificationTypes
                  }
                  selected={idType}
                  onSelectOption={setIdType}
                />
              </div>
              <div className="flex flex-col w-full">
                <FormTextInput
                  className="rounded rounded-l-none"
                  name="clientIdentifier"
                  type="text"
                  label="Cédula del cliente"
                  placeholder="12345678"
                  disabled={!isSuperadmin}
                  onChange={formik.handleChange}
                  value={cleanID(formik.values.clientIdentifier)}
                  error={
                    formik.touched.clientIdentifier &&
                    formik.errors.clientIdentifier
                      ? formik.errors.clientIdentifier
                      : ""
                  }
                />
              </div>
            </div>
            <div className="mb-4">
              <FormTextInput
                name="phone"
                type="text"
                label="Teléfono"
                placeholder="04141234567"
                onChange={handlePhoneNumberChange}
                value={formik.values.phone}
                error={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : ""
                }
              />
            </div>
            <div className="mb-4">
              <FormTextInput
                name="amount"
                type="number"
                step="any"
                disabled={!isSuperadmin}
                label="Monto a pagar en Bolívares"
                onChange={(e) => handlePmAmountChange(e, formik, maxBs)}
                value={formik.values.amount}
                error={
                  formik.touched.amount && formik.errors.amount
                    ? formik.errors.amount
                    : ""
                }
              />
            </div>
            <div className="flex flex-row mb-4">
              {isSuperadmin && (
                <div className="flex flex-col pr-2 w-1/2">
                  <FormSelect
                    label="Banco remitente"
                    name="destBankID"
                    options={
                      paymentMethods.find(
                        (method) =>
                          method.paymentMethodID ===
                          PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO
                      )?.bankAccounts ?? []
                    }
                    optionString={(bank) => {
                      return bank
                        ? `${formatName(
                            bank.bankName!
                          )} (${bank.accountNumber?.slice(-4)})`
                        : "Buscar...";
                    }}
                    selected={
                      paymentMethods
                        .find(
                          (method) =>
                            method.paymentMethodID ===
                            PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO
                        )
                        ?.bankAccounts?.find(
                          (b) => b.bankID === formik.values.destBankID
                        ) ?? null
                    }
                    onSelectOption={(bank) => {
                      formik.setFieldValue("destBankID", bank?.bankID);
                    }}
                    error={
                      formik.touched.destBankID && formik.errors.destBankID
                        ? formik.errors.destBankID
                        : ""
                    }
                  />
                </div>
              )}
              <FormSelect
                label="Banco destino"
                name="bank"
                options={
                  formik.values.destBankID === 6 ? b2pBank! : bankList! || []
                }
                optionString={(method) =>
                  `${
                    method.descripcion
                      ? formatName(method.descripcion)
                      : "Buscar..."
                  }`
                }
                selected={formik.values.bank}
                onSelectOption={(bank) => formik.setFieldValue("bank", bank)}
                error={
                  formik.touched.bank && formik.errors.bank
                    ? formik.errors.bank
                    : ""
                }
              />
            </div>
            {superadminOption.id === 2 && (
              <div className="mb-4">
                <FormTextInput
                  name="reference"
                  type="text"
                  label="Referencia"
                  onChange={(e) =>
                    formik.setFieldValue("reference", e.target.value)
                  }
                  value={formik.values.reference}
                  error={
                    formik.touched.reference && formik.errors.reference
                      ? formik.errors.reference
                      : ""
                  }
                />
              </div>
            )}
            {isSuperadmin && (
              <div className="flex flex-col mb-4">
                <div className="flex flex-col pr-2 w-full">
                  <FormSelect
                    label="Procesar vuelto / Registrar vuelto"
                    name="type"
                    options={superadminOptions ?? []}
                    optionString={(type) => `${type.name ?? "Buscar..."}`}
                    selected={superadminOption}
                    onSelectOption={(option) => setSuperadminOption(option)}
                  />
                </div>
                <p className="text-sm text-gray-500 text-left">
                  - Al procesar el vuelto se usará el servicio de vuelto del
                  banco seleccionado como remitente.
                </p>
                <p className="text-sm text-gray-500 text-left">
                  - Al registrar el vuelto se guardará directamente como pago
                  procesado.
                </p>
              </div>
            )}
            <PrimaryButton
              className="items-center justify-center w-full"
              type="submit"
            >
              Procesar cambio
            </PrimaryButton>
          </form>
        </div>
        {/* Cash change */}
        <div className={classNames(tab !== 1 && "hidden")}>
          <form onSubmit={handleSubmit} className="flex flex-col">
            {(sumUsd > 0 || isSuperadmin) && (
              <div className="my-4">
                <label className="pr-4">
                  <input
                    className="mr-1"
                    type="radio"
                    value="0"
                    checked={radio === 0}
                    onChange={() => setRadio(0)}
                  />
                  Bolívares
                </label>
                <label>
                  <input
                    className="mr-1"
                    type="radio"
                    value="1"
                    checked={radio === 1}
                    onChange={() => setRadio(1)}
                  />
                  Dólares
                </label>
              </div>
            )}
            {radio === 0 &&
              (currencyExchange(-remaining, exchanges, "BS") <= +bsBalance ||
              isSuperadmin ? (
                <CashChange
                  currencyID={1}
                  formik={formik}
                  max={maxBs}
                  paymentMethods={paymentMethods}
                />
              ) : (
                <div className="bg-gray-200 rounded p-4 mb-4">
                  <p className="text-black ">
                    No hay suficiente balance en bolívares en efectivo para
                    poder procesar el cambio completo. Considere otra opción de
                    vuelto.
                  </p>
                </div>
              ))}
            {radio === 1 &&
              (currencyExchange(-remaining, exchanges, "USD") <= +usdBalance ||
              isSuperadmin ? (
                <CashChange
                  currencyID={2}
                  formik={formik}
                  max={maxUsd}
                  paymentMethods={paymentMethods}
                />
              ) : (
                <div className="bg-gray-200 rounded p-4 mb-4">
                  <p className="text-black ">
                    No hay suficiente balance en dolares en efectivo para poder
                    procesar el cambio completo. Considere otra opción de
                    vuelto.
                  </p>
                </div>
              ))}

            <PrimaryButton
              className="items-center justify-center w-full"
              type="submit"
              disabled={
                (radio === 0 && maxBs <= 0) ||
                (radio === 1 && maxUsd <= 0) ||
                formik.values.amount <= 0 ||
                isNaN(formik.values.amount)
              }
            >
              Procesar cambio
            </PrimaryButton>
          </form>
        </div>
        {isSuperadmin && (
          <SecondaryButton
            className="items-center justify-center w-full mt-2"
            onClick={() => {
              setOpenModal(false);
              setOpenReintegrationModal(true);
            }}
          >
            Solicitar Reintegro
          </SecondaryButton>
        )}
      </div>
    </Modal>
  );
};
export default ChangeModal;
