import { useEffect, useState } from "react";
import { DhlLineItemsDto, DhlPackageDto } from "../interfaces/DHLInterface";
import {
  getRatesDHL,
  getTrackingDHL,
  createShipmentDHL,
  createPickupBookingDHL,
  cancelPickupBookingDHL,
  updatePickupBookingDHL,
} from "../services";
import { useAppSelector } from "../store/hooks";

enum Service {
  Rates = "Productos y precios",
  CreateShipment = "Creación de envío",
  Tracking = "Tracking",
  Pickup = "Crear orden de pickup",
  UpdatePickup = "Actualizar orden de pickup",
  CancelPickup = "Cancelar orden de pickup",
}

const DHLTest = () => {
  const user = useAppSelector((state) => state.user);
  const [selectedService, setSelectedService] = useState(Service.Rates);
  const [plannedShippingDate, setPlannedShippingDate] = useState(new Date());
  const [shipperCountryCode, setShipperCountryCode] = useState("");
  const [shipperPostalCode, setShipperPostalCode] = useState("");
  const [shipperCityName, setShipperCityName] = useState("");
  const [shipperAddressLine1, setShipperAddressLine1] = useState("");
  const [shipperAddressLine2, setShipperAddressLine2] = useState("");
  const [shipperAddressLine3, setShipperAddressLine3] = useState("");
  const [shipperEmail, setShipperEmail] = useState("");
  const [shipperPhone, setShipperPhone] = useState("");
  const [shipperFullName, setShipperFullName] = useState("");
  const [shipperCompanyName, setShipperCompanyName] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState("");
  const [receiverPostalCode, setReceiverPostalCode] = useState("");
  const [receiverCityName, setReceiverCityName] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receiverAddressLine3, setReceiverAddressLine3] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [receiverFullName, setReceiverFullName] = useState("");
  const [receiverCompanyName, setReceiverCompanyName] = useState("");
  const [receiverIdentificationNumber, setReceiverIdentificationNumber] =
    useState("");
  const [weight, setWeight] = useState(1);
  const [length, setLength] = useState(1);
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  const [packagesQuantity, setPackagesQuantity] = useState(1);
  const [itemsDescription, setItemsDescription] = useState("");
  const [itemsPrice, setItemsPrice] = useState(1);
  const [itemsManufacturerCountry, setItemsManufacturerCountry] = useState("");
  const [itemsQuantity, setItemsQuantity] = useState(1);
  const [itemsWeightNetValue, setItemsWeightNetValue] = useState(1);
  const [packages, setPackages] = useState<DhlPackageDto[]>([]);
  const [declaredValue, setDeclaredValue] = useState(0);
  const [isCustomsDeclarable, setIsCustomsDeclarable] = useState(false);
  const [items, setItems] = useState<DhlLineItemsDto[]>([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [dispatchConfirmationNumbers, setDispatchConfirmationNumbers] =
    useState("");
  const [pdfData, setPdfData] = useState("");
  const [response, setResponse] = useState<any>();

  const inputClassName =
    "focus block w-full rounded-xl border-0 text-gray-400 " +
    "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
    "placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
    "focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
    "invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer " +
    "h-10";

  const rate = async () => {
    const res = await getRatesDHL(
      plannedShippingDate,
      shipperPostalCode,
      shipperCityName,
      shipperCountryCode,
      shipperAddressLine1,
      shipperAddressLine2,
      shipperAddressLine3,
      receiverPostalCode,
      receiverCityName,
      receiverCountryCode,
      receiverAddressLine1,
      receiverAddressLine2,
      receiverAddressLine3,
      packages,
      declaredValue,
      isCustomsDeclarable
    );
    if (res !== null) {
      console.log(res);
      setResponse(res);
    }
  };

  const tracking = async () => {
    const res = await getTrackingDHL(trackingNumber);
    if (res !== null) {
      console.log(res);
      setResponse(res);
    }
  };

  const createShipment = async () => {
    const res = await createShipmentDHL(
      plannedShippingDate,
      shipperPostalCode,
      shipperCityName,
      shipperCountryCode,
      shipperAddressLine1,
      shipperAddressLine2,
      shipperAddressLine3,
      shipperPhone,
      shipperFullName,
      shipperCompanyName,
      receiverPostalCode,
      receiverCityName,
      receiverCountryCode,
      receiverAddressLine1,
      receiverAddressLine2,
      receiverAddressLine3,
      receiverEmail,
      receiverPhone,
      receiverFullName,
      receiverCompanyName,
      itemsDescription,
      packages,
      isCustomsDeclarable,
      items,
      invoiceNumber
    );
    if (res !== null) {
      console.log(res);
      setResponse(res);
      setPdfData(res.documents[0].content);
    }
  };

  const pickupBooking = async () => {
    const res = await createPickupBookingDHL(
      plannedShippingDate,
      packages,
      isCustomsDeclarable
    );
    console.log(res);
    if (res !== null) {
      setResponse(res);
    }
  };

  const updatePickup = async () => {
    const res = await updatePickupBookingDHL(
      dispatchConfirmationNumbers,
      plannedShippingDate,
      receiverCityName,
      receiverCountryCode,
      receiverPostalCode,
      receiverAddressLine1,
      receiverEmail,
      receiverPhone,
      receiverFullName
    );
    console.log(res);
    if (res !== null) {
      setResponse(res);
    }
  };

  // const cancelPickup = async () => {
  //   const res = await cancelPickupBookingDHL(
  //     dispatchConfirmationNumbers,
  //     cancelReason
  //   );
  //   console.log(res);
  //   if (res !== null) {
  //     setResponse(res);
  //   }
  // };

  const handleSubmit = async () => {
    switch (selectedService) {
      case Service.Rates:
        await rate();
        break;
      case Service.Tracking:
        await tracking();
        break;
      case Service.CreateShipment:
        await createShipment();
        break;
      case Service.Pickup:
        await pickupBooking();
        break;
      case Service.UpdatePickup:
        await updatePickup();
        break;
      case Service.CancelPickup:
        break;
    }
  };

  // Function to add a package to the list
  const addPackage = (pack: DhlPackageDto) => {
    console.log(pack);
    setPackages([...packages, pack]);
  };

  // Function to add an item to the list
  const addItem = (item: DhlLineItemsDto) => {
    console.log(item);
    setItems([...items, item]);
  };
  // Function to open the pdf in a new window
  const openPdfInNewWindow = () => {
    const binaryString = window.atob(pdfData);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const blob = new Blob([bytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  useEffect(() => {
    if (selectedService !== Service.CreateShipment) {
      setPdfData("");
    }
  }, [selectedService]);

  return (
    <div className="flex bg-white shadow-md rounded-md px-8 py-8 w-4/5 min-h-screen">
      <div className="w-1/2 pr-4">
        {/* Service selector */}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="service"
          >
            Servicio
          </label>
          <select
            className={inputClassName}
            id="service"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value as Service)}
          >
            {Object.values(Service).map((service) => (
              <option key={service} value={service}>
                {service}
              </option>
            ))}
          </select>
        </div>
        {/* Dispatch confirmation numbers */}
        {[Service.UpdatePickup, Service.CancelPickup].includes(
          selectedService
        ) && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="dispatchConfirmationNumbers"
            >
              Número de confirmación de despacho
            </label>
            <input
              className={inputClassName}
              id="dispatchConfirmationNumbers"
              type="text"
              placeholder="CBJ123456789012"
              value={dispatchConfirmationNumbers}
              onChange={(e) => setDispatchConfirmationNumbers(e.target.value)}
            />
          </div>
        )}
        {[
          Service.Rates,
          Service.CreateShipment,
          Service.Pickup,
          Service.UpdatePickup,
        ].includes(selectedService) && (
          <>
            {/* Planned shipping date  */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="plannedShippingDate"
              >
                Fecha de{" "}
                {selectedService === Service.Pickup ? "pickup" : "envío"}
              </label>
              <input
                className={inputClassName}
                id="plannedShippingDate"
                type="date"
                placeholder="plannedShippingDate"
                value={plannedShippingDate.toISOString().split("T")[0]}
                onChange={(e) =>
                  setPlannedShippingDate(new Date(e.target.value))
                }
              />
            </div>
            {[Service.Rates, Service.CreateShipment].includes(
              selectedService
            ) && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/* Shipper */}
                <div>
                  {/* Shipper Country code */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperCountryCode"
                    >
                      Código ISO del país de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperCountryCode"
                      type="text"
                      placeholder="VE"
                      value={shipperCountryCode}
                      onChange={(e) => setShipperCountryCode(e.target.value)}
                    />
                  </div>
                  {/* Shipper Postal code */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperPostalCode"
                    >
                      Código postal de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperPostalCode"
                      type="text"
                      placeholder="12345"
                      value={shipperPostalCode}
                      onChange={(e) => setShipperPostalCode(e.target.value)}
                    />
                  </div>
                  {/* Shipper City name */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperCityName"
                    >
                      Nombre de la ciudad de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperCityName"
                      type="text"
                      placeholder="Caracas"
                      value={shipperCityName}
                      onChange={(e) => setShipperCityName(e.target.value)}
                    />
                  </div>
                  {/* Address Line 1 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperAddressLine1"
                    >
                      Dirección de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperAddressLine1"
                      type="text"
                      placeholder="Calle 123"
                      value={shipperAddressLine1}
                      onChange={(e) => setShipperAddressLine1(e.target.value)}
                    />
                  </div>
                  {/* Address Line 2 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperAddressLine2"
                    >
                      Dirección de origen 2
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperAddressLine2"
                      type="text"
                      placeholder="Colonia"
                      value={shipperAddressLine2}
                      onChange={(e) => setShipperAddressLine2(e.target.value)}
                    />
                  </div>
                  {/* Address Line 3 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperAddressLine3"
                    >
                      Dirección de origen 3
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperAddressLine3"
                      type="text"
                      placeholder="Referencia"
                      value={shipperAddressLine3}
                      onChange={(e) => setShipperAddressLine3(e.target.value)}
                    />
                  </div>
                </div>
                {/* Receiver */}
                <div>
                  {/* Receiver Country code */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverCountryCode"
                    >
                      Código ISO del país de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverCountryCode"
                      type="text"
                      placeholder="MX"
                      value={receiverCountryCode}
                      onChange={(e) => setReceiverCountryCode(e.target.value)}
                    />
                  </div>
                  {/* Receiver Postal code */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverPostalCode"
                    >
                      Código postal de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverPostalCode"
                      type="text"
                      placeholder="12345"
                      value={receiverPostalCode}
                      onChange={(e) => setReceiverPostalCode(e.target.value)}
                    />
                  </div>
                  {/* Receiver City name */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverCityName"
                    >
                      Nombre de la ciudad de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverCityName"
                      type="text"
                      placeholder="Ciudad de México"
                      value={receiverCityName}
                      onChange={(e) => setReceiverCityName(e.target.value)}
                    />
                  </div>
                  {/* Address Line 1 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverAddressLine1"
                    >
                      Dirección de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverAddressLine1"
                      type="text"
                      placeholder="Calle 123"
                      value={receiverAddressLine1}
                      onChange={(e) => setReceiverAddressLine1(e.target.value)}
                    />
                  </div>
                  {/* Address Line 2 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverAddressLine2"
                    >
                      Dirección de destino 2
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverAddressLine2"
                      type="text"
                      placeholder="Colonia"
                      value={receiverAddressLine2}
                      onChange={(e) => setReceiverAddressLine2(e.target.value)}
                    />
                  </div>
                  {/* Address Line 3 */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverAddressLine3"
                    >
                      Dirección de destino 3
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverAddressLine3"
                      type="text"
                      placeholder="Referencia"
                      value={receiverAddressLine3}
                      onChange={(e) => setReceiverAddressLine3(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
            {[Service.CreateShipment].includes(selectedService) && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {/* Email  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperEmail"
                    >
                      Email de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperEmail"
                      type="email"
                      placeholder="usuario@ejemplo.com"
                      value={shipperEmail}
                      onChange={(e) => setShipperEmail(e.target.value)}
                    />
                  </div>
                  {/* Phone  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperPhone"
                    >
                      Teléfono de origen
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperPhone"
                      type="tel"
                      placeholder="+58 412 1234567"
                      value={shipperPhone}
                      onChange={(e) => setShipperPhone(e.target.value)}
                    />
                  </div>
                  {/* Full name  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperFullName"
                    >
                      Nombre completo remitente
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperFullName"
                      type="text"
                      placeholder="John Doe"
                      value={shipperFullName}
                      onChange={(e) => setShipperFullName(e.target.value)}
                    />
                  </div>
                  {/* Company name  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="shipperCompanyName"
                    >
                      Nombre de la empresa remitente
                    </label>
                    <input
                      className={inputClassName}
                      id="shipperCompanyName"
                      type="text"
                      placeholder="Empresa SA de CV"
                      value={shipperCompanyName}
                      onChange={(e) => setShipperCompanyName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  {/* Email  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverEmail"
                    >
                      Email de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverEmail"
                      type="email"
                      placeholder="usuario@ejemplo.com"
                      value={receiverEmail}
                      onChange={(e) => setReceiverEmail(e.target.value)}
                    />
                  </div>
                  {/* Phone  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverPhone"
                    >
                      Teléfono de destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverPhone"
                      type="tel"
                      placeholder="+52 123 456 7890"
                      value={receiverPhone}
                      onChange={(e) => setReceiverPhone(e.target.value)}
                    />
                  </div>
                  {/* Full name  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverFullName"
                    >
                      Nombre completo destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverFullName"
                      type="text"
                      placeholder="John Doe"
                      value={receiverFullName}
                      onChange={(e) => setReceiverFullName(e.target.value)}
                    />
                  </div>
                  {/* Company name  */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverCompanyName"
                    >
                      Nombre de la empresa destino
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverCompanyName"
                      type="text"
                      placeholder="Empresa SA de CV"
                      value={receiverCompanyName}
                      onChange={(e) => setReceiverCompanyName(e.target.value)}
                    />
                  </div>
                  {/* Identification number */}
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="receiverCompanyName"
                    >
                      Identificación
                    </label>
                    <input
                      className={inputClassName}
                      id="receiverCompanyName"
                      type="text"
                      placeholder="123456789"
                      value={receiverIdentificationNumber}
                      onChange={(e) =>
                        setReceiverIdentificationNumber(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {[Service.CreateShipment, Service.Rates].includes(
              selectedService
            ) && (
              <>
                <div>
                  <input
                    type="radio"
                    id="documents"
                    name="declarationType"
                    value="Documents"
                    checked={!isCustomsDeclarable}
                    onChange={(e) =>
                      setIsCustomsDeclarable(e.target.value === "Packages")
                    }
                  />
                  <label htmlFor="documents">Documentos</label>

                  <input
                    className="ml-2"
                    type="radio"
                    id="packages"
                    name="declarationType"
                    value="Packages"
                    checked={isCustomsDeclarable}
                    onChange={(e) =>
                      setIsCustomsDeclarable(e.target.value === "Packages")
                    }
                  />
                  <label htmlFor="packages">Productos</label>
                </div>
                {selectedService === Service.Rates && isCustomsDeclarable && (
                  <div>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Valor declarado
                    </label>
                    <input
                      className={inputClassName}
                      id="declaredValue"
                      type="number"
                      placeholder="20"
                      value={declaredValue}
                      onChange={(e) => setDeclaredValue(e.target.valueAsNumber)}
                    />
                  </div>
                )}
                {isCustomsDeclarable &&
                  [Service.CreateShipment].includes(selectedService) && (
                    <>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="packages"
                        >
                          Descripción
                        </label>
                        <input
                          className={inputClassName}
                          id="packages"
                          type="text"
                          placeholder="Libro"
                          value={itemsDescription}
                          onChange={(e) => setItemsDescription(e.target.value)}
                        />
                      </div>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="packages"
                      >
                        Items
                      </label>
                      <div className="flex flex-col">
                        <div className="flex flex-row">
                          <div className="w-2/4">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="packages"
                            >
                              Precio
                            </label>
                            <input
                              className={inputClassName}
                              id="packages"
                              type="number"
                              placeholder="20"
                              value={itemsPrice}
                              onChange={(e) =>
                                setItemsPrice(e.target.valueAsNumber)
                              }
                            />
                          </div>
                          <div className="w-2/4">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="packages"
                            >
                              País de origen
                            </label>
                            <input
                              className={inputClassName}
                              id="packages"
                              type="text"
                              placeholder="VE"
                              value={itemsManufacturerCountry}
                              onChange={(e) =>
                                setItemsManufacturerCountry(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="w-2/4">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="packages"
                            >
                              Cantidad de paquetes
                            </label>
                            <input
                              className={inputClassName}
                              id="packages"
                              type="number"
                              placeholder="quantity"
                              value={itemsQuantity}
                              onChange={(e) =>
                                setItemsQuantity(e.target.valueAsNumber)
                              }
                            />
                          </div>
                          <div className="w-2/4">
                            <label
                              className="block text-gray-700 text-sm font-bold mb-2"
                              htmlFor="packages"
                            >
                              Peso neto
                            </label>
                            <input
                              className={inputClassName}
                              id="packages"
                              type="number"
                              placeholder="weight"
                              value={itemsWeightNetValue}
                              onChange={(e) =>
                                setItemsWeightNetValue(e.target.valueAsNumber)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            type="button"
                            onClick={() =>
                              addItem({
                                number: items.length + 1,
                                description: itemsDescription,
                                price: itemsPrice,
                                manufacturerCountry: itemsManufacturerCountry,
                                quantity: {
                                  value: itemsQuantity,
                                  unitOfMeasurement: "PCS",
                                },
                                weight: {
                                  netValue: itemsWeightNetValue,
                                  grossValue: itemsWeightNetValue,
                                },
                              })
                            }
                          >
                            Agregar
                          </button>
                        </div>
                      </div>
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="invoiceNumber"
                        >
                          Número de factura
                        </label>
                        <input
                          className={inputClassName}
                          id="invoiceNumber"
                          type="text"
                          placeholder="1234567890"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="packages"
              >
                Paquetes
              </label>
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <div className="w-1/4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Largo
                    </label>
                    <input
                      className={inputClassName}
                      id="packages"
                      type="number"
                      placeholder="length"
                      value={length}
                      onChange={(e) => setLength(e.target.valueAsNumber)}
                    />
                  </div>
                  <div className="w-1/4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Ancho
                    </label>
                    <input
                      className={inputClassName}
                      id="packages"
                      type="number"
                      placeholder="width"
                      value={width}
                      onChange={(e) => setWidth(e.target.valueAsNumber)}
                    />
                  </div>
                  <div className="w-1/4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Alto
                    </label>
                    <input
                      className={inputClassName}
                      id="packages"
                      type="number"
                      placeholder="height"
                      value={height}
                      onChange={(e) => setHeight(e.target.valueAsNumber)}
                    />
                  </div>
                  <div className="w-1/4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Peso
                    </label>
                    <input
                      className={inputClassName}
                      id="packages"
                      type="number"
                      placeholder="weight"
                      value={weight}
                      onChange={(e) => setWeight(e.target.valueAsNumber)}
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/4">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="packages"
                    >
                      Cantidad
                    </label>
                    <input
                      className={inputClassName}
                      id="packages"
                      type="number"
                      placeholder="quantity"
                      value={packagesQuantity}
                      onChange={(e) =>
                        setPackagesQuantity(e.target.valueAsNumber)
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() =>
                      addPackage({
                        weight,
                        dimensions: { length, width, height },
                      })
                    }
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {selectedService === Service.Tracking && (
          <>
            {/* Tracking number  */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="trackingNumber"
              >
                Número de tracking
              </label>
              <input
                className={inputClassName}
                id="trackingNumber"
                type="text"
                placeholder="1234567890"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
            </div>
          </>
        )}
        {selectedService === Service.CancelPickup && (
          <>
            {/* Cancel reason  */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="cancelReason"
              >
                Motivo de cancelación
              </label>
              <input
                className={inputClassName}
                id="cancelReason"
                type="text"
                placeholder="Motivo de cancelación"
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
              />
            </div>
          </>
        )}

        {/* Submit Button */}
        <div className="flex items-center justify-around">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={() => handleSubmit()}
          >
            Aceptar
          </button>
          {pdfData && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="button"
              onClick={openPdfInNewWindow}
            >
              Abrir PDF
            </button>
          )}
        </div>
      </div>

      {/* Response text area*/}
      <div className="w-1/2 mb-4 h-full">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="response"
        >
          Respuesta
        </label>
        <textarea
          className={`${inputClassName} h-full resize-none`}
          id="response"
          value={JSON.stringify(response, null, 2)}
          readOnly
        />
      </div>
    </div>
  );
};

export default DHLTest;
