import api from "./api";
import axios from "axios";
import {
  Nullable,
  DocumentStatus,
  PaymentInterface,
  DocumentInterface,
  ResponseInterface,
  BusinessUnitInterface,
  ShipmentSimpleInterface,
  DocumentType,
  PaymentMethodEnum,
  TaxInterface,
  AccountInterface,
  EntityIdEnum,
  AttachmentTypeIdEnum,
  StoreDocumentInterface,
} from "../interfaces";
import { UserInterface } from "../interfaces/UserInterface";
import {
  DocumentDto,
  PaymentExtendedDto,
  ShipmentSimpleResponseDto,
} from "../interfaces/Dtos";
import { alertService } from "./alertService";
import { store } from "../store/store";
import { updateFileDO, uploadFileDO } from "./digitaloceanServices";
import { DailyOperationStatus } from "../interfaces/Dtos/DailyStoreOperationDTO";
import { DocumentReplacementDTO } from "../interfaces/Dtos/DocumentReplacementDTO";

const baseURLAPI = process.env.REACT_APP_API_HOST;

// Method to get Document by its identifiers
export const getDocument = async (
  documentId?: string,
  documentNumber?: string
): Promise<Nullable<DocumentInterface>> => {
  let uri = `${baseURLAPI}/Document`;
  if (documentId) {
    uri = `${uri}?DocumentID=${documentId}`;
  } else if (documentNumber) {
    uri = `${uri}?DocumentNumber=${documentNumber}`;
  } else {
    return null;
  }
  try {
    const response = await api.get(uri);

    if (response.status === 200 && response.data.model != null) {
      const dto: DocumentDto = response.data.model;
      if (dto == null) return null;
      const document: DocumentInterface =
        mapDocumentDtoToDocumentInterface(dto);
      return document;
    }
  } catch (error) {
    const message = (error as any)?.response?.data?.errorMessage;
    alertService.error("No se pudo obtener el documento", message);
  }

  return null;
};

// Map object from DB to more legible interfaces
export const mapDocumentDtoToDocumentInterface = (
  dto: DocumentDto
): DocumentInterface => {
  const currencyList = store.getState().inmutable.currencyList;
  const invoiceTaxes = [
    {
      name: "IVA",
      value: dto.taxAmountBaseCurr,
      type: "fixed",
    },
    {
      name: "Ipostel",
      value: dto.postalTaxAmount ?? 0,
      type: "fixed",
    },
  ] as TaxInterface[];

  if (dto.igtfTaxAmount > 0) {
    invoiceTaxes.push({
      name: "IGTF",
      value: dto.igtfTaxAmount,
      type: "fixed",
    });
  }

  let creationDate: Date;
  try {
    creationDate = new Date(dto.creationDate);
  } catch (error) {
    creationDate = new Date();
  }

  const document: DocumentInterface = {
    documentID: dto.documentID,
    documentNumber: dto.documentNumber,
    documentType: dto.documentTypeID,
    documentTypeName: dto.documentTypeName,
    documentTypeCode: dto.documentTypeCode,
    documentAffectedID: dto.documentAffectedID,
    documentAffectedDocumentNumber: dto.documentAffectedDocumentNumber,
    documentAppliedID: dto.documentAppliedID,
    documentAppliedDocumentNumber: dto.documentAppliedDocumentNumber,
    urlDocument: dto.urlDocument,
    accountOwner: dto.account as AccountInterface,
    paymentMode:
      !!dto.shipments && dto.shipments.length > 0
        ? dto.shipments[0].paymentModeID
        : 0,
    subTotal: dto.grossAmountBaseCurr,
    total: dto.totalAmountBaseCurr,
    status: dto.statusID,
    hasRetention: dto.hasRetention,
    balanceAmount: dto.balanceAmountBaseCurr,
    baseBalanceAmount: +dto.balanceAmountBaseCurr.toFixed(2),
    igtfAmount: dto.igtfTaxAmount,
    buCodeInvoicer: dto.buCodeInvoicer,
    fiscalControlNumber: dto.fiscalControlNumber ?? undefined,
    shipments: dto.shipments.map(
      (shipment: ShipmentSimpleResponseDto) =>
        ({
          id: shipment.shipmentHeaderID,
          shipmentNumber: shipment.shipmentNumber,
          paymentMode: shipment.paymentModeID,
          shipper: { accountFullName: shipment.shipperName },
          consignee: { accountFullName: shipment.consigneeName },
          buSource: { code: shipment.buCodeSource },
          buConsignee: { code: shipment.buCodeConsignee },
          consigneeAddress: { addressName: shipment.consigneeAddress },
          shipperAddress: { addressName: shipment.shipperAddress },
          status: shipment.statusID,
          total: +shipment.totalAmountBaseCurr.toFixed(2),
        } as ShipmentSimpleInterface)
    ),
    payments: dto.payments.map(
      (payment: PaymentExtendedDto) =>
        ({
          paymentID: payment.paymentID,
          paymentDetailID: payment.paymentDetailID,
          proofOfPayment: payment.attachmentID,
          amount: payment.paymentAmountBaseCurr,
          sourceBankID: payment.sourceBankID,
          destBankID: payment.bankAccountID,
          paymentMethod: {
            paymentMethodID: payment.paymentMethodID,
            paymentMethodName: payment.paymentMethodName,
            currencyID: payment.currencyID,
            bankID: payment.bankAccountID,
            taxes: [],
          },
          igtfAmount: 0,
          igtfPercentage: 0,
          reference: payment.referenceNum,
          currency: {
            id: payment.currencyID,
            name:
              currencyList.find((pay) => pay.currencyID === payment.currencyID)
                ?.currencyName ?? "",
            code:
              currencyList.find((pay) => pay.currencyID === payment.currencyID)
                ?.currencyIsoCode ?? "",
          },
          status: payment.statusID,
          creationDate: payment.creationDate,
          paymentDate: payment.paymentDetailDate,
          isRetention: [
            PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL,
            PaymentMethodEnum.RETENCION_DE_ISRL,
            PaymentMethodEnum.RETENCION_DE_IVA,
          ].includes(payment.paymentMethodID), // check if inside a list of retentions
        } as PaymentInterface)
    ),
    taxes: invoiceTaxes,
    printed: false,
    creationDate: creationDate,
    //updateDate: dto.CreationDate,
  };
  return document;
};

// Method to obtain the Documents by the dynamic string search and filtering by status and type
export const documentDynamicSearch = async (
  search?: string,
  buCodeSource?: string,
  buCodeConsignee?: string,
  documentTypes?: DocumentType[],
  statuses?: DocumentStatus[]
): Promise<Nullable<DocumentInterface[]>> => {
  const documentTypeIDList = documentTypes?.join(",");
  //Force filter for 4 statuses: Pending, Partial Paid, Paid, Anulled
  if (!statuses) statuses = [11, 20, 10, 6];
  const StatusIDList = statuses?.join(",");

  let uri = `${baseURLAPI}/Document/DynamicSearch?`;
  if (search) {
    uri += `Search=${search}&`;
  }
  if (buCodeSource) {
    uri += `BuCodeSource=${buCodeSource}&`;
  }
  if (buCodeConsignee) {
    uri += `BuCodeConsignee=${buCodeConsignee}&`;
  }
  if (documentTypeIDList) {
    uri += `DocumentTypeIDList=${documentTypeIDList}&`;
  }
  if (StatusIDList) {
    uri += `StatusIDList=${StatusIDList}&`;
  }
  try {
    const response = await api.get(uri);

    if (response.status === 200 && response.data.model != null) {
      const dtoList: DocumentDto[] = response.data.model;
      if (!dtoList) return null;
      return dtoList.map((dto) => mapDocumentDtoToDocumentInterface(dto));
    }
  } catch (error) {
    alertService.error("No se pudo obtener el documento");
  }

  return null;
};

// Get All documents Order  and Factura by BUConsignee
export const getDocumentsByBUConsignee = async (
  businessUnit?: BusinessUnitInterface
): Promise<Nullable<DocumentInterface[]>> => {
  return await documentDynamicSearch(undefined, undefined, businessUnit?.code, [
    DocumentType.ORDER,
    DocumentType.INVOICE,
  ]);
};

// Create Document
export const saveDocument = async (
  document: DocumentInterface,
  buCode?: string,
  user?: UserInterface,
  accountBillTo?: AccountInterface,
  observations?: string,
  applicationID?: number
): Promise<ResponseInterface<{ id_solicitud: string }>> => {
  const shipment = document.shipments[0];
  if (!shipment)
    return {
      message: "",
      model: null,
      didError: true,
      errorMessage: "No se encontró el envío",
    };

  const owner = accountBillTo ?? shipment.accountBillTo;

  const body = JSON.stringify({
    Document: {
      Items: [],
      PctOtherTax: "0",
      DocumentTypeID: "6",
      PctMunicipalTax: "0",
      Observations: observations,
      CreationUser: user?.userLogin,
      BUCode: buCode,
      PaymentModeID: shipment.paymentMode,
      AccountBillToID: document.accountOwner?.id,
      AccountSiteID: shipment.accountSiteID ?? null,
      AccountPhone: owner?.fiscalPhoneNumber,
      AccountName: owner?.accountFullName,
      AccountEmail: owner?.listAccountEmail[0]?.email,
      AccountLegalAddress: owner?.fiscalAddress,
      HasRetention: document.hasRetention,
      ShipmentNumbers: document.shipments.map((s) => s.shipmentNumber),
      StatusID: document.status,
      ApplicationID: applicationID,
    },
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Document`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error al guardar el documento.",
  };
};

export const saveProofOfPayment = async (
  file: File,
  retention: PaymentInterface,
  user: UserInterface,
  statusID: number,
  reference?: string,
  isModification?: boolean,
  documentID?: string
): Promise<ResponseInterface<string>> => {
  let response: ResponseInterface<string> = {
    message: "",
    model: null,
    didError: true,
    errorMessage: "",
  };
  if (!retention.paymentDetailID) {
    response.errorMessage = "No se encontró el PaymentDetailID";
    return response;
  }

  try {
    if (!isModification) {
      const resUpload = await uploadFileDO(
        file,
        EntityIdEnum.PAYMENT,
        AttachmentTypeIdEnum.PAYMENT_PROOF,
        user.userLogin
      );
      if (!resUpload.didError) {
        const resUpdate = await updatePayment({
          documentID: documentID ?? retention.documentID!,
          paymentDetailID: retention.paymentDetailID,
          attachmentID: resUpload.model?.attachmentID,
          statusID: statusID,
          referenceNum: reference ?? undefined,
          user: user,
        });
        if (!resUpdate.didError) {
          response.model = resUpload.model?.attachmentID!;
          response.didError = false;
          return response;
        }
        response.errorMessage = resUpdate.errorMessage;
        return response;
      }

      response.didError = true;
      response.errorMessage =
        "Hubo un error al guardar el documento adjunto en DigitalOcean";
      return response;
    } else {
      const resUpdateDO = await updateFileDO(file, retention.proofOfPayment!);

      if (!resUpdateDO.didError) {
        const resUpdate = await updatePayment({
          documentID: documentID ?? retention.documentID!,
          paymentDetailID: retention.paymentDetailID,
          referenceNum: reference ?? undefined,
          statusID: statusID,
          user: user,
        });
        if (!resUpdate.didError) {
          response.model = retention.proofOfPayment!;
          response.didError = false;
          return response;
        }

        response.didError = true;
        response.errorMessage = resUpdate.errorMessage;
        return response;
      }
      response.errorMessage =
        "Hubo un error al actualizar el documento adjunto en DigitalOcean";
      return response;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

// Method to save payment
export const savePayment = async (
  payment: PaymentInterface,
  document: DocumentInterface,
  buCode?: string,
  user?: UserInterface,
  currencyID: number = 2
): Promise<ResponseInterface<{ paymentID: string }>> => {
  const bankID = getBankIdByCode(payment.bank ?? "0000");
  // Get date with UTC-4
  const date = new Date();
  date.setHours(date.getHours() - 4);

  const body = JSON.stringify({
    DocumentId: document.documentID,
    PaymentAmount: +(payment.paymentAmount?.toFixed(2) ?? 0),
    PaymentDate: date.toISOString(),
    BUCodePayment: buCode,
    StatusID: payment.status,
    CreationUser: user?.userLogin,
    PaymentDetails: [
      {
        CurrencyID: currencyID,
        BankAccountID: payment.paymentMethod.bankAccountID,
        SourceBankID: [
          PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
          PaymentMethodEnum.REINTEGRO,
        ].includes(payment.paymentMethod.paymentMethodID)
          ? payment.destBankID
          : bankID,
        PaymentMethodID: payment.paymentMethod.paymentMethodID,
        Amount: +(payment.paymentAmount?.toFixed(2) ?? 0),
        PaymentDate: date.toISOString(),
        ReferenceNum: payment.reference,
        PctIGTFTax: payment.igtfAmount,
        IGTFTaxPercentage: payment.igtfPercentage,
      },
    ],
    PaymentElectronicDetails: {
      IdentificationNumber: payment.clientIdentifier ?? "",
      BankID: [
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
        PaymentMethodEnum.REINTEGRO,
      ].includes(payment.paymentMethod.paymentMethodID)
        ? bankID
        : payment.destBankID ?? 0,
      PhoneNumber: payment.phone ?? "",
    },
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v1/Payment/Insert`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return (error as any)?.response?.data;
  }

  return {
    message: "",
    model: null,
    didError: true,
    errorMessage: "Hubo un error al guardar el pago.",
  };
};

interface UpdatePaymentOptions {
  documentID: string;
  paymentDetailID: string;
  bankAccountID?: number;
  sourceBankID?: number;
  attachmentID?: string;
  referenceNum?: string;
  statusID?: number;
  user: UserInterface;
}

export const updatePayment = async ({
  documentID,
  paymentDetailID,
  bankAccountID,
  sourceBankID,
  attachmentID,
  referenceNum,
  statusID,
  user,
}: UpdatePaymentOptions): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify({
    DocumentID: documentID,
    PaymentDetailID: paymentDetailID,
    BankAccountID: bankAccountID,
    SourceBankID: sourceBankID,
    AttachmentID: attachmentID,
    ReferenceNum: referenceNum,
    StatusID: statusID,
    UpdateUser: user?.userLogin,
    UpdateDate: new Date().toISOString(),
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/v1/Payment/Update`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const updateDocumentStatus = async (
  documentId: string,
  status: DocumentStatus,
  documentType?: DocumentType,
  user?: UserInterface,
  buCodeInvoicer?: string,
  previousDocumentTypeID?: DocumentType
): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify({
    DocumentID: documentId,
    StatusID: status,
    DocumentTypeID: documentType,
    UpdateUser: user?.userLogin,
    UpdateDate: new Date().toISOString(),
    BUCodeInvoicer: buCodeInvoicer,
    PreviousDocumentTypeID: previousDocumentTypeID,
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Document/Status`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const updateDocumentAccountBillTo = async (
  documentId: string,
  accountBillTo: AccountInterface,
  user?: UserInterface
): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify({
    DocumentID: documentId,
    AccountBillToID: accountBillTo.id,
    AccountPhone: accountBillTo.listAccountPhone[0]?.phoneNumber,
    AccountName: accountBillTo.accountFullName,
    AccountEmail: accountBillTo.listAccountEmail[0]?.email,
    AccountFiscalNumber: accountBillTo.identificationNumber,
    AccountLegalAddress: accountBillTo.fiscalAddress,
    UpdateUser: user?.userLogin,
    UpdateDate: new Date().toISOString(),
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Document/AccountBillTo`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const createReplacement = async (
  documentAffectedID: string,
  documentAffectedTypeCode: string,
  documentNumber: string,
  fiscalControlNumber: string,
  documentTypeID: DocumentType,
  statusID: DocumentStatus,
  buCode: string,

  accountBillToID: string,
  accountName: string,
  accountPhone: string,
  accountEmail: string,
  accountFiscalNumber: string,
  accountLegalAddress: string,

  targetAccountBillToID: string,
  targetAccountName: string,
  targetAccountEmail: string,
  targetAccountPhone: string,
  targetAccountFiscalNumber: string,
  targetAccountLegalAddress: string,

  user: UserInterface
): Promise<ResponseInterface<DocumentReplacementDTO>> => {
  const body = JSON.stringify({
    SubstitutionReason: "Susticion de datos fiscales",
    DocumentAffectedID: documentAffectedID,
    DocumentAffectedTypeCode: documentAffectedTypeCode,
    DocumentNumber: documentNumber,
    FiscalControlNumber: fiscalControlNumber,
    DocumentTypeID: documentTypeID,
    StatusID: statusID,
    CreationUser: user.userLogin,
    TargetDocumentTypeID: 4,
    AccountName: accountName,
    AccountPhone: accountPhone,
    AccountEmail: accountEmail,
    AccountBillToID: accountBillToID,
    AccountFiscalNumber: accountFiscalNumber,
    AccountLegalAddress: accountLegalAddress,

    TargetAccountBillToID: targetAccountBillToID,
    TargetAccountName: targetAccountName,
    TargetAccountFiscalNumber: targetAccountFiscalNumber,
    TargetAccountPhone: targetAccountPhone,
    TargetAccountLegalAddress: targetAccountLegalAddress,
    TargetAccountEmail: targetAccountEmail,
    BUCode: buCode,
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Document/CreateReplacement`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

export const updateOperationStatus = async (
  dailyStoreOperationID: number,
  operationStatusID: DailyOperationStatus,
  updateUser: string
): Promise<ResponseInterface<boolean>> => {
  const body = JSON.stringify({
    DailyStoreOperationID: dailyStoreOperationID,
    OperationStatusID: operationStatusID,
    UpdateUser: updateUser,
    UpdateDate: new Date().toISOString(),
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Document/OperationStatus`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

export const getStoreDocuments = async (
  buCode: string
): Promise<ResponseInterface<StoreDocumentInterface[]>> => {
  const uri = `${baseURLAPI}/Document/StoreDocuments?BuCode=${buCode}`;

  try {
    const response = await api.get(uri);

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

export const searchDocumentsFullText = async (
  buCodeInvoicer: string,
  queryStr?: string,
  statusIDList?: DocumentStatus[],
  documentDateFrom?: string,
  documentDateTo?: string
): Promise<ResponseInterface<StoreDocumentInterface[]>> => {
  const uri = `${baseURLAPI}/Document/SearchDocumentsFullText`;

  const body = JSON.stringify({
    buCodeInvoicer,
    queryStr,
    statusIDList,
    documentDateFrom,
    documentDateTo,
  });

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any).response.data;
  }
};

// Get bank ID by bank code
type Bank = {
  BankID: number;
  BankCode: string;
};

const banks: Bank[] = [
  { BankID: 0, BankCode: "0000" },
  { BankID: 1, BankCode: "0001" },
  { BankID: 2, BankCode: "0102" },
  { BankID: 3, BankCode: "0104" },
  { BankID: 4, BankCode: "0105" },
  { BankID: 5, BankCode: "0108" },
  { BankID: 6, BankCode: "0114" },
  { BankID: 7, BankCode: "0115" },
  { BankID: 8, BankCode: "0128" },
  { BankID: 9, BankCode: "0134" },
  { BankID: 10, BankCode: "0137" },
  { BankID: 11, BankCode: "0138" },
  { BankID: 12, BankCode: "0146" },
  { BankID: 13, BankCode: "0151" },
  { BankID: 14, BankCode: "0156" },
  { BankID: 15, BankCode: "0157" },
  { BankID: 16, BankCode: "0163" },
  { BankID: 17, BankCode: "0166" },
  { BankID: 18, BankCode: "0168" },
  { BankID: 19, BankCode: "0169" },
  { BankID: 20, BankCode: "0171" },
  { BankID: 21, BankCode: "0172" },
  { BankID: 22, BankCode: "0173" },
  { BankID: 23, BankCode: "0174" },
  { BankID: 24, BankCode: "0175" },
  { BankID: 25, BankCode: "0177" },
  { BankID: 26, BankCode: "0191" },
  { BankID: 27, BankCode: "9999" },
  { BankID: 28, BankCode: "5000" },
  { BankID: 29, BankCode: "5001" },
  { BankID: 30, BankCode: "5002" },
];

const getBankIdByCode = (code: string): number => {
  const foundBank = banks.find((bank) => bank.BankCode === code);
  return foundBank ? foundBank.BankID : 0;
};
