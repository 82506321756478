import React, { FC } from "react";

interface FormLabelProps {
  fieldName: string;
  htmlFor?: string;
  isRequired?: boolean;
  fieldNameClassname?: string;
}
const FormLabel: FC<FormLabelProps> = ({
  fieldName,
  htmlFor,
  isRequired,
  fieldNameClassname = "",
}) => {
  return (
    <div className="flex flex-1 justify-between">
      <div>
        <label
          htmlFor={htmlFor}
          className={`block text-m font-medium leading-6 text-gray-900 mb-2 ${fieldNameClassname} truncate`}
        >
          {fieldName}
        </label>
      </div>
      {isRequired && <div className="text-zinc-500 text-right">Requerido</div>}
    </div>
  );
};

export default FormLabel;
