import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { PrimaryButton } from "../Buttons";
import LoadingIcon from "../LodingIcon";
import PaginationFooter from "../PaginationFooter";
import { documentStatusFormat } from "../../utils";
import { useAppSelector } from "../../store/hooks";
import { searchDocumentsFullText } from "../../services";
import { FormDatePicker, FormTextInput } from "../FormFields";
import { MixedFilter, MixedFilterDisclosure } from "../FilterDisclosure";
import { DocumentStatus, StoreDocumentInterface } from "../../interfaces";
import { DocumentTableField, DocumentTableItem } from "./DocumentTableItem";

const message = (begin: number, end: number, total: number) => {
  return (
    <p className="text-sm text-gray-700">
      Mostrando resultados <span className="font-medium">{begin}</span> a{" "}
      <span className="font-medium">{end}</span> de los
      <span className="font-medium"> {total}</span> más relevantes.
    </p>
  );
};

const INVOICES_STATUS = [
  DocumentStatus.PENDING,
  DocumentStatus.PAID,
  DocumentStatus.ANULLED,
  DocumentStatus.PARTIAL_PAID,
].map((status) => ({
  name: documentStatusFormat(status as DocumentStatus),
  value: status as DocumentStatus,
  checked: false,
}));
const CREDIT_NOTES_STATUSES = [
  DocumentStatus.TO_ISSUE,
  DocumentStatus.ISSUED,
].map((status) => ({
  name: documentStatusFormat(status as DocumentStatus),
  value: status as DocumentStatus,
  checked: false,
}));

interface DocumentTableSearchProps {
  title?: string;
  rowsPerPage?: number;
  oldestFirst?: boolean;
  fields?: DocumentTableField[];
}
const DocumentTableSearch: FC<DocumentTableSearchProps> = ({
  title,
  fields = Object.values(DocumentTableField).filter(
    (value) => typeof value === "number"
  ) as DocumentTableField[],
  rowsPerPage = 5,
}) => {
  const buCode = useAppSelector((state) => state.user.businessUnit?.code);

  const [filter, setFilter] = useState("");
  const [loader, setLoader] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searched, setSearched] = useState(false);
  const [maxDate, setMaxDate] = useState<Date | null>(new Date());
  const [documents, setDocuments] = useState<StoreDocumentInterface[]>([]);
  const [invoiceStatuses, setInvoiceStatuses] = useState(INVOICES_STATUS);
  const [creditNoteStatuses, setCreditNoteStatuses] = useState(
    CREDIT_NOTES_STATUSES
  );
  const [minDate, setMinDate] = useState<Date | null>(
    moment().subtract(7, "days").toDate()
  );

  const statuses = useMemo(() => {
    const result: MixedFilter<any>[] = [];

    result.push({
      title: "Facturas",
      isMultiple: true,
      multipleOptions: invoiceStatuses,
      onSelectMultipleOption: setInvoiceStatuses,
    });
    result.push({
      title: "Notas de Crédito",
      isMultiple: true,
      multipleOptions: creditNoteStatuses,
      onSelectMultipleOption: setCreditNoteStatuses,
    });

    return result;
  }, [invoiceStatuses, creditNoteStatuses]);

  const paginatedDocuments = useMemo(() => {
    if (!documents) return [];
    return documents.slice(itemOffset, itemOffset + rowsPerPage);
  }, [documents, itemOffset, rowsPerPage]);

  const handlePageClick = (event: { selected: number }) => {
    if (!documents) return;
    const newOffset = (event.selected * rowsPerPage) % documents?.length;
    setItemOffset(newOffset);
  };

  const onSearchDocuments = async () => {
    if (!buCode) return;
    setLoader(true);

    const response = await searchDocumentsFullText(
      buCode,
      filter,
      [
        ...invoiceStatuses
          .filter((status) => status.checked)
          .map((status) => status.value),
        ...creditNoteStatuses
          .filter((status) => status.checked)
          .map((status) => status.value),
      ],
      minDate?.toISOString(),
      maxDate?.toISOString()
    );
    if (response.didError || !response.model) {
      setDocuments([]);
      setLoader(false);
      return;
    }

    setDocuments(response.model);
    setLoader(false);
    setSearched(true);
  };

  // Set total pages
  useEffect(() => {
    if (!documents) return;
    setTotalPages(Math.ceil(documents.length / rowsPerPage));
  }, [itemOffset, rowsPerPage, documents]);

  return (
    <div className="flex flex-col max-w-full flex-1">
      <div className="flex items-center w-full justify-between mr-8 gap-4 mb-2">
        <h2 className="text-2xl font-semibold text-gray-900 truncate">
          {title}
        </h2>
      </div>

      <div className="flex flex-1 flex-col 2xl:flex-row relative gap-2 2xl:gap-10">
        <div className="flex items-center gap-4 sm:gap-10">
          <div className="flex flex-1 flex-col lg:min-w-[30rem]">
            <FormTextInput
              value={filter}
              label="Buscador"
              onClick={onSearchDocuments}
              id={`document-filter-${title}`}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Buscar por ID o por nombre/documento de persona a facturar"
            />
          </div>

          <div className="items-end justify-end flex h-full 2xl:hidden">
            <div>
              <PrimaryButton onClick={onSearchDocuments}>Buscar</PrimaryButton>
            </div>
          </div>
        </div>

        <div className="flex flex-1 gap-2 sm:gap-10 flex-col sm:flex-row">
          <div className="flex gap-4 sm:gap-10">
            <div className="w-full md:w-auto">
              <FormDatePicker
                id="date"
                name="date"
                label="Desde"
                useRange={false}
                maxDate={maxDate}
                value={{
                  startDate: minDate,
                  endDate: minDate,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) =>
                  setMinDate(
                    !!e?.startDate ? moment(e.startDate).toDate() : null
                  )
                }
                configs={{
                  shortcuts: {},
                }}
              />
            </div>

            <div className="w-full md:w-auto">
              <FormDatePicker
                id="date"
                name="date"
                label="Hasta"
                useRange={false}
                minDate={minDate}
                value={{
                  startDate: maxDate,
                  endDate: maxDate,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) =>
                  setMaxDate(
                    !!e?.startDate ? moment(e.startDate).toDate() : null
                  )
                }
                configs={{
                  shortcuts: {},
                }}
              />
            </div>
          </div>

          <div className="flex flex-col items-end justify-end">
            <MixedFilterDisclosure
              showCount
              showClear
              title="Estado"
              filters={statuses}
              className="p-0 sm:pt-10"
            />
          </div>
        </div>

        <div className="items-end justify-end hidden 2xl:flex">
          <div>
            <PrimaryButton onClick={onSearchDocuments}>Buscar</PrimaryButton>
          </div>
        </div>
      </div>

      {paginatedDocuments.length > 0 && !loader && (
        <div className="overflow-x-auto">
          <table className="table-auto w-full mt-4">
            <thead>
              <tr>
                {fields.includes(DocumentTableField.ID) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    ID
                  </th>
                )}

                {fields.includes(DocumentTableField.DATE) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    FECHA DE EMISIÓN
                  </th>
                )}

                {fields.includes(DocumentTableField.OWNER) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    A NOMBRE DE
                  </th>
                )}

                {fields.includes(DocumentTableField.AMOUNT) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    TOTAL
                  </th>
                )}

                {fields.includes(DocumentTableField.TO_PAID) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    MONTO POR COBRAR
                  </th>
                )}

                {fields.includes(DocumentTableField.EXCESS) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    MONTO A REINTEGRAR
                  </th>
                )}

                {fields.includes(DocumentTableField.DETAILS) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    DETALLES
                  </th>
                )}

                {fields.includes(DocumentTableField.STATUS) && (
                  <th className="text-right px-4 py-2 font-semibold text-xs">
                    ESTADO
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {paginatedDocuments.map((document, index) => (
                <DocumentTableItem
                  index={index}
                  fields={fields}
                  document={document}
                  key={document.documentID}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}

      {paginatedDocuments.length === 0 && !loader && searched && (
        <div
          className="flex flex-1 items-center justify-center"
          style={{ minHeight: "5rem" }}
        >
          <p className="text-gray-400">No existen resultados que mostrar</p>
        </div>
      )}

      {loader && (
        <div className="flex items-center justify-center w-full p-8">
          {loader && <LoadingIcon size="2rem" />}
        </div>
      )}

      {!searched && <div className="h-8" />}

      <PaginationFooter
        rowCounts={rowsPerPage}
        itemsOffSet={itemOffset}
        totalPages={totalPages}
        totalItems={documents.length}
        handlePageClick={handlePageClick}
        message={documents.length === 100 ? message : undefined}
      />
    </div>
  );
};

export default DocumentTableSearch;
