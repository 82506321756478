import React, { useState } from "react";
import { PrimaryButton } from "./Buttons";
import { mercantilTransferSearch } from "../services";

const MercantilTest = () => {
  const [total, setTotal] = useState(0);
  const [ci, setCi] = useState("");
  const [phone, setPhone] = useState("");
  const [bank, setBank] = useState("0105");
  const [concept, setConcept] = useState("test");
  const [date, setDate] = useState(new Date());
  const [ref, setRef] = useState("");
  const [otp, setOtp] = useState("");

  const inputClassName =
    "focus block w-full rounded-xl border-0 text-gray-400 " +
    "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
    "placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
    "focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
    "invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer " +
    "h-10";

  const transfer = async (
    ci: string = "V13138745",
    bank: string = "0105",
    reference: string = "25511906554",
    amount: number = 8000,
    date: Date
  ) => {
    const res = await mercantilTransferSearch(
      ci,
      date,
      bank,
      reference,
      amount,
      1
    );
    if (res !== null) {
      if (
        res.didError &&
        res.model?.errorList &&
        res.model?.errorList?.length > 0
      ) {
        alert(res.model?.errorList[0].description);
      } else {
        alert(res.model);
      }
    }
  };

  return (
    <div className="bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4 w-2/3">
      <h2 className="text-xl font-bold pb-4">Mercantil</h2>

      <label>
        CI:
        <input
          type="text"
          value={ci}
          onChange={(event) => setCi(event.target.value)}
          className={inputClassName}
        />
      </label>
      <br />
      <label>
        Monto:
        <input
          type="number"
          value={total}
          onChange={(event) => setTotal(event.target.valueAsNumber)}
          className={inputClassName}
        />
      </label>
      <br />
      <label>
        Banco:
        <input
          type="text"
          value={bank}
          onChange={(event) => setBank(event.target.value)}
          className={inputClassName}
        />
      </label>
      <br />
      <div className="flex justify-around">
        <div className="mx-1">
          <p>Consulta</p>

          <label>
            Fecha:
            <input
              type="date"
              value={date.toISOString().split("T")[0]}
              onChange={(event) => setDate(new Date(event.target.value))}
              className={inputClassName}
            />
          </label>
          <br />
          <label>
            Referencia:
            <input
              type="text"
              value={ref}
              onChange={(event) => setRef(event.target.value)}
              className={inputClassName}
            />
          </label>

          <br />
          <div className="flex justify-between">
            <div className="flex justify-evenly items-center mx-4">
              <PrimaryButton
                onClick={() => transfer(ci, bank, ref, total, date)}
              >
                Transferencia
              </PrimaryButton>
            </div>
            <div>
              <label>
                Teléfono:
                <input
                  type="text"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  className={inputClassName}
                />
              </label>
              <br />
              <div className="flex justify-evenly">
                <PrimaryButton onClick={() => {}}>Pago movil</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-1">
          <p>B2P y C2P</p>

          <label>
            Concepto:
            <input
              type="text"
              value={concept}
              onChange={(event) => setConcept(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <div className="flex justify-between">
            <div className="flex justify-evenly items-center mx-4">
              <PrimaryButton onClick={() => {}}>B2P</PrimaryButton>
            </div>
            <div>
              <label>
                OTP:
                <input
                  type="text"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                  className={inputClassName}
                />
              </label>
              <br />
              <div className="flex justify-evenly">
                <PrimaryButton onClick={() => {}}>C2P</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MercantilTest;
