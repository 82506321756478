import { FC, useEffect, useMemo, useState } from "react";
import "moment/locale/es";
import moment from "moment";
import Modal from "../components/Modal";
import { useAppSelector } from "../store/hooks";
import { PrimaryButton } from "../components/Buttons";
import { BankAccountDTO } from "../interfaces/Dtos/BankAccountDTO";
import { AttachmentTypeIdEnum, EntityIdEnum } from "../interfaces";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { currencyExchangeText, useCurrencyExchanges } from "../utils";
import { DailyStoreCloseDTO } from "../interfaces/Dtos/DailyStoreCloseDTO";
import { MerchantClosuresCard } from "../components/CashDeck/MerchantClosuresCard";
import { DailyStoreOperationsCard } from "../components/CashDeck/DailyStoreOperationsCard";
import {
  DeposiValues,
  DepositModal,
} from "../components/CashDeck/DepositModal";
import {
  DailyOperationType,
  DailyTransactionType,
  DailyOperationStatus,
  DailyStoreOperationDTO,
} from "../interfaces/Dtos/DailyStoreOperationDTO";
import {
  addOperation,
  uploadFileDO,
  getBankAccounts,
  getDailyClosures,
  getDailyOperations,
  getAvailableChange,
  loaderService,
  getBUCredit,
} from "../services";
import { BUCreditDTO } from "../interfaces/Dtos/BUCreditDTO";

const CashDesk: FC = () => {
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);

  const [error, setError] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [buCredit, setBuCredit] = useState<BUCreditDTO>();
  const [availableChange, setAvailableChange] = useState(0);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [closures, setClosures] = useState<DailyStoreCloseDTO[]>([]);
  const [bsDepositModalOpen, setBsDepositModalOpen] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccountDTO[]>([]);
  const [operations, setOperations] = useState<DailyStoreOperationDTO[]>([]);
  const [dollarDepositModalOpen, setDollarDepositModalOpen] = useState(false);

  const operationsBS = useMemo(() => {
    let logs = [...operations];

    // Filter by dollars
    logs = logs.filter((log) => log.currencyID === 1);

    return logs;
  }, [operations]);

  const operationsDollar = useMemo(() => {
    let logs = [...operations];

    // Filter by dollars
    logs = logs.filter((log) => log.currencyID !== 1);

    return logs;
  }, [operations]);

  const bsBankAccounts = useMemo(() => {
    return bankAccount.filter((account) => account.currencyID === 1);
  }, [bankAccount]);

  const dollarBankAccounts = useMemo(() => {
    return bankAccount.filter((account) => account.currencyID !== 1);
  }, [bankAccount]);

  const getChange = async (buCode: string) => {
    const response = await getAvailableChange(buCode);
    if (!response.didError) {
      setAvailableChange(response.model ?? 0);
    }
  };

  const handleOperationCreation = async (
    values: DeposiValues,
    currencyID: number
  ) => {
    if (!user.user || !values.file) return;

    loaderService.start();
    const attachment = await uploadFileDO(
      values.file,
      EntityIdEnum.COBRANZA_FRANQUICIA,
      AttachmentTypeIdEnum.CASH_DEPOSIT_PROOF,
      user.user?.userLogin ?? ""
    );
    if (attachment.didError) {
      loaderService.stop();
      setOpenErrorModal(true);
      setError(attachment.errorMessage);
      return;
    }
    const attachmentID = attachment.model?.attachmentID;

    const operation: DailyStoreOperationDTO = {
      dailyStoreOperationID: 0,
      operationAffectedID: 0,
      operationTypeID: DailyOperationType.EXPENSES,
      operationType: "",
      transactionTypeID: DailyTransactionType.DEPOSIT,
      transactionType: "",
      buCode: user.businessUnit?.code ?? "",
      currencyID,
      registerDate: moment().format("YYYY-MM-DD"),
      operationDate: moment(values.date).format("YYYY-MM-DD"),
      income: 0,
      expenses: values.amount,
      bankID: values.bank!.bankID,
      bankName: "",
      bankAccountID: values.bank!.bankAccountID,
      referenceNum: values.proofOfPayment,
      operationStatusID: DailyOperationStatus.PENDING,
      operationStatusCode: "",
      operationStatusDescription: "",
      lastStatusDate: "",
      lastStatusUser: "",
      attachmentID,
      applied: false,
      creationUser: user.user?.userLogin ?? "",
      creationDate: "",
      updateUser: "",
      updateDate: "",
      lastupdate: 0,
    };

    const response = await addOperation(operation);
    loaderService.stop();

    if (response.didError) {
      setOpenErrorModal(true);
      setError(response.errorMessage);
      return;
    }

    setRefresh(Math.random());
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    getChange(buCode);
  };

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    getChange(buCode);
  }, [user.businessUnit]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getOperations = async () => {
      const response = await getDailyOperations(buCode);
      if (!response.didError && response.model) {
        setOperations(
          response.model.sort((a, b) => {
            return moment(a.creationDate).isBefore(moment(b.creationDate))
              ? 1
              : -1;
          })
        );
      }
    };

    getOperations();
  }, [user.businessUnit, refresh]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getClosures = async () => {
      const response = await getDailyClosures(buCode);
      if (!response.didError && response.model) {
        setClosures(
          response.model.sort((a, b) => {
            return moment(a.closeDate).isBefore(moment(b.closeDate)) ? 1 : -1;
          })
        );
      }
    };

    getClosures();
  }, [user.businessUnit, refresh]);

  useEffect(() => {
    const getAccounts = async () => {
      const response = await getBankAccounts();
      if (!response.didError && response.model) {
        setBankAccount(response.model);
      }
    };

    getAccounts();
  }, []);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getCredit = async () => {
      const response = await getBUCredit(buCode);
      if (!response.didError && response.model) {
        setBuCredit(response.model);
      }
    };

    getCredit();
  }, [user.businessUnit]);

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Opciones de caja
        </header>
      </div>

      <div className="flex flex-col gap-12 mt-8 mx-4 mb-12">
        <div className="flex flex-1 flex-col gap-12 justify-evenly mx-14">
          <div className="flex flex-1 flex-col gap-2 w-full">
            <DailyStoreOperationsCard
              setRefresh={setRefresh}
              operations={operationsBS}
              onDeposit={() => setBsDepositModalOpen(true)}
              balance={closures.find((c) => c.currencyID === 1)?.balance}
            />
          </div>

          <div className="flex flex-1 flex-col gap-2 w-full">
            <DailyStoreOperationsCard
              isDollar
              setRefresh={setRefresh}
              operations={operationsDollar}
              onDeposit={() => setDollarDepositModalOpen(true)}
              balance={closures.find((c) => c.currencyID === 2)?.balance}
            />
          </div>
        </div>

        <div className="flex flex-1 flex-col xl:flex-row gap-24 mx-14 justify-evenly">
          <div className="flex flex-1 flex-col gap-2 w-full">
            <MerchantClosuresCard closures={closures} />
          </div>

          <div className="flex flex-1 flex-col gap-8 w-full h-20">
            <div className="flex flex-1 justify-between bg-white rounded-xl shadow-md hover:shadow-lg border px-8 pb-6 pt-4">
              <p className="text-lg font-semibold text-center text-gray-700">
                Vuelto disponible:
              </p>

              <div className="flex flex-col items-end">
                <p className="text-gray-800 font-semibold">
                  {currencyExchangeText(
                    availableChange,
                    exchanges,
                    "USD",
                    "USD"
                  )}
                </p>
                <p className="text-gray-700 text-sm">
                  {currencyExchangeText(
                    availableChange,
                    exchanges,
                    "BS",
                    "USD"
                  )}
                </p>
              </div>
            </div>

            <div className="flex flex-1 justify-between bg-white rounded-xl shadow-md hover:shadow-lg border px-8 pb-6 pt-4">
              <div className="flex flex-col text-lg text-gray-800">
                <p className="font-semibold">Crédito Taquilla Disponible:</p>

                <div className="flex flex-col">
                  <p className="font-semibold">
                    {currencyExchangeText(
                      buCredit?.balance ?? 0,
                      exchanges,
                      "USD",
                      buCredit?.currencyID === 1 ? "BS" : "USD"
                    )}
                  </p>
                  <p className="text-xs font-medium -mt-1">
                    {currencyExchangeText(
                      buCredit?.balance ?? 0,
                      exchanges,
                      "BS",
                      buCredit?.currencyID === 1 ? "BS" : "USD"
                    )}
                  </p>
                </div>
              </div>

              <div className="flex flex-col text-gray-600">
                <p className="font-medium">Límite:</p>

                <div className="flex flex-col items-end -mt-0.5">
                  <p className="font-semibold">
                    {currencyExchangeText(
                      buCredit?.creditLimit ?? 0,
                      exchanges,
                      "USD",
                      buCredit?.currencyID === 1 ? "BS" : "USD"
                    )}
                  </p>
                  <p className="text-xs font-medium -mt-1">
                    {currencyExchangeText(
                      buCredit?.creditLimit ?? 0,
                      exchanges,
                      "BS",
                      buCredit?.currencyID === 1 ? "BS" : "USD"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DepositModal
        bankAccounts={bsBankAccounts}
        title="Registrar Depósito en VES"
        open={bsDepositModalOpen}
        setOpen={setBsDepositModalOpen}
        onSubmit={async (values) => {
          await handleOperationCreation(values, 1);
          setBsDepositModalOpen(false);
        }}
      />

      <DepositModal
        isDollar
        bankAccounts={dollarBankAccounts}
        title="Registrar Depósito en USD"
        open={dollarDepositModalOpen}
        setOpen={setDollarDepositModalOpen}
        onSubmit={async (values) => {
          await handleOperationCreation(values, 2);
          setDollarDepositModalOpen(false);
        }}
      />

      {/* Error modal */}
      <Modal openModal={openErrorModal} setOpenModal={setOpenErrorModal}>
        <div
          className="flex flex-col items-center justify-center"
          style={{ maxWidth: "20rem" }}
        >
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32 text-red-500" />
          </div>
          <p className="mt-2 text-lg text-center text-gray-700">
            Hubo un error al registrar el depósito
          </p>
          <p className="mt-2 text-center text-gray-700">{error}</p>

          <PrimaryButton
            className="px-4 mt-6"
            onClick={() => setOpenErrorModal(false)}
          >
            Aceptar
          </PrimaryButton>
        </div>
      </Modal>
    </main>
  );
};

export default CashDesk;
