import api from "./api";
import axios from "axios";
import { AttachmentResponseInterface, ResponseInterface } from "../interfaces";

const baseURL = process.env.REACT_APP_API_HOST;

// Upload content files to DigitalOcean
export const uploadFileDO = async (
  file: File,
  entityID: number,
  attachmentTypeID: number,
  creationUser: string,
  identifierID?: string,
  attachmentDescription?: string,
  attachmentObservation?: string
): Promise<ResponseInterface<AttachmentResponseInterface>> => {
  let response: ResponseInterface<AttachmentResponseInterface> = {
    message: "",
    model: null,
    didError: true,
    errorMessage: "",
  };
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURL}/Spaces`;
  const formData = new FormData();
  formData.append("File", file);
  formData.append("EntityID", entityID.toString());
  formData.append("AttachmentTypeID", attachmentTypeID.toString());
  formData.append("CreationUser", creationUser);
  if (identifierID) {
    formData.append("IdentifierID", identifierID);
  }
  if (attachmentDescription) {
    formData.append("AttachmentDescription", attachmentDescription);
  }
  if (attachmentObservation) {
    formData.append("AttachmentObservation", attachmentObservation);
  }

  try {
    const res = await api.post(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      cancelToken: source.token,
    });
    if (res && res.status === 200 && res.data.didError === false) {
      return res.data;
    }
  } catch (error) {}
  response.errorMessage = "Hubo un error al registrar el adjunto";
  return response;
};

// Update content files uploaded to DigitalOcean
export const updateFileDO = async (
  file: File,
  attachmentID: string
): Promise<ResponseInterface<boolean>> => {
  let response = {
    message: "",
    model: false,
    didError: true,
    errorMessage: "",
  };
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURL}/Spaces`;
  const formData = new FormData();
  formData.append("File", file);
  formData.append("AttachmentID", attachmentID);

  try {
    const res = await api.put(uri, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      cancelToken: source.token,
    });
    if (res && res.status === 200 && res.data.didError === false) {
      return res.data;
    }
  } catch (error) {}
  response.errorMessage = "Hubo un error al actualizar el adjunto";
  return response;
};

// Preview content files uploaded to DigitalOcean
export const previewFileDO = async (
  attachmentID: string
): Promise<ResponseInterface<AttachmentResponseInterface>> => {
  const uri = `${baseURL}/Attachment?attachmentID=${attachmentID}`;
  try {
    // If the files exists in db return the url
    const response = await api.get(uri);
    if (
      response &&
      response.status === 200 &&
      response.data.didError === false
    ) {
      const data = response.data;
      const attachmentPath = data.model.attachmentPath;
      const fileExtension = attachmentPath.split(".").pop();
      try {
        // Get a preview of the file
        const response = await axios.get(attachmentPath, {
          responseType: "blob",
        });
        let file: Blob;
        if (fileExtension === "pdf") {
          file = new Blob([response.data], { type: "application/pdf" });
        } else {
          file = new Blob([response.data], { type: "image/png" });
        }
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank", "toolbar=0,location=0,menubar=0");
        return data;
      } catch (error) {}
      return {
        message: "",
        model: null,
        didError: true,
        errorMessage: "Hubo un error al obtener el adjunto de DigitalOcean",
      };
    }
  } catch (error) {}
  return {
    message: "",
    model: null,
    didError: true,
    errorMessage:
      "Hubo un error al obtener el url del adjunto en la base de datos",
  };
};

// Delete content files uploaded to DigitalOcean
export const deleteFileDO = async (
  attachmentID: string
): Promise<ResponseInterface<boolean>> => {
  let response = {
    message: "",
    model: false,
    didError: true,
    errorMessage: "",
  };

  const uri = `${baseURL}/Spaces`;
  const body = {
    AttachmentID: attachmentID,
  };
  try {
    const res = await axios.delete(uri, { data: body });
    if (res && res.status === 200 && res.data.didError === false) {
      return res.data;
    }
  } catch (error) {}
  response.errorMessage = "Hubo un error al eliminar el adjunto";
  return response;
};
