export enum EntityIdEnum {
  GENERAL = 1,
  GUIAS,
  TRACKING,
  DOCUMENT,
  CLIENTES,
  MANIFIESTO,
  USER,
  COBRANZA_FRANQUICIA,
  COBRANZA_REPORTE,
  PAYMENT,
  PARTNER,
  COLLECTING,
  ACCOUNT,
  BU,
  CURRENCY,
  NOTIFICATION,
  SERVICE,
  AGREEMENT,
  LOCATION,
  ZONE,
  ITEM,
  MEASURE_UNIT,
  PREGUIAS,
  CONTENEDOR,
  PACKAGE,
  SHIPPING_METHOD,
  MASTER_SHIPMENT,
  APPROVAL,
  POD,
  FACTURACION_FRANQUICIA,
}

export enum AttachmentTypeIdEnum {
  USER_PROFILE = 1,
  ID_DOCUMENT,
  PACKAGE_PHOTO,
  POD,
  PAYMENT_PROOF,
  CASH_DEPOSIT_PROOF,
  INVOICE_FRANCHISE_PROOF,
}

export enum AttachmentExtensionIdEnum {
  JPG = 1,
  PNG,
  PDF,
  BMP,
  GIF,
}

export interface AttachmentResponseInterface {
  attachmentID: string;
  attachmentName: string;
  attachmentPath: string;
}
