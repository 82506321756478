import { FC, useEffect, useMemo, useState } from "react";
import LoadingIcon from "../LodingIcon";
import PaginationFooter from "../PaginationFooter";
import { StoreDocumentInterface } from "../../interfaces";
import { DocumentTableField, DocumentTableItem } from "./DocumentTableItem";
import { BUCreditDTO } from "../../interfaces/Dtos/BUCreditDTO";

interface DocumentTableProps {
  documents: StoreDocumentInterface[];
  title?: string;
  fields?: DocumentTableField[];
  rowsPerPage?: number;
  loader?: boolean;
  buCredit?: BUCreditDTO;
}
export const DocumentTable: FC<DocumentTableProps> = ({
  documents,
  title,
  buCredit,
  rowsPerPage = 5,
  loader = false,
  fields = Object.values(DocumentTableField).filter(
    (value) => typeof value === "number"
  ) as DocumentTableField[],
}) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Paginated documents
  const documentsPaginated = useMemo(() => {
    if (!documents) return [];
    return documents.slice(itemOffset, itemOffset + rowsPerPage);
  }, [documents, itemOffset, rowsPerPage]);

  // Handle page click
  const handlePageClick = (event: { selected: number }) => {
    if (!documents) return;
    const newOffset = (event.selected * rowsPerPage) % documents?.length;
    setItemOffset(newOffset);
  };

  // Set total pages
  useEffect(() => {
    if (!documents) return;
    setTotalPages(Math.ceil(documents.length / rowsPerPage));
  }, [itemOffset, rowsPerPage, documents]);

  return (
    <div className="flex flex-col max-w-full flex-1">
      <div className="flex items-center w-full justify-between mr-8 gap-4">
        <h2 className="text-2xl font-semibold text-gray-900 truncate">
          {title}
        </h2>
      </div>

      {!loader && documentsPaginated.length > 0 && (
        <div className="overflow-x-auto">
          <table className="table-auto w-full mt-4">
            <thead>
              <tr>
                {fields.includes(DocumentTableField.ID) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    ID
                  </th>
                )}

                {fields.includes(DocumentTableField.DATE) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs">
                    FECHA DE EMISIÓN
                  </th>
                )}

                {fields.includes(DocumentTableField.OWNER) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    A NOMBRE DE
                  </th>
                )}

                {fields.includes(DocumentTableField.AMOUNT) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    TOTAL
                  </th>
                )}

                {fields.includes(DocumentTableField.TO_PAID) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    MONTO POR COBRAR
                  </th>
                )}

                {fields.includes(DocumentTableField.EXCESS) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    MONTO A REINTEGRAR
                  </th>
                )}

                {fields.includes(DocumentTableField.DETAILS) && (
                  <th className="text-left px-4 py-2 font-semibold text-xs truncate">
                    DETALLES
                  </th>
                )}

                {fields.includes(DocumentTableField.STATUS) && (
                  <th className="text-right px-4 py-2 font-semibold text-xs">
                    ESTADO
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {documentsPaginated.map((document, index) => (
                <DocumentTableItem
                  index={index}
                  fields={fields}
                  document={document}
                  buCredit={buCredit}
                  key={document.documentID}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loader && documentsPaginated.length === 0 && (
        <div
          className="flex flex-1 items-center justify-center"
          style={{ minHeight: "5rem" }}
        >
          <p className="text-gray-400">No existen resultados que mostrar</p>
        </div>
      )}

      {loader && (
        <div className="flex items-center justify-center w-full p-8">
          {loader && <LoadingIcon size="2rem" />}
        </div>
      )}

      <PaginationFooter
        rowCounts={rowsPerPage}
        itemsOffSet={itemOffset}
        totalItems={documents.length}
        handlePageClick={handlePageClick}
        totalPages={totalPages}
      />
    </div>
  );
};
