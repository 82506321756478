import { PaymentMode, ShipmentService } from "../../interfaces";

export enum BlockedReason {
  DEBT_30_DAYS = 32,
  DEBT_60_DAYS = 33,
  DEBT_90_DAYS = 34,
  OTHER = 35,
}

export interface AccountBlocked {
  accountBlockedID: string;
  accountID: string;
  serviceID?: ShipmentService;
  paymentModeID?: PaymentMode;
  buCode?: string;
  fromDate?: string;
  toDate?: string;
  blockedReasonID: BlockedReason;
  observation: string;
  creationUser: string;
  creationDate: string;
  updateUser?: string;
  updateDate?: string;
  statusID: number;
  lastUpdate: number;
}
