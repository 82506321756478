import api from "./api";
import axios from "axios";
import { alertService } from "./alertService";
import {
  Nullable,
  PDocumentType,
  PDocumentInterface,
  ResponseInterface,
  PDocumentStatus,
} from "../interfaces";

const baseURLAPI = process.env.REACT_APP_API_HOST;

export const pdocumentDynamicSearch = async (
  buCodeVendor?: string,
  statusIDList?: PDocumentStatus[],
  documentTypeIDList?: PDocumentType[]
): Promise<Nullable<PDocumentInterface[]>> => {
  let uri = `${baseURLAPI}/PurchaseDocument/DynamicSearch?`;
  if (buCodeVendor) {
    uri += `BuCodeVendor=${buCodeVendor}&`;
  }
  if (statusIDList) {
    uri += `StatusIDList=${statusIDList.join(",")}&`;
  }
  if (documentTypeIDList) {
    uri += `DocumentTypeIDList=${documentTypeIDList.join(",")}&`;
  }

  try {
    const response = await api.get(uri);

    if (response.status === 200 && response.data.model != null) {
      return response.data.model;
    }
  } catch (error) {
    alertService.error("No se pudo obtener el documento");
  }

  return null;
};

export const updatePDocument = async (
  documentID: string,
  documentNumber?: string,
  fiscalControlNumber?: string,
  attachmentID?: string,
  statusID?: PDocumentStatus,
  documentTypeID?: number,
  updateUser?: string
): Promise<ResponseInterface<string>> => {
  const body = JSON.stringify({
    DocumentID: documentID,
    DocumentNumber: documentNumber,
    FiscalControlNumber: fiscalControlNumber,
    AttachmentID: attachmentID,
    StatusID: statusID,
    DocumentTypeID: documentTypeID,
    UpdateUser: updateUser,
  });
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/PurchaseDocument/Update`;

  try {
    const response = await api.put(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return {
    model: null,
    didError: true,
    message: "Error al actualizar la factura",
    errorMessage: "Error al actualizar la factura",
  };
};
