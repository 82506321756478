import { createContext } from "react";

export interface PendingShipmentContextInterface {
  refresh: number;
  setRefresh: (refresh: number) => void;
}

export const PendingShipmentsContext =
  createContext<PendingShipmentContextInterface>({
    refresh: 0,
    setRefresh: () => {},
  });
