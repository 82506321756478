import * as Yup from "yup";
import Modal from "./Modal";
import { Formik } from "formik";
import { PrimaryButton } from "./Buttons";
import { useAppSelector } from "../store/hooks";
import { DocumentInterface } from "../interfaces";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { alertService, reportIncidence, loaderService } from "../services";
import { FormFileUpload, FormTextAreaInput, FormTextInput } from "./FormFields";
import { NetworkLog } from "../interfaces/ReportInterface";

interface IncidenceModalProps {
  logs: NetworkLog[];
  document: DocumentInterface;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const IncidenceTicketModal = ({
  logs,
  document,
  openModal,
  setOpenModal,
}: IncidenceModalProps) => {
  const login = useAppSelector((state) => state.user);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .max(90, "El título puede tener una cantidad máxima de 90 caracteres")
      .required("El título es requerido"),
    description: Yup.string()
      .max(600, "La descripción puede tener un máximo de 600 caracteres")
      .required("La descripción es requerida"),
    files: Yup.array<File>()
      .max(3, "Solo se pueden adjuntar un máximo de 3 archivos")
      .test(
        "is-pdf-or-image",
        "Los archivos deben ser formato PDF o imagen",
        (files) => {
          if (files) {
            return files.every(
              (file) =>
                file.type === "application/pdf" ||
                file.type.startsWith("image/")
            );
          }
          return true;
        }
      ),
  });

  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal} className="w-1/3">
      {/* Header */}
      <div className="flex flex-row justify-between mb-4">
        <h2 className="text-xl font-bold">Crear ticket de incidencia</h2>
        <div className="ml-3 flex h-7 items-center">
          <button
            type="button"
            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => setOpenModal(false)}
          >
            <span className="absolute -inset-2.5" />
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      <Formik
        initialValues={{
          title: "",
          description: "",
          files: undefined as File[] | undefined,
        }}
        onSubmit={async (values, { resetForm }) => {
          setOpenModal(false);
          loaderService.start();
          const res = await reportIncidence(
            login,
            values.title,
            values.description,
            logs,
            document,
            values.files
          );
          loaderService.stop();
          if (res.didError) {
            alertService.error(res.errorMessage);
            return;
          }
          resetForm();
          alertService.success("Ticket creado exitosamente");
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <div className="flex flex-1 flex-col gap-4">
            <div className="flex flex-col flex-1">
              <FormTextInput
                name="title"
                label="Título"
                value={formik.values.title}
                error={
                  formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-col flex-1">
              <FormTextAreaInput
                className="h-28 resize-none"
                name="description"
                label="Descripción"
                value={formik.values.description}
                error={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : undefined
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            <div style={{ maxHeight: "20rem" }}>
              <FormFileUpload
                className="flex block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                zoneClassName="!h-40"
                description="Se pueden adjuntar hasta 3 archivos en formato PDF o imagen"
                onSelectFile={(files) => {
                  if (files) {
                    formik.setFieldValue("files", files);
                  } else {
                    formik.setFieldError(
                      "file",
                      "El archivo debe ser formato PDF o imagen"
                    );
                  }
                }}
                multiple
                error={
                  formik.touched.files && formik.errors.files
                    ? formik.errors.files
                    : undefined
                }
              />
            </div>

            {/* informative text with instructions */}
            <p className="text-sm text-gray-500 my-2">
              La información de la(s) guía(s), los pagos guardados y la orden o
              factura también será transmitida en el ticket. Le llegará por
              correo la confirmación de la creación del mismo.
            </p>

            <PrimaryButton onClick={formik.submitForm} className=" flex w-full">
              Crear Ticket
            </PrimaryButton>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default IncidenceTicketModal;
