import React, { useState } from "react";
import { PrimaryButton } from "./Buttons";
import { consultBC, listBankBC, sendB2PBC, sendC2PBC } from "../services";
import { useAppSelector } from "../store/hooks";

const BCTest = () => {
  const [total, setTotal] = useState(0);
  const [ci, setCi] = useState("");
  const [phone, setPhone] = useState("");
  const [bank, setBank] = useState("0114");
  const [concept, setConcept] = useState("test");
  const [trx, setTrx] = useState("PM");
  const [ref, setRef] = useState("");
  const [invoice, setInvoice] = useState("");
  const [otp, setOtp] = useState("");
  const userConnected = useAppSelector((state) => state.user.user);

  const inputClassName =
    "focus block w-full rounded-xl border-0 text-gray-400 " +
    "text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 " +
    "placeholder:text-gray-400 focus:ring-2 focus:ring-inset " +
    "focus:ring-indigo-600 sm:text-sm sm:leading-6 " +
    "invalid:[&:not(:placeholder-shown):not(:focus)]:ring-red-500 peer " +
    "h-10";

  const b2p = async (
    bank: string,
    total: number,
    ci: string,
    phone: string
  ) => {
    const res = await sendB2PBC(bank, ci, total, phone, concept, "Factura");
    if (res !== null) {
      console.log(res);
      alert(`Código de respuesta: ${res?.codigoError} \n
      numSeq: ${res?.secuencial} \n
      Respuesta de Bancaribe: ${res?.descripcionError} \n
      Confirmacion: ${res?.codigoConfirmacion}`);
    }
  };

  const c2p = async (
    bank: string,
    total: number,
    ci: string,
    phone: string,
    otp: string,
    ref: string
  ) => {
    const res = await sendC2PBC(
      bank,
      ci,
      total,
      phone,
      concept,
      otp,
      ref,
      false
    );
    if (res !== null) {
      console.log(res);
      alert(`Código de respuesta: ${res?.codigoError} \n
      numSeq: ${res?.secuencial} \n
      Respuesta de Bancaribe: ${res?.descripcionError}`);
    }
  };

  const consult = async (
    ci: string,
    trx: string,
    phone: string,
    amount: number,
    ref: string,
    invoice?: string
  ) => {
    const res = await consultBC(ci, amount, phone, ref, trx, invoice);
    if (res?.cedulaCliente !== null) {
      console.log(res);
      alert(`Estado de ejecucion respuesta: ${res?.estadoEjecucion} \n
      Respuesta de Bancaribe: ${res?.descripcion} \n
      Confirmacion: ${res?.codigoConfirmacion}`);
    } else {
      console.log(res);
      alert(`Estado de ejecucion respuesta: ${res?.message?.code} \n
      Respuesta de Bancaribe: ${res?.message?.message}`);
    }
  };

  const listBank = async (type: string) => {
    const res = await listBankBC(type);
    if (res !== null) {
      console.log(res);
      alert("Listado en consola");
    }
  };

  return (
    <div className="bg-white shadow-md rounded-md px-8 pt-6 pb-8 mb-4 w-2/3">
      <h2 className="text-xl font-bold pb-4">Bancaribe</h2>

      <label>
        CI:
        <input
          type="text"
          value={ci}
          onChange={(event) => setCi(event.target.value)}
          className={inputClassName}
        />
      </label>
      <br />
      <label>
        Telefono:
        <input
          type="text"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          className={inputClassName}
        />
      </label>
      <br />
      <label>
        Monto:
        <input
          type="number"
          value={total}
          onChange={(event) => setTotal(event.target.valueAsNumber)}
          className={inputClassName}
        />
      </label>
      <br />
      <div className="flex justify-around">
        <div className="mx-1">
          <p>B2P y C2P</p>
          <label>
            Banco:
            <input
              type="text"
              value={bank}
              onChange={(event) => setBank(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <label>
            Concepto:
            <input
              type="text"
              value={concept}
              onChange={(event) => setConcept(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <div className="flex justify-between">
            <div className="flex justify-evenly items-center mx-4">
              <PrimaryButton onClick={() => b2p(bank, total, ci, phone)}>
                B2P
              </PrimaryButton>
            </div>
            <div>
              <label>
                OTP:
                <input
                  type="text"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                  className={inputClassName}
                />
              </label>
              <br />
              <div className="flex justify-evenly">
                <PrimaryButton
                  onClick={() => c2p(bank, total, ci, phone, otp, ref)}
                >
                  C2P
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-1">
          <p>Consulta</p>
          <label>
            TRX:
            <input
              type="text"
              value={trx}
              onChange={(event) => setTrx(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <label>
            Referencia:
            <input
              type="text"
              value={ref}
              onChange={(event) => setRef(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <label>
            Factura:
            <input
              type="text"
              value={invoice}
              onChange={(event) => setInvoice(event.target.value)}
              className={inputClassName}
            />
          </label>
          <br />
          <div className="flex justify-evenly">
            <PrimaryButton
              onClick={() => consult(ci, trx, phone, total, ref, invoice)}
            >
              Consulta
            </PrimaryButton>
          </div>
        </div>
      </div>
      <br />
      <div className="flex justify-around">
        <div className="flex justify-evenly">
          <PrimaryButton onClick={() => listBank("b2p")}>
            Listar Bancos B2P
          </PrimaryButton>
        </div>
        <div className="flex justify-evenly">
          <PrimaryButton onClick={() => listBank("c2p")}>
            Listar Bancos C2P
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default BCTest;
