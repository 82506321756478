import { ItemInterface } from "./ItemInterface";
import { AccountInterface } from "./AccountInterface";
import { PieceInterface } from "./PackagePieceInterface";
import { BoxAccountInterface } from "./BoxAccountInterface";
import { LocationInterface, BusinessUnitInterface } from "./LocationInterface";
import { Nullable } from "./GenericTypes";

export enum AccountAuthorization {
  NO_AUTHORIZED,
  AUTHORIZED,
  ENTERPRISE,
}

export enum ShipmentStatus {
  DRAFT = 5,
  ACTIVE = 1,
  ANNULLED = 3,
  REPLACED = 55,
  DELIVERED = 30,
  // ABANDONED,  // NOT FOUND IN DB
  // DESINCORPORATED,  // NOT FOUND IN DB
}

export enum DeliveryType {
  AT_HOME = 20,
  AT_OFFICE = 10,
  INTERNATIONAL = 11,
}

export enum ShipmentService {
  STANDARD = 60,
  DOCUMENT = 61,
  INTERNATIONAL = 62,
  ECONOMIC = 64,
  NATIONAL_LOCKER = 81,
}
export enum PaymentMode {
  CONTADO = 1,
  COD = 2,
  CREDIT = 3,
  BOXOFFICE_CREDIT = 4,
  DEST_BOXOFFICE_CREDIT = 5,
}

export interface DocumentResumeInterface {
  documentId: string;
  documentNumber: string;
  documentTypeID: number;
  status: number;
  creationDate: string;
  accountName: string;
  urlDocument: string;
  totalAmountBaseCurr: number;
  balanceAmountBaseCurr: number;
}

export interface ShipmentInterface {
  id?: string;
  shipmentNumber?: string;
  document?: DocumentResumeInterface;
  preShipmentHeaderID?: string;
  shipper: AccountInterface;
  consignee: AccountInterface;
  shipperAuth?: AccountAuthorization;
  accountBillTo?: AccountInterface;
  accountSiteID?: Nullable<number>;
  consigneeAuth?: AccountAuthorization;
  buSource: BusinessUnitInterface;
  deliveryType: DeliveryType;
  declaredValue?: string;
  lastBU?: string;
  lastBUDate?: string;
  consigneeAddress: LocationInterface;
  buConsignee?: BusinessUnitInterface;
  pieces: PieceInterface[];
  items: ItemInterface[];
  boxAccount?: BoxAccountInterface;
  paymentMode?: PaymentMode;
  observations?: string;
  deliveryDate?: string;
  deliveryObservation?: string;
  deliveryCreationUser?: string;
  service?: ShipmentService;
  discount?: string;
  deliveryDistance?: number;
  substituteFor?: string;
  substitutedBy?: string;
  status: ShipmentStatus;
  createdAt?: string;
  updatedAt?: string;
  total: number;
  totalChargedWeight?: number;
  totalPhysicalWeight?: number;
  totalDimensionalWeight?: number;
  totalPostalTaxBaseCurr?: number;
}

export interface ShipmentResumeInterface {
  id: string;
  shipmentNumber: string;
  service: ShipmentService;
  paymentMode: PaymentMode;
  deliveryType: DeliveryType;
  lastBU: string;
  observations: string;
  deliveryDate: string;
  deliveryObservation: string;
  deliveryCreationUser: string;
  totalPieces: number;
  totalAmount: number;
  deliveryDistance?: number;
  totalChargedWeight?: number;
  totalPhysicalWeight?: number;
  totalDimensionalWeight?: number;
  totalPostalTaxBaseCurr?: number;
  accountSiteID?: Nullable<number>;
  substituteFor?: string;
  substitutedBy?: string;
  document?: DocumentResumeInterface;
  shipper: {
    id: string;
    taxIdentificationTypeID: Nullable<number>;
    identificationNumber: string;
    accountFullName: string;
    listAccountEmail: { email: string }[];
    listAccountPhone: { phoneNumber: string; countryID: number }[];
  };
  consignee: {
    id: string;
    taxIdentificationTypeID: Nullable<number>;
    identificationNumber: string;
    accountFullName: string;
    listAccountEmail: { email: string }[];
    listAccountPhone: { phoneNumber: string; countryID: number }[];
  };
  accountBillTo?: {
    id: string;
    taxIdentificationTypeID: Nullable<number>;
    identificationNumber: string;
    accountFullName: string;
  };
  buSource: {
    id: string;
    code: string;
    name: string;
    location: {
      id: string;
      name: string;
      code: string;
    };
  };
  buConsignee: {
    id: string;
    code: string;
    name: string;
    location: {
      id: string;
      name: string;
      code: string;
    };
  };
  consigneeAddress: {
    addressName: string;
    latitude: number;
    longitude: number;
    reference: string;
  };
  pieces: PieceInterface[];
  items: ItemInterface[];
  status: ShipmentStatus;
  createdAt: string;
  updatedAt: string;
}

export interface PreShipmentInterface extends Partial<ShipmentInterface> {
  id: string;
  preShipmentNumber: string;
  creationUser: string;
}

export interface ShipmentGuideModel {
  sourceBUCode: string;
  serviceCode: number;
  shipmentNumber: string;
  pieceNumber: number;
  locationDestinationCode: string;
  totalPiece: number;
  totalWeigth?: number;
}

export interface ShipmentSimpleInterface {
  id: string;
  shipmentNumber: string;
  paymentMode?: PaymentMode;
  total: number;
  shipper: {
    accountFullName: string;
  };
  consignee: {
    accountFullName: string;
  };
  buSource: {
    code: string;
  };
  buConsignee: {
    code: string;
  };
  consigneeAddress: {
    addressName: string;
  };
  shipperAddress: {
    addressName: string;
  };
  status: ShipmentStatus;
  accountBillTo?: AccountInterface;
  accountSiteID?: Nullable<number>;
}

export interface ShipmentTracking {
  status: string;
  description: string;
  city: string;
  state: string;
  locationZone: string;
  date: string;
}

export interface ShipmentPrintLabel {
  labelFormat: string;
  stringLabel: string[];
}
