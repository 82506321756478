import { FC, useState } from "react";
import DropdownMenu from "../DropdownMenu";
import { ClientDetails } from "./ShipmentHeader";
import { AccountInterface } from "../../interfaces";
import { AccountSearch } from "../Account/AccountSearch";
import UpdateClientModal from "../Account/UpdateClientModal";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

export interface ShipmentOwnerProps {
  owner?: AccountInterface;
  onSelectOwner?: (owner: AccountInterface) => void;
  openCreationModal?: (open: boolean) => void;
}

const ShipmentOwner: FC<ShipmentOwnerProps> = ({
  owner,
  onSelectOwner = () => {},
  openCreationModal = () => {},
}) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);

  return (
    <div className="flex flex-1 gap-16 flex-row items-start justify-between bg-white rounded-lg border pl-2 pr-8 py-4">
      <div className="flex flex-1 items-center">
        <div
          title="Menú de opciones"
          data-te-toggle="tooltip"
          onClick={() => setOpenDropdownMenu(true)}
          className="rounded-full hover:cursor-pointer p-4"
        >
          <EllipsisVerticalIcon
            className="h-5 w-5 text-gray3-600"
            aria-hidden="true"
          />
        </div>

        <DropdownMenu
          open={openDropdownMenu}
          setOpen={setOpenDropdownMenu}
          onSelect={() => setOpenUpdateModal(true)}
          options={[
            {
              title: "Editar",
              value: "edit",
            },
          ]}
        />

        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 gap-1 flex-col xl:flex-row xl:gap-2 xl:items-center">
            <div className="flex items-center">
              <p className="text-sm text-gray-900 font-semibold">FACTURAR A:</p>
            </div>

            <p className="text-sm truncate">{owner?.accountFullName}</p>
          </div>

          <div className="flex flex-1 flex-col mt-4">
            <ClientDetails
              showAddress
              client={owner}
              onLocationClick={() => setOpenUpdateModal(true)}
            />
          </div>
        </div>
      </div>

      <div
        className="w-full flex flex-1 justify-end gap-4"
        style={{ maxWidth: "30rem" }}
      >
        <AccountSearch
          title="Facturar a otra persona"
          placeholder="Buscar cliente..."
          onSelectClient={onSelectOwner}
          openCreationModal={openCreationModal}
        />
      </div>

      {!!owner && (
        <UpdateClientModal
          selectedItem={owner}
          openModal={openUpdateModal}
          setOpenModal={setOpenUpdateModal}
          setSelectedItem={onSelectOwner}
        />
      )}
    </div>
  );
};

export default ShipmentOwner;
