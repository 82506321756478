export interface PDocumentItemInterface {
  documentItemID: string;
  itemID: number;
  itemSeqNumber: number;
  itemQuantity: number;
  itemName: string;
  itemComment: string;
  itemPrice: number;
  taxAmount: number;
  pctTax: number;
  grossAmount: number;
  pctDiscount: number;
  discountAmount: number;
  totalAmount: number;
  pctMunicipalTax: number;
  pctOtherTax: number;
  taxAmountBaseCurr: number;
  grossAmountBaseCurr: number;
  discountAmountBaseCurr: number;
  totalAmountBaseCurr: number;
  creationUser: string;
  creationDate: string;
  updateUser: string;
  updateDate: string;
  statusID: number;
  statusName: string;
}

export interface PurchasePaymentDetailInterface {
  paymentDetailID: string;
  paymentMethodID: number;
  paymentMethodName: string;
  paymentMethodCode: string;
  currencyID: string;
  bankAccountID?: number;
  sourceBankAccountID?: number;
  attachmentID?: string;
  referenceNum: string;
  amount: number;
  amountBaseCurr: number;
  pctWithholding: number;
  amountWithholding: number;
  amountWithholdingBaseCurr: number;
  exchangeRate: number;
  amountAppliedIGTF: number;
  amountAppliedIGTFBaseCurr: number;
  pctIGTFTax: number;
  iGTFTaxAmount: number;
  iGTFTaxAmountBaseCurr: number;
  paymentDate: number;
}

export interface PurchasePaymentInterface {
  docPaymentID: number;
  paymentID: string;
  balance: number;
  paymentDate: string;
  paymentAmountBaseCurr: number;
  paymentStatusID: number;
  paymentStatusName: string;
  paymentStatusCode: string;
  paymentDetails: PurchasePaymentDetailInterface[];
}

export enum PDocumentType {
  CHD = 1,
  FCT = 2,
  FOI = 3,
  NDC = 4,
  NDD = 5,
  ODF = 6,
}

export enum PDocumentStatus {
  PENDING = 56,
  TO_APPROVE = 57,
  APPROVED = 58,
}

export interface PDocumentInterface {
  documentID: string;
  reportID: number;
  documentReportID: number;
  documentTypeID: PDocumentType;
  documentTypeName: string;
  documentTypeCode: string;
  documentTermID: number;
  currencyID: number;
  buCodeVendor: string;
  attachmentID?: string;
  accountVendorID: string;
  documentAffectedID?: string;
  documentAffectedDocumentNumber?: string;
  documentAppliedID?: string;
  documentAppliedDocumentNumber?: string;
  lastStatusChangedBy: string;
  documentDate: string;
  documentDueDate: string;
  lastStatusDate: string;
  registrationTime: string;
  documentNumber: string;
  fiscalControlNumber?: string;
  documentSerie?: string;
  hasRetention: boolean;
  exchangeRate: number;
  taxExemptAmountBaseCurr: number;
  pctTax: number;
  taxAmountBaseCurr: number;
  pctMunicipalTax?: number;
  municipalTaxAmountBaseCurr: number;
  pctOtherTax?: number;
  otherTaxAmountBaseCurr: number;
  pctDiscount?: number;
  discountAmountBaseCurr: number;
  grossAmountBaseCurr: number;
  totalAmountBaseCurr: number;
  balanceAmountBaseCurr: number;
  pctIGTFTax?: number;
  igtfTaxAmountBaseCurr?: number;
  changeAmountBaseCurr?: number;
  documentAffectedTypeCode?: string;
  reportDate: string;
  reportFromDate: string;
  reportToDate: string;
  observations: string;
  creationUser: string;
  creationDate: string;
  updateUser: string;
  updateDate: string;
  statusID: PDocumentStatus;
  statusName: string;
  statusCode: string;
  items: PDocumentItemInterface[];
  payments: PurchasePaymentInterface[];
}
