import { useMemo, FC } from "react";
import { LinkText } from "../Buttons";
import AnimatedHeight from "../AnimatedHeight";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Nilable,
  AccountInterface,
  LocationInterface,
  BoxAccountInterface,
  BusinessUnitInterface,
} from "../../interfaces";
import {
  formatName,
  formatPhoneNumber,
  useCurrencyExchanges,
  currencyExchangeText,
} from "../../utils";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface ClientDetailsProps {
  client?: AccountInterface;
  showAddress?: boolean;
  onLocationClick?: () => void;
}
export const ClientDetails: FC<ClientDetailsProps> = ({
  client,
  showAddress,
  onLocationClick,
}) => {
  const nPhones = useMemo(() => {
    return client?.listAccountPhone?.length ?? 0;
  }, [client?.listAccountPhone]);

  const nEmails = useMemo(() => {
    return client?.listAccountEmail?.length ?? 0;
  }, [client?.listAccountEmail]);

  return (
    <div>
      <div className="flex flex-row gap-4 items-center">
        <p className="text-sm text-gray-700 hidden lg:block">Identificación:</p>
        <p className="text-sm ">
          {client?.abreviationName}
          {client?.identificationNumber}
        </p>
      </div>

      <div
        className={classNames(
          "flex flex-row gap-4 items-center",
          nPhones === 0 && !client?.fiscalPhoneNumber && "hidden"
        )}
      >
        <p className="text-sm text-gray-700 hidden lg:block">Teléfono:</p>
        {!!nPhones ? (
          <p className="text-xs truncate">
            {formatPhoneNumber(client?.listAccountPhone?.[0])}
          </p>
        ) : (
          <p className="text-xs truncate">{client?.fiscalPhoneNumber}</p>
        )}
      </div>

      {!!nEmails && (
        <div className="flex flex-row gap-4 items-center">
          <p className="text-sm text-gray-700 hidden lg:block">Correo:</p>

          <p className="text-xs truncate">
            {client?.listAccountEmail?.[0].email}
          </p>
        </div>
      )}

      {showAddress && (
        <div className="flex flex-row gap-4 items-center">
          <p className="text-sm text-gray-700 hidden lg:block">Dirección:</p>
          {!!client?.fiscalAddress ? (
            <p className="text-xs truncate">{client?.fiscalAddress}</p>
          ) : (
            <LinkText
              text="Agregar dirección fiscal"
              className="text-xs"
              onClick={onLocationClick}
            />
          )}
        </div>
      )}
    </div>
  );
};

export interface ShipmentHeaderProps {
  /**
   * Indicates if the accordion item is active
   */
  active: boolean;
  /**
   * Shipment total
   */
  total?: number;
  /**
   * Shipper data
   */
  shipper?: AccountInterface;
  isShipperEnterprise?: boolean;
  /**
   * Consignee data
   */
  consignee?: AccountInterface;
  isConsigneeEnterprise?: boolean;
  /**
   * Box account data
   */
  boxAccount?: Nilable<BoxAccountInterface>;
  /**
   * Business unit source data
   */
  buSource?: BusinessUnitInterface;
  /**
   * Location data
   */
  location?: LocationInterface;
  /**
   * Business unit consignee data
   */
  buConsignee?: BusinessUnitInterface;
  /**
   * Animation duration
   */
  duration?: number;
  /**
   * On click callback
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const ShipmentHeader: FC<ShipmentHeaderProps> = ({
  active,
  total,
  shipper,
  isShipperEnterprise,
  consignee,
  isConsigneeEnterprise,
  boxAccount,
  buSource,
  location,
  buConsignee,
  duration = 1000,
  onClick,
}) => {
  const exchanges = useCurrencyExchanges();

  return (
    <div className="flex flex-1 items-center py-2">
      <div className="flex flex-col flex-1">
        <div className="relative flex items-center">
          <div className="gap-8 grid w-full grid-cols-2">
            {/* Shipper */}
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col xl:flex-row xl:gap-4 xl:items-center">
                <p className="font-semibold">Remitente:</p>
                <div className="flex items-center truncate">
                  {formatName(shipper?.accountFullName ?? "")}

                  <CheckBadgeIcon
                    title="Cliente Crédito Corporativo"
                    data-te-toggle="tooltip"
                    className={classNames(
                      "ml-2 inline h-5 w-5 text-indigo-600",
                      !isShipperEnterprise && "hidden"
                    )}
                  />
                </div>
              </div>

              <AnimatedHeight active={active} duration={duration}>
                <ClientDetails client={shipper} />
              </AnimatedHeight>
            </div>

            {/* Consignee */}
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col xl:flex-row xl:gap-4 xl:items-center">
                <p className="font-semibold">Destinatario:</p>
                <div className="flex items-center truncate">
                  {formatName(consignee?.accountFullName ?? "")}

                  <CheckBadgeIcon
                    title="Cliente Crédito Corporativo"
                    data-te-toggle="tooltip"
                    className={classNames(
                      "ml-2 inline h-5 w-5 text-indigo-600",
                      !isConsigneeEnterprise && "hidden"
                    )}
                  />
                </div>
              </div>

              <AnimatedHeight active={active} duration={duration}>
                <ClientDetails client={consignee} />
              </AnimatedHeight>
            </div>
          </div>

          <div
            className="flex flex-1 flex-col h-full"
            style={{ minWidth: "5rem", alignSelf: "flex-start" }}
          >
            <p className="text-gray-500 text-sm font-semibold text-right">
              Total
            </p>
            <p className="text-gray-500 text-xs text-right">
              {currencyExchangeText(total ?? 0, exchanges, "USD")}
            </p>
            <p className="text-gray-500 text-xs text-right">
              {currencyExchangeText(total ?? 0, exchanges, "BS")}
            </p>
          </div>
        </div>

        <AnimatedHeight active={active} duration={duration}>
          <div className="flex flex-1 flex-col pt-4">
            {/* buSource */}
            <div className="flex flex-1 flex-col">
              <div className="flex items-center gap-4">
                <p className="font-semibold mr-2">Origen:</p>
                <div className="flex flex-col">
                  {!!buSource?.code ? (
                    <p className="text-sm">
                      {buSource?.code} - {buSource?.location.name}
                    </p>
                  ) : (
                    <p className="text-sm">{buSource?.location.name}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Location */}
            <div className="flex flex-1 flex-col mt-2">
              <div className="flex items-start gap-4">
                <p className="font-semibold">Destino:</p>
                <div className="flex flex-col">
                  {!!boxAccount && (
                    <p className="text-sm">
                      Casillero {boxAccount?.boxAccountCode}
                    </p>
                  )}
                  {!!buConsignee?.code ? (
                    <p className="text-sm">
                      {buConsignee.code} - {buConsignee.location?.name}
                    </p>
                  ) : (
                    <p className="text-sm">{buConsignee?.location?.name}</p>
                  )}
                  <p className="text-xs">
                    {location?.name}
                    {" - "}
                    {location?.address}{" "}
                    {!!location?.postalCode
                      ? `(código postal: ${location?.postalCode})`
                      : ""}
                  </p>
                  <p className="text-xs">{location?.reference}</p>
                </div>
              </div>
            </div>
          </div>
        </AnimatedHeight>
      </div>

      <div
        className="p-2 ml-4 hover:bg-gray-200 border cursor-pointer rounded-full"
        onClick={onClick}
      >
        <ChevronDownIcon
          style={{
            transform: `rotate(${!active ? 0 : 180}deg)`,
            transitionDuration: `${duration}ms`,
          }}
          className="h-8 w-8 flex-none text-gray-400 transition-all ease-in-out"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default ShipmentHeader;
