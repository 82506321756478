import { FC, useMemo } from "react";
import { PaymentMode, ItemInterface } from "../../interfaces";
import {
  paymentModeFormat,
  currencyExchangeText,
  useCurrencyExchanges,
} from "../../utils";

interface PriceDetailsProps {
  items: ItemInterface[];
  paymentMode: PaymentMode;
}
const PriceDetails: FC<PriceDetailsProps> = ({ items, paymentMode }) => {
  const exchanges = useCurrencyExchanges();

  const ipostel = useMemo(() => {
    return items.reduce((acc, item) => acc + item.rate.ipostel, 0);
  }, [items]);

  const iva = useMemo(() => {
    return items.reduce((acc, item) => acc + item.rate.iva, 0);
  }, [items]);

  // Subtotal for all items (selected and default)
  const subTotal = useMemo(() => {
    const absoluteTotalValue = items
      .filter((item) => !item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);
    const percentageTotalValue = items
      .filter((item) => item.rate.isPercentage)
      .reduce((acc, item) => acc + item.rate.value, 0);

    return absoluteTotalValue * (1 + percentageTotalValue / 100);
  }, [items]);

  // Total to be paid
  const total = useMemo(() => {
    return Number((subTotal + iva + ipostel).toFixed(2));
  }, [subTotal, iva, ipostel]);

  return (
    <>
      <div className="flex flex-row bg-white gap-4 border-t border-gray-200 px-8 pb-6 pt-4">
        <div className="flex flex-1">
          <div className="flex flex-col">
            <p className="text-gray-400">Modalidad de Pago</p>
            <p className="font-semibold text-xl">
              {paymentModeFormat(paymentMode)}
            </p>
          </div>
        </div>

        <div className="flex flex-1 flex-col">
          <div className="flex flex-row justify-between">
            <p className="text-gray-400">Sub total</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-gray-600">
                {currencyExchangeText(subTotal, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(subTotal, exchanges, "BS")}
              </p>
            </div>
          </div>
          <hr className="my-2" />

          <div className="flex flex-row justify-between">
            <p className="text-gray-400">IVA</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-gray-600">
                {currencyExchangeText(iva, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(iva, exchanges, "BS")}
              </p>
            </div>
          </div>
          <hr className="my-2" />

          <div className="flex flex-row justify-between">
            <p className="text-gray-400">Ipostel</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-gray-600">
                {currencyExchangeText(ipostel, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(ipostel, exchanges, "BS")}
              </p>
            </div>
          </div>
          <hr className="my-2" />

          <div className="flex flex-row justify-between">
            <p className="text-gray-400">Total de factura</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-2xl">
                {currencyExchangeText(total, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(total, exchanges, "BS")}
              </p>
            </div>
          </div>

          {paymentMode === PaymentMode.COD && (
            <p className="text-xs text-right text-gray-400">
              *Los montos son estimados.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PriceDetails;
