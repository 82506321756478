import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import { AddButton } from "../Buttons";
import { Currency, formatFloat } from "../../utils";
import { FormSelect, FormTextInput } from "../FormFields";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import {
  PaymentInterface,
  PaymentMethodEnum,
  PaymentMethodInterface,
  PaymentStatusEnum,
} from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { loaderService } from "../../services";

export interface PercentageInterface {
  id: number;
  name: string;
  value: string;
  percentage: number;
  initialPercentage: number;
}

export interface RetentionFormValues {
  amount: number;
  retentionPercent: number;
  paymentMethod: PaymentMethodInterface;
  currency: Currency;
}

interface RetentionFormProps {
  iva: number;
  subTotal: number;
  remaining: number;
  formik: FormikProps<RetentionFormValues>;
  payments: PaymentInterface[];
  defaultPercentages: PercentageInterface[];
  onEmptyOptions?: () => void;
  handlePayAmountChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<any>,
    max: number
  ) => void;
}

export const retentionList = [
  "iva-retention",
  "islr-retention",
  "municiapl-tax",
];

const RetentionsForm: FC<RetentionFormProps> = ({
  iva,
  subTotal,
  remaining,
  formik,
  payments,
  defaultPercentages,
  onEmptyOptions = () => {},
  handlePayAmountChange,
}) => {
  const loading = loaderService.useIsLoading();
  const [isValueEditable, setValueIsEditable] = useState(false);
  const [isAddPercentageOption, setIsAddPercentageOption] = useState(false);
  const initialRetentionMethods = useAppSelector(
    (state) => state.user.retentionMethods ?? []
  );

  const retentionOptions = useMemo(() => {
    const options = initialRetentionMethods.filter((option) => {
      return (
        (option.paymentMethodID === 15 ||
          !payments
            .filter(
              (p) =>
                p.status === PaymentStatusEnum.PENDING ||
                p.status === PaymentStatusEnum.APPROVE
            )
            .some(
              (payment) =>
                payment.paymentMethod.paymentMethodName ===
                option.paymentMethodName
            )) &&
        (iva > 0 || option.paymentMethodID !== 14)
      );
    });

    if (
      options.length > 0 &&
      formik.values.paymentMethod.paymentMethodName !==
        options[0].paymentMethodName
    ) {
      formik.setFieldValue("paymentMethod", options[0]);
    } else {
      onEmptyOptions();
    }

    return options;

    // To update the retention options you only need to know the payments,
    // not the formik object. Adding it would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, iva]);

  const clamp = useCallback(
    (value: number) => Math.max(0, Math.min(value, remaining)),
    [remaining]
  );

  const handlePercentageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<RetentionFormValues>
  ) => {
    const setValue = (value: string) => {
      e.target.value = value;
    };
    if (!formatFloat(e.target.value, setValue)) {
      return;
    }

    formik.setValues({
      ...formik.values,
      amount: +clamp((subTotal * Number(e.target.value)) / 100).toFixed(2),
      retentionPercent: e.target.value as unknown as number,
    });
  };

  useEffect(() => {
    setValueIsEditable(
      formik.values.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.RETENCION_DE_ISRL
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  useEffect(() => {
    setIsAddPercentageOption(
      formik.values.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  useEffect(() => {
    const percentageObj = defaultPercentages.find(
      (option) => option.id === formik.values.paymentMethod.paymentMethodID
    );

    if (
      formik.values.paymentMethod.paymentMethodID ===
      PaymentMethodEnum.RETENCION_DE_IVA
    ) {
      formik.setFieldValue("amount", +(iva * 0.75).toFixed(2));
    } else if (!!percentageObj) {
      formik.setFieldValue(
        "amount",
        Number(clamp(subTotal * percentageObj.initialPercentage).toFixed(2))
      );
    } else if (
      formik.values.paymentMethod.paymentMethodID ===
      PaymentMethodEnum.RETENCION_DE_IMPUESTO_MUNICIPAL
    ) {
      formik.setValues({
        ...formik.values,
        retentionPercent: 3,
        amount: Number(clamp((subTotal * 3) / 100).toFixed(2)),
      });
      setIsAddPercentageOption(true);
    } else {
      setIsAddPercentageOption(false);
    }

    // Adding the formik object would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    iva,
    defaultPercentages,
    formik.values.paymentMethod.paymentMethodName,
    subTotal,
    clamp,
  ]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex items-end gap-4 w-full"
    >
      <div className="flex items-start gap-4 w-full">
        <div className="flex flex-col flex-1">
          <FormSelect
            label="Tipo de Retención"
            name="retentionType"
            options={retentionOptions}
            optionString={(method) => `${method.paymentMethodName}`}
            selected={formik.values.paymentMethod}
            error={
              formik.touched.paymentMethod && formik.errors.paymentMethod
                ? formik.errors.paymentMethod
                : ""
            }
            onSelectOption={(method) =>
              formik.setFieldValue("paymentMethod", method)
            }
            onBlur={formik.handleBlur}
          />
        </div>

        <div className="flex flex-1 space-x-2">
          {isAddPercentageOption && (
            <div className="flex-1">
              <FormTextInput
                name="retentionPercent"
                label="%"
                onChange={(e) => handlePercentageChange(e, formik)}
                value={formik.values.retentionPercent}
                error={
                  formik.touched.retentionPercent &&
                  formik.errors.retentionPercent
                    ? formik.errors.retentionPercent
                    : ""
                }
                onBlur={formik.handleBlur}
              />
            </div>
          )}
          <div className="flex-1">
            <FormTextInput
              name="amount"
              label="Monto (Bs)"
              readOnly={!isValueEditable}
              onChange={(e) => handlePayAmountChange(e, formik, remaining)}
              value={formik.values.amount}
              error={formik.errors.amount}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
      </div>

      {!isValueEditable && (
        <button
          type="submit"
          title="Editar decimales"
          data-te-toggle="tooltip"
          onClick={() => setValueIsEditable(true)}
          className="flex flex-row inline-flex justify-center text-sm font-semibold items-center rounded-full 
                      bg-indigo-100 hover:bg-indigo-200 hover:cursor-pointer px-3 py-2 "
        >
          <PencilSquareIcon
            className="h-5 w-5 text-indigo-600"
            aria-hidden="true"
          />
          Ajustar
        </button>
      )}

      <div>
        <AddButton type="submit" disabled={!formik.values.amount || loading} />
      </div>
    </form>
  );
};

export default RetentionsForm;
