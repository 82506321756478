import { useEffect, useState } from "react";
import {
  alertService,
  getLockBUPaymentModes,
  getLockBUServices,
  getPaymentMethods,
  getRetentionMethods,
  loaderService,
} from "../services";
import { useLocation, useNavigate } from "react-router-dom";
import { navigation } from "../utils/NavigationLists";
import SidebarStatic from "../components/SidebarStatic";
import SidebarDynamic from "../components/SidebarDynamic";
import { UserInterface } from "../interfaces/UserInterface";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  login,
  logout,
  setLockedServices,
  setPaymentMethods,
  setPaymentModes,
  setRetentionMethods,
  setUserLocation,
} from "../store/slices";
import {
  CreateOrUpdateSession,
  logoutSession,
} from "../services/authetication";
import {
  SessionInterface,
  ResponseInterface,
  BusinessUnitInterface,
  PaymentMethodInterface,
} from "../interfaces";

const Layout = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const { pathname: path } = useLocation();
  const dispatch = useAppDispatch();

  const userLogged = useAppSelector((state) => state.user.user);
  const userSession = useAppSelector((state) => state.user.userSession);
  const inmutable = useAppSelector((state) => state.inmutable);

  useEffect(() => {
    const section = "/" + path.split("/")[1];
    const index = navigation.findIndex((item) => item.href === section);

    setCurrentItemIndex(index);
  }, [path]);

  const handleLogout = async () => {
    if (!userLogged || !userSession) return;
    const response = await logoutSession(
      userLogged.userID,
      userSession.sessionID
    );
    if (!response.didError) dispatch(logout());
  };

  const handleBusinessUnitChange = async (
    user: UserInterface,
    businessUnit: BusinessUnitInterface
  ) => {
    loaderService.start();
    const userSessionResponse: ResponseInterface<SessionInterface> =
      await CreateOrUpdateSession(
        user.userID,
        inmutable.appData.applicationCode,
        businessUnit.code
      );
    const paymentMethodsResponse: PaymentMethodInterface[] =
      await getPaymentMethods(businessUnit.code, user.userLogin);
    const retentionMethodsResponse: PaymentMethodInterface[] =
      await getRetentionMethods(businessUnit.code, user.userLogin);
    const lockedServices = await getLockBUServices(businessUnit.code);
    const paymentModes = await getLockBUPaymentModes(businessUnit.code);

    loaderService.stop();
    if (
      !userSessionResponse.didError &&
      !!userSessionResponse.model &&
      !paymentModes.didError &&
      !!paymentModes.model &&
      !lockedServices.didError &&
      !!lockedServices.model
    ) {
      let userSession = userSessionResponse.model;
      dispatch(login({ user: user, session: userSession }));
      dispatch(setUserLocation(businessUnit));
      dispatch(setPaymentMethods(paymentMethodsResponse));
      dispatch(setRetentionMethods(retentionMethodsResponse));
      dispatch(setLockedServices(lockedServices.model));
      dispatch(
        setPaymentModes(
          inmutable.paymentModes.filter(
            (mode) =>
              !paymentModes.model?.some(
                (blocked) => blocked.paymentModeID === mode.paymentModeID
              )
          )
        )
      );
      navigate("/dashboard");
    } else {
      alertService.error(userSessionResponse.errorMessage);
    }
  };

  return (
    <>
      <SidebarDynamic
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigationItems={navigation}
        currentItemIndex={currentItemIndex}
        setCurrentItemIndex={setCurrentItemIndex}
        handleLogout={handleLogout}
        handleBusinessUnitChange={handleBusinessUnitChange}
      />
      <SidebarStatic
        navigationItems={navigation}
        currentItemIndex={currentItemIndex}
        setCurrentItemIndex={setCurrentItemIndex}
        handleLogout={handleLogout}
        handleBusinessUnitChange={handleBusinessUnitChange}
      />
    </>
  );
};

export default Layout;
