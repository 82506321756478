import { FC, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import logo from "../../assets/LogoTEALCA.svg";
import { useNavigate } from "react-router-dom";
import {
  alertService,
  getLockBUPaymentModes,
  getLockBUServices,
  getPaymentMethods,
  getRetentionMethods,
  getStorePendingPayments,
  loaderService,
} from "../../services";
import {
  login,
  setLockedServices,
  setPaymentMethods,
  setPaymentModes,
  setRetentionMethods,
  setUserLocation,
} from "../../store/slices";
import { UserInterface } from "../../interfaces/UserInterface";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { FormSelect, FormTextInput } from "../../components/FormFields";
import { CreateOrUpdateSession, userLogin } from "../../services/authetication";
import {
  ErrorInterface,
  SessionInterface,
  ResponseInterface,
  BusinessUnitInterface,
  PaymentMethodInterface,
  PaymentStatusEnum,
  PaymentMethodEnum,
} from "../../interfaces";
import {
  LinkText,
  PrimaryButton,
  SecondaryButton,
} from "../../components/Buttons";

export interface LoginFormValues {
  email: string;
  password: string;
}
enum LoginPhase {
  LOGIN,
  BUSINESS_UNIT_SELECTION,
  NOT_AVAILABLE_BUSINESS_UNIT,
}

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<UserInterface>();
  const [values, setValues] = useState<LoginFormValues>();
  const [loginErrors, setLoginErrors] = useState<string[]>([]);
  const [loginPhase, setLoginPhase] = useState(LoginPhase.LOGIN);
  const [businessUnit, setBusinessUnit] = useState<BusinessUnitInterface>();

  const inmutable = useAppSelector((state) => state.inmutable);

  const verifyMerchant = async (BUCode: string) => {
    const response = await getStorePendingPayments(BUCode);

    if (response.didError || !response.model) {
      return;
    }

    const existsPendingMerchant = response.model.some(
      (p) =>
        p.status === PaymentStatusEnum.PENDING &&
        new Date(p.paymentDate ?? "").getDate() < new Date().getDate() &&
        (p.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.PAGO_MERCHANT_P2C ||
          p.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO ||
          p.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO)
    );

    if (existsPendingMerchant) {
      alertService.warn(
        "Tienes un cierre de caja de Merchant pendiente de días anteriores",
        "Por favor, recuerde realizar el cierre de caja.",
        { autoClose: false, keepAfterRouteChange: true }
      );
    }
  };

  const loginUser = async (values: LoginFormValues) => {
    setLoginErrors([]);

    loaderService.start();
    const userLogged = await userLogin(
      values.email,
      values.password,
      inmutable.appData.applicationCode
    );
    loaderService.stop();

    if (userLogged === null) {
      setLoginErrors([...loginErrors, "Error al iniciar sesión"]);
    } else if (userLogged.hasOwnProperty("error")) {
      setLoginErrors((userLogged as ErrorInterface).error);
    } else {
      const user = userLogged as UserInterface;
      const businessUnits = user.businessUnitList;
      setUser(user);
      localStorage.setItem("Token", user?.token);

      if (businessUnits.length === 0) {
        setLoginPhase(LoginPhase.NOT_AVAILABLE_BUSINESS_UNIT);
      } else if (
        businessUnits.length === 1 ||
        (!!user.userPasswordExpiration &&
          new Date(user.userPasswordExpiration) < new Date())
      ) {
        saveSession(user, businessUnits[0], values);
      } else {
        setValues(values);
        setLoginPhase(LoginPhase.BUSINESS_UNIT_SELECTION);
      }
    }
  };

  //Save session
  const saveSession = async (
    user: UserInterface,
    businessUnit: BusinessUnitInterface,
    values: LoginFormValues
  ) => {
    loaderService.start();
    const userSessionResponse: ResponseInterface<SessionInterface> =
      await CreateOrUpdateSession(
        user.userID,
        inmutable.appData.applicationCode,
        businessUnit.code
      );
    const paymentMethodsResponse: PaymentMethodInterface[] =
      await getPaymentMethods(businessUnit.code, user.userLogin);
    const retentionMethodsResponse: PaymentMethodInterface[] =
      await getRetentionMethods(businessUnit.code, user.userLogin);
    const paymentModes = await getLockBUPaymentModes(businessUnit.code);
    const lockedServices = await getLockBUServices(businessUnit.code);
    loaderService.stop();

    if (
      !userSessionResponse.didError &&
      !!userSessionResponse.model &&
      !paymentModes.didError &&
      !!paymentModes.model &&
      !lockedServices.didError &&
      !!lockedServices.model
    ) {
      const userSession = userSessionResponse.model;

      if (
        !!user.userPasswordExpiration &&
        new Date(user.userPasswordExpiration) < new Date()
      ) {
        navigate("/user-password-update", {
          state: {
            email: values.email,
            password: values.password,
          },
        });
      } else {
        verifyMerchant(businessUnit.code);

        dispatch(login({ user: user, session: userSession }));
        dispatch(setUserLocation(businessUnit));
        dispatch(setPaymentMethods(paymentMethodsResponse));
        dispatch(setRetentionMethods(retentionMethodsResponse));
        dispatch(setLockedServices(lockedServices.model));
        dispatch(
          setPaymentModes(
            inmutable.paymentModes.filter(
              (mode) =>
                !paymentModes.model?.some(
                  (blocked) => blocked.paymentModeID === mode.paymentModeID
                )
            )
          )
        );
        navigate("/dashboard");
      }
    } else {
      setLoginErrors([...loginErrors, userSessionResponse.errorMessage]);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("El correo es obligatorio"),
    password: Yup.string().required("La contraseña es obligatorio"),
  });

  return (
    <div className="flex h-screen flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
      <div className="w-full h-full max-w-sm flex flex-1 flex-col justify-around">
        <div>
          <h1 className="text-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Taquilla TEALCA
          </h1>
          <img src={logo} className="mx-auto h-30 w-auto" alt="logo" />

          {loginPhase === LoginPhase.LOGIN && (
            <h2 className="mt-5 text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
              Inicie sesión en su cuenta
            </h2>
          )}
        </div>

        {loginPhase === LoginPhase.LOGIN && (
          <>
            <Formik<LoginFormValues>
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                loginUser(values).then(() => {
                  actions.setSubmitting(false);
                  actions.resetForm({ values: { email: "", password: "" } });
                });
              }}
            >
              {(formik) => {
                const {
                  errors,
                  touched,
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                } = formik;
                return (
                  <form className="space-y-6" onSubmit={handleSubmit}>
                    {!!loginErrors && loginErrors.length > 0 && (
                      <p className="text-center ">
                        <span className="text-sm text-red-500">
                          {loginErrors.join(", ")}
                        </span>
                      </p>
                    )}
                    <div className="flex flex-1 flex-col gap-2">
                      <div>
                        <FormTextInput
                          id="email"
                          name="email"
                          autoComplete="email"
                          error={
                            touched.email && errors.email ? errors.email : ""
                          }
                          placeholder="Correo electrónico o username"
                          value={values.email}
                          onChange={(value) => {
                            handleChange(value);
                            setLoginErrors([]);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      <div>
                        <FormTextInput
                          id="password"
                          name="password"
                          type="password"
                          error={
                            touched.password && errors.password
                              ? errors.password
                              : ""
                          }
                          placeholder="Contraseña"
                          value={values.password}
                          onChange={(value) => {
                            handleChange(value);
                            setLoginErrors([]);
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>

                    <div className="flex flex-1">
                      <PrimaryButton type="submit" className="w-full">
                        Iniciar sesión
                      </PrimaryButton>
                    </div>
                  </form>
                );
              }}
            </Formik>

            <div className="flex w-full flex-col items-center">
              <LinkText
                text="Olvidé mi contraseña"
                onClick={() => navigate("/password-recovery")}
              />
            </div>
          </>
        )}

        {loginPhase === LoginPhase.BUSINESS_UNIT_SELECTION &&
          !!user?.businessUnitList && (
            <>
              <div className="flex flex-col gap-8">
                <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
                  Seleccione la tienda
                </h2>
                {!!loginErrors && loginErrors.length > 0 && (
                  <p className="text-center ">
                    <span className="text-sm text-red-500">
                      {loginErrors.join(", ")}
                    </span>
                  </p>
                )}
                <div className="flex flex-col gap-2 w-full">
                  <FormSelect
                    id="businessUnit"
                    name="businessUnit"
                    placeholder="Seleccione una tienda"
                    selected={businessUnit}
                    options={user.businessUnitList}
                    optionString={(businessUnit) =>
                      `${businessUnit.code} - ${businessUnit.location.name}`
                    }
                    onSelectOption={(businessUnit) =>
                      setBusinessUnit(businessUnit)
                    }
                  />
                </div>
              </div>

              <div className="flex flex-col gap-12">
                <div className="flex flex-col w-full gap-4">
                  <PrimaryButton
                    className="w-full mt-12"
                    onClick={() => {
                      if (!!businessUnit) {
                        saveSession(user, businessUnit, values!);
                      }
                    }}
                  >
                    Iniciar sesión
                  </PrimaryButton>

                  <SecondaryButton
                    className="w-full"
                    onClick={() => setLoginPhase(LoginPhase.LOGIN)}
                  >
                    Volver
                  </SecondaryButton>
                </div>
              </div>
            </>
          )}

        {loginPhase === LoginPhase.NOT_AVAILABLE_BUSINESS_UNIT && !!user && (
          <>
            <div className="flex flex-col gap-8">
              <h2 className="text-center text-2xl font-semibold leading-9 tracking-tight text-gray-900">
                No tienes una tienda asignada
              </h2>
              <span className="text-center text-sm text-gray-500 font-medium">
                Por favor, contacta a Soporte Técnico o a algún administrador
                para resolver el problema.
              </span>
            </div>

            <div className="flex flex-col gap-12">
              <div className="flex flex-col w-full gap-4">
                <SecondaryButton
                  className="w-full"
                  onClick={() => setLoginPhase(LoginPhase.LOGIN)}
                >
                  Volver
                </SecondaryButton>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Login;
