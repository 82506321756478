import {
  PlusIcon,
  CalculatorIcon,
  InboxStackIcon,
  ChartBarSquareIcon,
  ComputerDesktopIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  WrenchIcon,
  BookOpenIcon,
  UserCircleIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

export interface NavigationItem {
  name: string;
  href: string;
  primary?: boolean;
  icon?: React.ElementType;
  target?: string;
  rolesRequired?: string[];
}

export const navigation: NavigationItem[] = [
  { name: "Dashboard", href: "/dashboard", icon: ChartBarSquareIcon },
  { name: "Crear Guía", href: "/shipment-create", icon: PlusIcon },
  { name: "Guías", href: "/shipments", icon: InboxStackIcon },
  {
    name: "Facturas y Órdenes",
    href: "/documents",
    icon: ClipboardDocumentCheckIcon,
  },
  {
    name: "Pagos por Conciliar",
    href: "/pending-payments",
    icon: DocumentMagnifyingGlassIcon,
  },
  //{ name: 'Por entregar', href: '/in-transit', icon: ClockIcon},
  { name: "Cotizador", href: "/estimation", icon: CalculatorIcon },
  { name: "Clientes", href: "/clients", icon: UserCircleIcon },
  { name: "Caja", href: "/cash-desk", icon: ComputerDesktopIcon },
  {
    name: "Facturar a Casa Matriz",
    href: "/pdocuments",
    icon: BookOpenIcon,
    rolesRequired: ["Administrador", "Superadministrador"],
  },
  // { name: "Retirar", href: "/pick-up", icon: InboxArrowDownIcon },
  {
    name: "Reporte Operativo",
    href: "https://lookerstudio.google.com/reporting/8db0b423-c6f0-4833-bb96-e120c009b0f0",
    icon: WrenchIcon,
    target: "_blank",
  },
  {
    name: "Reporte Administrativo",
    href: "https://lookerstudio.google.com/u/0/reporting/7fa733cd-58b8-481b-9531-c9176df7eb85/page/p_bko8n2ekcd", // TODO
    icon: ClipboardDocumentListIcon,
    target: "_blank",
    rolesRequired: ["Supervisor", "Administrador", "Superadministrador"],
  },

  //{ name: "Test Lab", href: "/test", icon: AdjustmentsVerticalIcon },
];
